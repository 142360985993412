import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { BannerComponent } from '@domains/company/banner/banner.component';
import { CompanyListItemComponent } from '@domains/company/company-list-item/company-list-item.component';
import { CompanyListSkeletonComponent } from '@domains/company/company-list-skeleton/company-list-skeleton.component';
import { HeaderComponent } from '@domains/company/header/header.component';
import { I18nFieldModule } from '@domains/company/i18n-field/i18n-field.module';
import { InmailCustomFormComponent } from '@domains/company/inmail-custom-form/inmail-custom-form.component';
import { LogoComponent } from '@domains/company/logo/logo.component';
import { PublicationSkeletonComponent } from '@domains/company/publication-skeleton/publication-skeleton.component';
import { PublicationComponent } from '@domains/company/publication/publication.component';
import { TagsComponent } from '@domains/company/tags/tags.component';
import { EventModule } from '@domains/event/event.module';

@NgModule({
  imports: [CommonModule, SvgIconModule, I18nFieldModule, RouterModule, EventModule, TrackerModule],
  declarations: [
    LogoComponent,
    HeaderComponent,
    CompanyListItemComponent,
    TagsComponent,
    BannerComponent,
    CompanyListSkeletonComponent,
    PublicationComponent,
    PublicationSkeletonComponent,
    InmailCustomFormComponent,
  ],
  exports: [
    LogoComponent,
    HeaderComponent,
    CompanyListItemComponent,
    TagsComponent,
    BannerComponent,
    CompanyListSkeletonComponent,
    PublicationComponent,
    PublicationSkeletonComponent,
    InmailCustomFormComponent,
  ],
})
export class CompanyModule {}
