import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface ParticipationEventData {
  ids: string;
  name: string;
  category: string;
}

@Injectable()
export class OpsEventPariticipationInterceptor implements HttpInterceptor {
  private readonly OpsEventParticipationUrl = `${environment.api.opsEvent}/candidate/participation/`;

  constructor(private readonly eventTrackerService: EventTrackerService) {}

  intercept(req: HttpRequest<any>, nextHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.OpsEventParticipationUrl) && req.method === 'POST') {
      const ids = req.body.utmCampaign;

      return nextHandler.handle(req).pipe(
        tap<HttpResponse<any>>((response) => {
          if (response instanceof HttpResponse && response.status === 202) {
            this.trackWithFBPixel({ ids, name: 'Page Event', category: 'Page Event' });
          }
        })
      );
    }

    return nextHandler.handle(req);
  }

  private trackWithFBPixel(data: ParticipationEventData): void {
    this.eventTrackerService.sendCustomTrackingEventToFacebookPixel({ ...data }, 'ApplyToEvent');
  }
}
