<app-popup>
  <button
    class="profile-popup-btn btn-clean"
    type="button"
    i18n-aria-label="préférences"
    aria-label="Voir les préférences"
    appPopupTrigger
  >
    <span class="profile-popup-btn__pic">{{ profile?.firstName?.charAt(0) }} {{ profile?.lastName?.charAt(0) }}</span>
    <app-svg-icon class="profile-popup-btn__icon" icon="chevron-down" width=".5em"></app-svg-icon>
  </button>

  <div class="profile-popup-menu" appPopupItem>
    <a
      class="profile-popup-menu__link"
      [routerLink]="profile && [profileSegment, profile.id]"
      i18n
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Profile"
      >Mon CV</a
    >

    <a
      class="profile-popup-menu__link profile-popup-menu__link--extra"
      [routerLink]="profile && [profileSegment, profile.id, bookmarksSegment]"
      i18n
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Favorite"
      >Mes favoris</a
    >

    <a
      class="profile-popup-menu__link profile-popup-menu__link--extra"
      [routerLink]="profile && [profileSegment, profile.id, applicationsSegment]"
      i18n
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Applications"
      >Mes candidatures</a
    >
  </div>
</app-popup>
