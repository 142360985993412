import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';

@Component({
  selector: 'advertising-menu-entry',
  templateUrl: './menu-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuEntryComponent {
  wizbiiDomain = environment.apiDomain;
  locale = environment.locale;
  LocaleEnum = LocaleEnum;
}
