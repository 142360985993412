import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Inject, LOCALE_ID, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { BrowserCompatibilityModule } from '@commons/browser-compatibility/browser-compatibility.module';
import { FooterModule } from '@commons/footer/footer.module';
import { HeaderModule } from '@commons/header/header.module';
import { ModalModule } from '@commons/modal/modal.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { bugsnagConfig } from '@config/bugsnag.config';
import { routerConfig } from '@config/router.config';
import { trackingConfig } from '@config/tracking-config';
import { MainComponent } from '@core/components/main/main.component';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { ngxsConfig } from '@core/ngxs.config';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { GlobalService } from '@core/services/global.service';
import { OpsEventPariticipationInterceptor } from '@core/services/http-request.interceptor';
import { JwtService } from '@core/services/jwt.service';
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { SeoService } from '@core/services/seo.service';
import { AppBrowserViewportScroller } from '@core/utils/scroller/app-browser-viewport-scroller';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SetConsents } from '@store/consents/consents.actions';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { PlaceStoreModule } from '@store/place/place.store.module';
import { BookmarkState } from '@store/profile/bookmark/bookmark.state';
import { ScrollLockState } from '@store/scroll-lock/scroll-lock.state';
import { SearchStoreModule } from '@store/search/search.store.module';
import { SessionState } from '@store/session/session.state';
import { ALGOLIA_SERVICE_TOKEN, AlgoliaModule, AlgoliaParamsInterface } from '@wizbii/algolia';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { DataStorageService, ImageService, WINDOW, WINDOW_PROVIDERS } from '@wizbii/angular-utilities';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import { setupTracking, track } from '@wizbii/tracking';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  PLACE_API_CONFIG,
} from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

const algoliaParams: AlgoliaParamsInterface = {
  applicationId: environment.algolia.applicationId,
  apiKey: environment.algolia.apiKey,
  options: {},
};

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: environment.applicationId }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HammerModule,
    FormsModule,
    HttpClientModule,
    FeaturesModule,
    AlgoliaModule,
    HeaderModule,
    FooterModule,
    ModalModule,
    HeadTitleModule,
    HeadDescriptionModule,
    BrowserCompatibilityModule,

    NgxsModule.forRoot([SessionState, ScrollLockState, ProfileState, BookmarkState], ngxsConfig),

    QuicklinkSharedModule,
    RouterModule.forRoot([], routerConfig),

    PlaceStoreModule,
    SearchStoreModule,

    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'WIZBII Jobs', maxAge: 30 }),
    ScrollToModule.forRoot(),

    TrackerModule.forRoot(),
  ],
  declarations: [MainComponent, SpriteComponent],
  providers: [
    GlobalService,
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    {
      provide: RouterStateSerializer,
      useClass: WizbiiRouterStateSerializer,
    },
    DataStorageService,
    ScrollLockService,
    CookieService,
    SeoService,

    {
      provide: LOCALE_ID,
      useValue: environment.lang,
    },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.address,
      },
    },

    AuthenticationWebservice,
    CityWebservice,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: OpsEventPariticipationInterceptor,
      multi: true,
    },

    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },

    {
      provide: ViewportScroller,
      useClass: AppBrowserViewportScroller,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: JWT_SERVICE_TOKEN,
      useClass: JwtService,
    },
    {
      provide: ALGOLIA_SERVICE_TOKEN,
      useValue: algoliaParams,
    },
  ],
  bootstrap: [MainComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(
    router: Router,
    ngZone: NgZone,
    store: Store,
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(WINDOW) window: any
  ) {
    ngZone.runOutsideAngular(() => {
      if (isPlatformBrowser(platformId)) {
        this.initializeTracking(router, window, store);
      }
    });
  }

  private initializeTracking(router: Router, window: Window, store: Store) {
    setupTracking(trackingConfig).then(() => {
      from(window.WizbiiGdpr.getConsents())
        .pipe(
          tap((consents: any) => store.dispatch(new SetConsents(consents))),
          switchMap(() => router.events),
          filter((event) => event instanceof NavigationEnd),
          map((event) => (event as NavigationEnd).urlAfterRedirects),
          startWith(window.location.href.replace(window.origin, '')),
          map((page) => ({ url: window.location.href, page }))
        )
        .subscribe({
          next: (event) => track('pageview', event),
        });
    });
  }
}
