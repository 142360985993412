import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollSpyDirective } from '@commons/scroll-spy/scroll-spy.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollSpyDirective],
  exports: [ScrollSpyDirective],
})
export class ScrollSpyModule {}
