import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheckmarkLoaderModule } from '@commons/checkmark-loader/checkmark-loader.module';
import { MutationObserverModule } from '@commons/mutation-observer/mutation-observer.directive';
import { PipeModule } from '@commons/pipe/pipe.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { JobLightweightComponent } from '@domains/advertising/job-lightweight/job-lightweight.component';
import { LeaderBoardComponent } from '@domains/advertising/leader-board/leader-board.component';
import { MenuEntryComponent } from '@domains/advertising/menu-entry/menu-entry.component';
import { WizmailComponent } from '@domains/advertising/wizmail/wizmail.component';
import { EventModule } from '@domains/event/event.module';

@NgModule({
  imports: [
    CommonModule,
    CheckmarkLoaderModule,
    EventModule,
    PipeModule,
    RouterModule,
    SvgIconModule,
    TrackerModule,
    MutationObserverModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [JobLightweightComponent, MenuEntryComponent, WizmailComponent, LeaderBoardComponent],
  exports: [JobLightweightComponent, MenuEntryComponent, WizmailComponent, LeaderBoardComponent],
})
export class AdvertisingModule {}
