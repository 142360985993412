import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CustomForms } from '@models/media/custom-forms';
import { Inmail } from '@models/media/inmail';
import { Media } from '@models/media/media';
import { MediaAction } from '@models/media/media-action';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';

const SITE = 'wizbii';

enum MediaTypeEnum {
  Inmail = 'i',
  CustomFormsCompany = 'cfcp',
  FakeEntryMenu = 'fme',
  LargeFormat = 'ga',
  LeaderBoard = 'lb',
  FakeNews = 'fn',
  FakeJob = 'fj',
  FakeEvent = 'fe',
  Track = 't',
}

enum ItemCategory {
  Job = 'job',
  News = 'company-newsfeed',
  Search = 'job-search',
  Event = 'events',
}

@Injectable()
export class MediaWebservice {
  private readonly baseUrl = `${environment.api.media}/:mediaType/?:id/?:page`;

  constructor(private readonly http: HttpClient) {}

  getInmail(inmailId: string): Observable<Inmail> {
    return this.getAlternativeMedia<Inmail>(inmailId, MediaTypeEnum.Inmail);
  }

  getCustomFormsForCompany(companyId: string): Observable<CustomForms> {
    return this.getAlternativeMedia<CustomForms>(companyId, MediaTypeEnum.CustomFormsCompany);
  }

  getMenuEntry(): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.FakeEntryMenu, ItemCategory.Job, SITE);
  }

  getLargeFormat(department?: string): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.LargeFormat, ItemCategory.Job, SITE, department);
  }

  getLeaderBoard(department?: string): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.LeaderBoard, ItemCategory.Job, SITE, department);
  }

  getFakeNews(): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.FakeNews, ItemCategory.News, SITE);
  }

  getFakeJob(): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.FakeJob, ItemCategory.Search, SITE);
  }

  getFakeEvent(): Observable<Media> {
    return this.getMedia<Media>(MediaTypeEnum.FakeEvent, ItemCategory.Event, SITE);
  }

  private getAlternativeMedia<T>(id: string, mediaType: MediaTypeEnum): Observable<T> {
    return this.getMedia<T>(mediaType, undefined, id);
  }

  private getMedia<T>(mediaType: MediaTypeEnum, page: ItemCategory, id, department?: string): Observable<T> {
    const url = buildUrl(this.baseUrl, { mediaType, id, page, department });
    return this.http.get<T>(url);
  }

  recordAction(action: MediaAction): any {
    const url = buildUrl(this.baseUrl, { mediaType: MediaTypeEnum.Track });

    return this.http.post(url, action, { withCredentials: true }).subscribe();
  }
}
