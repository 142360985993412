import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Store } from '@ngxs/store';
import { GetCompany } from '@store/company/company.actions';
import { CompanyState } from '@store/company/company.state';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    @Optional() @Inject(RESPONSE) private readonly response: any
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const { companyId } = next.params;

    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1), // session initialised
      switchMap(() => this.store.selectOnce(SessionState.tokens)),
      map((tokens) => !!tokens), // logged in state retrieved
      switchMap((isLogged) =>
        this.store.dispatch(new GetCompany(companyId, isLogged)).pipe(
          withLatestFrom(this.store.select(CompanyState.company(companyId))), // company retrieved
          map(([, company]) => {
            // Forbid access to banned companies, and to private companies when unlogged (404)
            if (company.status === 'BANNI' || (!isLogged && company.status === 'PRIVE')) {
              this.redirect404();
              return false; // can't return `UrlTree` yet because of `skipLocationChange` - https://github.com/angular/angular/issues/27148
            }

            // Redirect to custom company URL
            if (company.urlCustom && company.urlCustom !== companyId) {
              this.redirect301(company.urlCustom);
              return false;
            }

            // Redirect to canonical company URL
            if (company.aliasOf && company.aliasOf !== companyId) {
              this.redirect301(company.aliasOf);
              return false;
            }

            return true;
          }),
          catchError(this.handleError.bind(this))
        )
      )
    );
  }

  private handleError(err: HttpErrorResponse | Error) {
    // Forbid activation to unknown company
    if (err instanceof HttpErrorResponse && err.status === 404) {
      this.redirect404();
      return of(false);
    }

    return throwError(err);
  }

  private redirect301(companySlug: string) {
    const redirectUrl = buildPath(FeaturesRoutingEnum.Company, companySlug);

    if (this.response) {
      this.response.statusCode = 301;
      this.response.statusMessage = `Redirecting to ${redirectUrl} with 301`;
      this.response.setHeader('Location', redirectUrl);
    }

    this.router.navigate([redirectUrl], { replaceUrl: true });
  }

  private redirect404() {
    // Navigate to 404 component (which already takes care of updating server response)
    this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
  }
}
