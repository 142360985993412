import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { JobAlert } from '@models/profile/job-alert';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobAlertWebservice {
  private readonly baseUrl = `${environment.api.profile}/profiles/:profileId/job-alert`;
  private readonly patchUrl = `${environment.api.profile}/profiles/:profileId/job-alert/:jobAlertId`;

  constructor(private readonly http: HttpClient) {}

  create(profileId: string, jobAlert: JobAlert): Observable<JobAlert> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.post<object>(url, jobAlert).pipe(map((item) => new JobAlert(item)));
  }

  patch(profileId: string, jobAlert: JobAlert): Observable<JobAlert> {
    const url = buildUrl(this.patchUrl, { profileId, jobAlertId: jobAlert.id });

    return this.http.patch<object>(url, jobAlert).pipe(map((item) => new JobAlert(item)));
  }

  delete(profileId: string, jobAlertId: string): Observable<any> {
    const url = buildUrl(this.patchUrl, { profileId, jobAlertId });

    return this.http.delete<object>(url);
  }

  getBy(profileId: string): Observable<JobAlert[]> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.get<object[]>(url).pipe(map((response) => response.map((item) => new JobAlert(item))));
  }
}
