export function getAccountRequiredParams(queryParams: { [key: string]: string }): { [key: string]: string } {
  return Object.keys(queryParams || {}).reduce((carry, key) => {
    if (key.includes('utm_')) {
      return {
        ...carry,
        [key]: queryParams[key],
      };
    }

    return carry;
  }, {} as any);
}
