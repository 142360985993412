<ng-container [ngSwitch]="model">
  <!-- New contract duration -->
  <ng-container *ngSwitchCase="DurationEnum.LessOneMonth" i18n>Moins de 1 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.OneAndThreeMonths" i18n>Entre 1 et 3 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.FourAndSixMonths" i18n>Entre 4 et 6 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.SevenAndTwelveMonths" i18n>Entre 7 et 12 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.ThirteenAndTwentyFourMonths" i18n>Entre 13 et 24 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.TwentyFiveAndThirtySixMonths" i18n>Entre 25 mois et 36 mois</ng-container>
  <!-- Legacy contract durations, delete after job indexation -->
  <ng-container *ngSwitchCase="DurationEnum.LessThreeMonths" i18n>Moins de 3 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.ThreeAndSixMonths" i18n>Entre 3 et 6 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.SixAndTwelveMonths" i18n>Entre 6 et 12 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.TwelveMonths" i18n>12 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.TwelveAndTwentyFourMonths" i18n>Entre 12 et 24 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.MoreTwelveMonths" i18n>Plus de 12 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.ThreeMonths" i18n>3 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.SixMonths" i18n>6 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.ThirtySixMonths" i18n>36 mois</ng-container>
  <ng-container *ngSwitchCase="DurationEnum.TwentyFourMonths" i18n>24 mois</ng-container>
  <ng-container *ngSwitchDefault>{{ model }}</ng-container>
</ng-container>
