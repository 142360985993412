import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Education } from '@models/profile/education';
import { Profile } from '@models/profile/profile';
import { DegreeWebservice } from '@webservices/school-api/degree.webservice';
import { SchoolWebservice } from '@webservices/school-api/school.webservice';
import { buildUrl } from '@wizbii/angular-utilities';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ProfileWebservice {
  private readonly baseUrl = `${environment.api.profile}/profiles`;

  constructor(
    private readonly http: HttpClient,
    private readonly degreeWebservice: DegreeWebservice,
    private readonly schoolWebservice: SchoolWebservice
  ) {}

  get(id: string): Observable<Profile> {
    const url = buildUrl(`${this.baseUrl}/:id`, { id });
    return this.http.get<object>(url).pipe(
      switchMap((profile) => this.loadDegrees(profile)),
      switchMap((profile) => this.loadSchools(profile)),
      map((next) => new Profile(next))
    );
  }

  patch(id: string, partialProfile: Partial<Profile>): Observable<Profile> {
    const url = buildUrl(`${this.baseUrl}/:id`, { id });

    return this.createSchools(partialProfile.educations).pipe(
      map(
        (educations) =>
          educations &&
          educations.map((education) => ({
            ...education,
            degree: education.degree ? education.degree.id : undefined,
            school: education.school ? education.school.id : undefined,
          }))
      ),
      switchMap((educations) => this.http.patch<object>(url, { ...partialProfile, educations })),
      switchMap((profile) => this.loadDegrees(profile)),
      switchMap((profile) => this.loadSchools(profile)),
      map((next) => new Profile(next))
    );
  }

  create(profile: Partial<Profile>): Observable<Profile> {
    const payload = {
      ...profile,
      locale: environment.locale,
    };
    return this.http.post<object>(this.baseUrl, payload).pipe(map((next) => new Profile(next)));
  }

  delete(id: string, reasons: string[]): Observable<void> {
    const url = buildUrl(`${this.baseUrl}/:id/delete`, { id });
    return this.http.post<void>(url, reasons);
  }

  private loadDegrees(profile: object): Observable<object> {
    return this.degreeWebservice.getBy(profile['locale']).pipe(
      map((degrees) => {
        profile['educations'] = profile['educations'].map((education) => ({
          ...education,
          degree: degrees.find(({ id }) => education.degree === id),
        }));

        return profile;
      })
    );
  }

  private loadSchools(profile: object): Observable<object> {
    return profile['educations'].length
      ? forkJoin(
          profile['educations'].map((education) =>
            education['school']
              ? this.schoolWebservice.get(education['school']).pipe(map((school) => ({ ...education, school })))
              : of(education)
          )
        ).pipe(map((educations) => ({ ...profile, educations })))
      : of(profile);
  }

  private createSchools(educations?: Education[]): Observable<Education[]> {
    return educations && educations.length
      ? forkJoin(
          educations.map((education) =>
            education.school && !education.school.id
              ? this.schoolWebservice.create(education.school).pipe(map((school) => ({ ...education, school })))
              : of(education)
          )
        )
      : of(educations);
  }
}
