<div class="nps container">
  <a class="nps__logo" [routerLink]="searchUrl">
    <app-svg-icon
      class="logo"
      icon="wizbii"
      label="Page d’accueil WIZBII"
      i18n-label
      width="5.75em"
      height="1.25em"
    ></app-svg-icon>
  </a>

  <div class="nps__message">
    <h2 class="nps__title" i18n>
      Merci d'avoir donné votre avis, si vous le souhaitez vous pouvez
      <span class="highlight">ajouter un commentaire</span> sinon vous pouvez fermer cette page&nbsp;!
    </h2>

    <div class="nps__note">
      {{ note }}
    </div>

    <mat-form-field class="nps__textarea">
      <textarea matInput placeholder="Commentez votre choix" i18n-placeholder [(ngModel)]="comment"></textarea>
    </mat-form-field>

    <button class="btn btn--shadow" (click)="sendUpdate()">
      <ng-container *ngIf="!updateSent" i18n>Envoyer</ng-container>
      <ng-container *ngIf="updateSent" i18n>Envoyé</ng-container>
      <app-svg-icon class="icon" icon="exp-right" width="1em" height="0.7em" *ngIf="!updateSent"></app-svg-icon>
      <app-svg-icon class="icon" icon="exp-check" width="1em" height="0.7em" *ngIf="updateSent"></app-svg-icon>
    </button>
    <br />
    <a href="/" i18n>Retour à la page d'accueil</a>
  </div>
</div>
