<ng-container *ngIf="landing$ | async as landing">
  <span wizHeadTitle>
    <ng-container [ngSwitch]="pageId">
      <ng-container *ngSwitchCase="contractsEnum.stage">{{ jobsContractsMeta.stage.metaTitle }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.alternance">{{ jobsContractsMeta.alternance.metaTitle }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum['jobs-etudiants']">{{
        jobsContractsMeta['jobs-etudiants'].metaTitle
      }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.cdi">{{ jobsContractsMeta.cdi.metaTitle }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.apprentissage">{{
        jobsContractsMeta.apprentissage.metaTitle
      }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum['emploi-saisonnier']">{{
        jobsContractsMeta['emploi-saisonnier'].metaTitle
      }}</ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container i18n>Stage, alternance, emploi</ng-container>
        {{ landing.title && landing.slug !== landing.city ? landing.title : ('' | titlecase) }}
        <ng-container *ngIf="landing.city" i18n>à {{ landing.city | titlecase }}</ng-container>
        - WIZBII
      </ng-container>
    </ng-container>
  </span>

  <span wizHeadDescription [onlyDescription]="true">
    <ng-container [ngSwitch]="pageId">
      <ng-container *ngSwitchCase="contractsEnum.stage">{{ jobsContractsMeta.stage.metaDescription }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.alternance">{{
        jobsContractsMeta.alternance.metaDescription
      }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum['jobs-etudiants']">{{
        jobsContractsMeta['jobs-etudiants'].metaDescription
      }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.cdi">{{ jobsContractsMeta.cdi.metaDescription }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum.apprentissage">{{
        jobsContractsMeta.apprentissage.metaDescription
      }}</ng-container>
      <ng-container *ngSwitchCase="contractsEnum['emploi-saisonnier']">{{
        jobsContractsMeta['emploi-saisonnier'].metaDescription
      }}</ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container i18n>Découvrez toutes nos offres d'emploi, de stage et d'alternance&nbsp;</ng-container>
        {{ landing.domain ? landing.domain : ('' | titlecase) }}
        <ng-container *ngIf="landing.city" i18n>à {{ landing.city | titlecase }}</ng-container>
        <ng-container i18n>&nbsp;et postulez en un clic !</ng-container>
      </ng-container>
    </ng-container>
  </span>
</ng-container>

<app-header
  [applicationsTotal]="applicationsTotal$ | async"
  [bookmarksTotal]="(bookmarkMap$ | async)?.size"
  [profile]="profile$ | async"
  [isLogged]="isLogged$ | async"
></app-header>

<app-header-banner class="directory-jobs-banner">
  <div class="container">
    <h1 class="directory-jobs-header__title">
      <span i18n>Emploi&#32;</span><span class="highlight">{{ (landing$ | async)?.title }}</span>
    </h1>
  </div>
</app-header-banner>

<main class="container jobs-list">
  <app-breadcrumb
    *ngIf="breadcrumbSegments$ | async as breadcrumbSegments"
    [segments]="breadcrumbSegments"
    class="breadcrumb"
  >
  </app-breadcrumb>
  <section
    *ngIf="(landing$ | async)?.description && page === 1"
    class="jobs-description"
    [innerHtml]="(landing$ | async)?.description"
  ></section>

  <section *ngIf="(jobs$ | async)?.nbHits > 0" class="jobs-results">
    <h2 class="jobs-results__total">
      <span i18n>Emploi&#32;</span><span>{{ (landing$ | async)?.title }}&nbsp;: </span>
      <ng-container *ngIf="(jobs$ | async)?.nbHits < 1000; else moreThanThousand">
        <span i18n>{{ (jobs$ | async)?.nbHits }} résultat(s)</span>
      </ng-container>
      <ng-template #moreThanThousand>
        <span i18n>+1000 résultats</span>
      </ng-template>
    </h2>

    <job-lightweight
      *ngFor="let job of (jobs$ | async)?.hits; trackBy: trackById"
      [model]="job"
      [application]="(applications$ | async)?.get(job.id)"
      [bookmark]="(bookmarkMap$ | async)?.get(job.id)"
      (toggleBookmark)="toggleBookmark(job)"
      [trackingData]="{ eventCategory: 'Directories', eventAction: 'Click See Job' }"
    ></job-lightweight>

    <div class="jobs-results__pagination pagination" *ngIf="(jobs$ | async)?.nbPages > 1">
      <a
        [routerLink]="buildUrlForPagination()"
        [queryParams]="buildQueryParamsForPagination(-1, (jobs$ | async)?.nbPages)"
        queryParamsHandling="merge"
        rel="prev"
      >
        <app-svg-icon
          class="pagination__icon"
          [class.pagination__icon--active]="page !== 1 || !page"
          icon="exp-left"
          width="1.2em"
        ></app-svg-icon>
      </a>

      <ul class="pagination__list">
        <li *ngFor="let item of computedPagination; trackBy: trackByIndex">
          <a
            [attr.rel]="item.number >= 10 ? 'nofollow' : item.rel ? item.rel : null"
            class="pagination__list__item"
            [routerLink]="buildUrlForPagination()"
            [class.pagination__list__item--active]="page === item.number"
            [queryParams]="{ page: item.number === 1 ? undefined : item.number }"
            >{{ item.number }}</a
          >
        </li>
      </ul>

      <a
        [routerLink]="buildUrlForPagination()"
        queryParamsHandling="merge"
        [queryParams]="buildQueryParamsForPagination(1, (jobs$ | async)?.nbPages)"
        rel="next"
      >
        <app-svg-icon
          [class.pagination__icon--active]="page !== (jobs$ | async)?.nbPages"
          class="pagination__icon"
          icon="exp-right"
          width="1.2em"
        ></app-svg-icon>
      </a>
    </div>

    <div *ngIf="(landing$ | async)?.relatedCity.length > 0" class="jobs-results__links">
      <h2 class="jobs-results__links__title">
        <span i18n>Les offres d’emploi proches de</span> {{ (landing$ | async)?.location.city }}
      </h2>

      <ul class="list">
        <li class="list__item" *ngFor="let city of (landing$ | async)?.relatedCity; trackBy: trackById">
          <a [routerLink]="buildTranslatedUrl(city.slug)" class="list__item__link">
            {{ city.title }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="(landing$ | async)?.relatedCityDomain.length > 0" class="jobs-results__links">
      <h2 class="jobs-results__links__title">
        <span i18n>Emploi&#32;</span>
        <span>{{ (landing$ | async)?.title }}&nbsp;:&#32;</span>
        <span i18n>Ces offres d'emploi pourraient aussi vous intéresser</span>
      </h2>

      <ul class="list">
        <li
          class="list__item"
          *ngFor="let relatedCityDomain of (landing$ | async)?.relatedCityDomain; trackBy: trackById"
        >
          <a [routerLink]="buildTranslatedUrl(relatedCityDomain.slug)" class="list__item__link">
            {{ relatedCityDomain.title }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="(landing$ | async)?.relatedDomain.length > 0" class="jobs-results__links">
      <h2 class="jobs-results__links__title">
        <span i18n>Les offres d'emploi</span> {{ (landing$ | async)?.title }} domain
      </h2>

      <ul class="list">
        <li class="list__item" *ngFor="let relatedDomain of (landing$ | async)?.relatedDomain; trackBy: trackById">
          <pre>{{ relatedDomain | json }}</pre>

          <a [routerLink]="buildTranslatedUrl(relatedDomain.slug)" class="list__item__link">
            {{ relatedDomain.title }}
          </a>
        </li>
      </ul>
    </div>
  </section>
</main>

<app-footer [isLogged]="isLogged$ | async"></app-footer>
