import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nFieldComponent } from '@domains/company/i18n-field/i18n-field/i18n-field.component';

@NgModule({
  imports: [CommonModule],
  declarations: [I18nFieldComponent],
  exports: [I18nFieldComponent],
})
export class I18nFieldModule {}
