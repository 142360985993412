import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() icon: string;

  @Input() label = '';

  @Input() classes = '';

  // For some reason, Angular always renders components with undefined inputs first.
  // `svg` elements without `width`/`height` attributes are typically given a size of 300x150px.
  // To prevent glitches on initial render, this component's `svg` element is not rendered until
  // the `width` input value becomes available to the template.
  @Input() width: string;

  @Input() height: string;

  @Input() viewBox: string;

  @Input() preserveAspectRatio = 'xMinYMax meet';

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>) {}

  // Prevent the component from being rendered during SSR.
  // In SSR svg elements are missing a `styles` attributes and cause errors.
  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
