export enum ProfileSectionEnum {
  Contact = 'CONTACT',
  Educations = 'EDUCATIONS',
  ExtraExperiences = 'EXTRA_EXPERIENCES',
  HardSkills = 'HARD_SKILLS',
  Intro = 'INTRO',
  Languages = 'LANGUAGES',
  PersonalIdentity = 'PERSONAL_IDENTITY',
  ProExperiences = 'PRO_EXPERIENCES',
  SoftSkills = 'SOFT_SKILLS',
  Title = 'TITLE',
  Visibility = 'VISIBILITY',
  Links = 'LINKS',
}
