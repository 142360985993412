import { Contract } from '@models/job/contract';
import { Domain } from '@models/job/domain';
import { Job } from '@models/job/job';
import { Facet } from '@models/search/facet';
import { Page } from '@models/search/page';

export class JobPagination extends Page<Job, { contracts: Contract; domains: Domain }> {
  facetContracts: Facet<Contract>[];
  domainFacets: Facet<Domain>[];

  constructor(data: Partial<JobPagination> = {}) {
    super(data);
    this.facetContracts = this.facets.contracts || [];
    this.domainFacets = this.facets.domains || [];
  }
}
