import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class IsAuthenticatedProfileGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticatedProfile(next.params.profileId);
  }

  private isAuthenticatedProfile(profileId: string): Observable<boolean> {
    return combineLatest([
      this.store.select(SessionState.isInitialized).pipe(filter((isInitialized) => isInitialized)),
      this.store.select(ProfileState.authenticatedProfile),
    ]).pipe(
      take(1),
      map(([_, profile]) => {
        if (profile.id !== profileId) {
          this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
        }

        return profile.id === profileId;
      })
    );
  }
}
