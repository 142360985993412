import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { modalCloseTransition, modalOpenTransition } from '@commons/modal/modal.animation';
import { Store } from '@ngxs/store';
import { Lock, Unlock } from '@store/scroll-lock/scroll-lock.actions';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [modalOpenTransition, modalCloseTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit, OnDestroy, OnInit {
  @HostBinding('@modalOpenTransition') modalOpenTransition = '';
  @HostBinding('@modalCloseTransition') modalCloseTransition = '';

  @Input() layered = false;
  @Input() flushed = false;
  @Input() ieAnyway = false;
  @Input() lockScroll = false;

  @Output() dismiss = new EventEmitter<void>();

  @ViewChild('modal', { static: true }) modal: ElementRef;
  @ViewChild(PerfectScrollbarComponent) perfectScrollbar: PerfectScrollbarComponent;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new Lock());
  }

  ngAfterViewInit(): void {
    this.modal.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Unlock());
  }

  @HostListener('keydown.escape')
  doEscape(): void {
    this.dismiss.emit();
  }
}
