import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GlobalService } from '@core/services/global.service';
import { environment } from '@environment';
import { Consent } from '@models/consent/consent';
import { buildUrl, WINDOW } from '@wizbii/angular-utilities';
import { deserializeJwt, JwtTokens } from '@wizbii/jwt';
import { ArticleTypes } from '@wizbii/models';
import { ContentLegalWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsentApiService {
  private readonly baseUrl = `${environment.api.consent}/v2/consent/:uuid/jobs`;
  private readonly appId = environment.applicationId;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly cookieService: CookieService,
    private readonly contentLegalWebservice: ContentLegalWebservice,
    @Inject(WINDOW) private readonly window: any
  ) {}

  getAll(): Observable<{ [key: string]: boolean } | null> {
    const tokens: JwtTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
    const userId = tokens && tokens.token ? deserializeJwt(tokens.token)['user-id'] : null;
    const uuid = this.cookieService.get('uniqUserId');

    if (!userId && !uuid) {
      return throwError(null);
    }

    if (!tokens || !tokens.token) {
      return this.httpClient.get<{ [key: string]: boolean }>(buildUrl(this.baseUrl, { uuid }), {
        withCredentials: true,
      });
    }

    return this.httpClient.get<{ [key: string]: boolean }>(buildUrl(this.baseUrl, { uuid: userId }), {
      withCredentials: true,
      headers: {
        'x-auth-token': tokens.token,
        'x-auth-type': 'jwt',
        'Cache-Control': 'no-store,no-cache,must-revalidate',
        Pragma: 'no-cache',
      },
    });
  }

  transformToMember(visitorId?: string, token?: string): Observable<void> {
    if (!token || token.trim() === '') {
      return throwError('User not connected');
    }

    return this.httpClient.post<void>(`${environment.api.consent}/v2/consent/transform/${visitorId}`, null, {
      withCredentials: true,
      headers: {
        'x-auth-token': token,
        'x-auth-type': 'jwt',
        'Cache-Control': 'no-store,no-cache,must-revalidate',
        Pragma: 'no-cache',
      },
    });
  }

  sendLastVersionsConsent(type: ArticleTypes, value: boolean): Observable<Consent> {
    const service = type === ArticleTypes.CGU ? 'global' : this.appId;
    return this.contentLegalWebservice.getVersionsByServiceAndType(service, type).pipe(
      switchMap((version) =>
        from(
          this.window.WizbiiGdpr.saveConsent({
            key: type,
            value,
            lastVersion: version && version.lastVersion ? version.lastVersion : null,
          })
        )
      ),
      map((consent) => consent as Consent)
    );
  }
}
