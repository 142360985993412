import { Directive, ElementRef, EventEmitter, Inject, Input, NgModule, OnDestroy, Output } from '@angular/core';
import { WINDOW } from '@wizbii/angular-utilities';

@Directive({
  selector: '[appMutationObserver]',
})
export class MutationObserverDirective implements OnDestroy {
  // By default, observe only direct DOM nodes additions/removals
  @Input('appMutationObserverConfig') config: MutationObserverInit = { childList: true };
  @Output('appMutationObserver') mutated = new EventEmitter<MutationRecord>();

  private readonly observer: MutationObserver;

  constructor(private readonly el: ElementRef, @Inject(WINDOW) private readonly window: any) {
    if (!('MutationObserver' in this.window)) {
      return; // skip SSR and old browsers
    }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        this.mutated.emit(mutation);
      });
    });

    this.observer.observe(this.el.nativeElement, this.config);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

@NgModule({
  declarations: [MutationObserverDirective],
  exports: [MutationObserverDirective],
})
export class MutationObserverModule {}
