import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { WINDOW } from '@wizbii/angular-utilities';
import { Request } from 'express';

@Injectable({ providedIn: 'root' })
export class CustomMetaService {
  private readonly renderer: Renderer2;
  private link: any;

  constructor(
    private readonly metaService: Meta,
    private readonly rendererFactory: RendererFactory2,
    @Inject(WINDOW) private readonly window: any,
    @Inject(DOCUMENT) private readonly document: any,
    @Optional() @Inject(REQUEST) private readonly request: Request
  ) {
    this.renderer = this.rendererFactory.createRenderer(document, {
      id: Date.now().toString(),
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  updateOrAddTag(meta: MetaDefinition): HTMLMetaElement | null {
    if (!meta) {
      return null;
    }

    const key = Object.keys(meta)[0];

    if (this.metaService.getTag(`${key}="${meta[key]}"`)) {
      return this.metaService.updateTag(meta);
    }

    return this.metaService.addTag(meta);
  }

  updateOrAddTags(metas: MetaDefinition[]): HTMLMetaElement[] | null {
    if (!metas) {
      return null;
    }

    return metas.map((meta) => this.updateOrAddTag(meta)).filter((meta) => !!meta);
  }

  updateOrAddCanonicalURL(url?: string): void {
    const canUrl = url
      ? url
      : this.request
      ? [this.request.protocol, '//', this.request.hostname, this.request.path].join('')
      : [this.window.location.protocol, '//', this.window.location.host, this.window.location.pathname].join('');

    if (!this.link) {
      this.link = this.renderer.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.link.setAttribute('href', canUrl);
      this.renderer.appendChild(this.document.head, this.link);
      return;
    }

    this.link.setAttribute('href', canUrl);
  }
}
