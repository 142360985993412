import { environment } from '@environment';
import { TranslatedJobUrls } from '@features/directories/translated-urls';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { DirectoriesRoutingEnum } from '@features/routing/directories/directories-routing.enum';
import { LangEnum } from '@models/commons/locales.enum';
import { buildUrl } from '@wizbii/angular-utilities';

interface FooterLinkInterface {
  name: string;
  link: string;
}

export interface FooterLinksInterface {
  cities: FooterLinkInterface[];
  domains: FooterLinkInterface[];
  contracts: FooterLinkInterface[];
}

export const CityDirectoryLinks: Record<LangEnum, string> = {
  [LangEnum.fr]: `${environment.urls.job.fr_FR}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.VillesFR}`,
  [LangEnum.en]: `${environment.urls.job.en_GB}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.VillesEN}`,
  [LangEnum.it]: `${environment.urls.job.it_IT}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.VillesIT}`,
  [LangEnum.es]: `${environment.urls.job.es_ES}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.VillesES}`,
};

export const DomainDirectoryLinks: Record<LangEnum, string> = {
  [LangEnum.fr]: `${environment.urls.job.fr_FR}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.DomainesFR}`,
  [LangEnum.en]: `${environment.urls.job.en_GB}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.DomainesEN}`,
  [LangEnum.it]: `${environment.urls.job.it_IT}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.DomainesIT}`,
  [LangEnum.es]: `${environment.urls.job.es_ES}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.DomainesES}`,
};

export const ContractDirectoryLink: Record<LangEnum, string> = {
  [LangEnum.fr]: `${environment.urls.job.fr_FR}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.ContractsFR}`,
  [LangEnum.en]: `${environment.urls.job.en_GB}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.ContractsEN}`,
  [LangEnum.it]: `${environment.urls.job.it_IT}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.ContractsIT}`,
  [LangEnum.es]: `${environment.urls.job.es_ES}/${FeaturesRoutingEnum.Directories}/${DirectoriesRoutingEnum.Lp}/${DirectoriesRoutingEnum.ContractsES}`,
};

const domains = {
  ventes: 'ventes',
  conceptionGenieCivilGenieIndustriel: 'conception-genie-civil-genie-industriel',
  comptabiliteControleDeGestion: 'comptabilite-controle-de-gestion',
  hotellerieRestauration: 'hotellerie-restauration',
  servicesALaPersonne: 'services-a-la-personne',
};

export enum ContractsEnum {
  stage = 'stage',
  alternance = 'alternance',
  jobEtudiant = 'job-etudiant',
  emploiSaisonnier = 'saisonnier',
  cdi = 'cdi',
  apprentissage = 'apprentissage',
}

export const FooterLinks: Record<LangEnum, FooterLinksInterface> = {
  [LangEnum.fr]: {
    cities: [
      {
        name: 'Paris',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: 'paris' })}`,
      },
      {
        name: 'Toulouse',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: 'toulouse' })}`,
      },
      {
        name: 'Lyon',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: 'lyon' })}`,
      },
      {
        name: 'Bordeaux',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: 'bordeaux' })}`,
      },
      {
        name: 'Nantes',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: 'nantes' })}`,
      },
    ],
    domains: [
      {
        name: 'Ventes',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], { id: domains.ventes })}`,
      },
      {
        name: 'Conception / Génie civil / Génie industriel',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: domains.conceptionGenieCivilGenieIndustriel,
        })}`,
      },
      {
        name: 'Comptabilité / Contrôle de gestion',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: domains.comptabiliteControleDeGestion,
        })}`,
      },
      {
        name: 'Services à la personne',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: domains.servicesALaPersonne,
        })}`,
      },
      {
        name: 'Hôtellerie - Restauration',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: domains.hotellerieRestauration,
        })}`,
      },
    ],
    contracts: [
      {
        name: 'Stage',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: ContractsEnum.stage,
        })}`,
      },
      {
        name: 'Alternance',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: ContractsEnum.alternance,
        })}`,
      },
      {
        name: 'Job Etudiant',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: ContractsEnum.jobEtudiant,
        })}`,
      },
      {
        name: 'Emploi Saisonnier',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: ContractsEnum.emploiSaisonnier,
        })}`,
      },
      {
        name: 'CDI',
        link: `${environment.urls.job.fr_FR}${buildUrl(TranslatedJobUrls[LangEnum.fr], {
          id: ContractsEnum.cdi,
        })}`,
      },
    ],
  },
  [LangEnum.en]: {
    cities: [
      {
        name: 'London',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'london' })}`,
      },
      {
        name: 'Manchester',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'manchester' })}`,
      },
      {
        name: 'Birmingham',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'birmingham' })}`,
      },
      {
        name: 'Leeds',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'leeds' })}`,
      },
      {
        name: 'Liverpool',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'liverpool' })}`,
      },
    ],
    domains: [
      {
        name: 'Sales',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], { id: 'ventes' })}`,
      },
      {
        name: 'Accounting / Management control',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: domains.comptabiliteControleDeGestion,
        })}`,
      },
      {
        name: 'Design / Civil engineering / Industrial engineering',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: domains.conceptionGenieCivilGenieIndustriel,
        })}`,
      },
      {
        name: 'Personal services',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: domains.servicesALaPersonne,
        })}`,
      },
      {
        name: 'Hotels / Restaurants',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: domains.hotellerieRestauration,
        })}`,
      },
    ],
    contracts: [
      {
        name: 'Traineeship',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: ContractsEnum.stage,
        })}`,
      },
      {
        name: 'Alternation',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: ContractsEnum.alternance,
        })}`,
      },
      {
        name: 'Student job',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: ContractsEnum.jobEtudiant,
        })}`,
      },
      {
        name: 'Seasonal employment',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: ContractsEnum.emploiSaisonnier,
        })}`,
      },
      {
        name: 'Permanent contract',
        link: `${environment.urls.job.en_GB}${buildUrl(TranslatedJobUrls[LangEnum.en], {
          id: ContractsEnum.cdi,
        })}`,
      },
    ],
  },
  [LangEnum.it]: {
    cities: [
      {
        name: 'Milan',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], { id: 'milan' })}`,
      },
      {
        name: 'Roma',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], { id: 'roma' })}`,
      },
      {
        name: 'Bologna',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], { id: 'bologna' })}`,
      },
      {
        name: 'Modena',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], { id: 'modena' })}`,
      },
    ],
    domains: [
      {
        name: 'Vendite',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], { id: 'ventes' })}`,
      },
      {
        name: 'Energia / Materiali / Meccanica',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: 'energie-materiaux-mecanique',
        })}`,
      },
      {
        name: 'Contabilità / Controllo di gestione',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: domains.comptabiliteControleDeGestion,
        })}`,
      },
      {
        name: 'Hôtellerie - Ristorazione',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: domains.hotellerieRestauration,
        })}`,
      },
      {
        name: 'Servizi per la persona',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: domains.servicesALaPersonne,
        })}`,
      },
    ],
    contracts: [
      {
        name: 'Tirocinio',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: ContractsEnum.stage,
        })}`,
      },
      {
        name: 'Alternanza',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: ContractsEnum.alternance,
        })}`,
      },
      {
        name: 'Lavoro studentesco',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: ContractsEnum.jobEtudiant,
        })}`,
      },
      {
        name: 'Lavoro stagionale',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: ContractsEnum.emploiSaisonnier,
        })}`,
      },
      {
        name: 'Contratto a tempo indeterminato',
        link: `${environment.urls.job.it_IT}${buildUrl(TranslatedJobUrls[LangEnum.it], {
          id: ContractsEnum.cdi,
        })}`,
      },
    ],
  },
  [LangEnum.es]: {
    cities: [
      {
        name: 'Madrid',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'madrid' })}`,
      },
      {
        name: 'Barcelona',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'barcelona' })}`,
      },
      {
        name: 'València',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'valencia' })}`,
      },
      {
        name: 'Zaragoza',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'zaragoza' })}`,
      },
      {
        name: 'Sevilla',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'sevilla' })}`,
      },
    ],
    domains: [
      {
        name: 'Ventas',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'ventes' })}`,
      },
      {
        name: 'Marketing',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], { id: 'marketing' })}`,
      },
      {
        name: 'Producción audiovisual',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: 'production-audiovisuelle',
        })}`,
      },
      {
        name: 'Hostelería - restauración',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: domains.hotellerieRestauration,
        })}`,
      },
      {
        name: 'Diseño/Ingeniería civil/Ingeniería industrial',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: domains.conceptionGenieCivilGenieIndustriel,
        })}`,
      },
    ],
    contracts: [
      {
        name: 'Prácticas',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: ContractsEnum.stage,
        })}`,
      },
      {
        name: 'Alternancia',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: ContractsEnum.alternance,
        })}`,
      },
      {
        name: 'Trabajo de estudiante',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: ContractsEnum.jobEtudiant,
        })}`,
      },
      {
        name: 'Empleo estacional',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: ContractsEnum.emploiSaisonnier,
        })}`,
      },
      {
        name: 'CDI',
        link: `${environment.urls.job.es_ES}${buildUrl(TranslatedJobUrls[LangEnum.es], {
          id: ContractsEnum.cdi,
        })}`,
      },
    ],
  },
};
