import { Publication } from '@models/company/publication';
import { Page } from '@models/search/page';

export class GetNews {
  static readonly type = '[Company News] Get';
  constructor(public companyId: string) {}
}

export class GetNewsSuccess {
  static readonly type = '[Company News] Get Success';
  constructor(public companyId: string, public companyPublications: Page<Publication>) {}
}

export class GetNewsFailed {
  static readonly type = '[Company News] Get Failed';
  constructor(public error: any) {}
}

export class LoadMore {
  static readonly type = '[Company News] Load More';
  constructor(public companyId: string) {}
}

export class LoadMoreSuccess {
  static readonly type = '[Company News] Load More Success';
  constructor(public companyId: string, public companyPublications: Page<Publication>) {}
}

export class LoadMoreFailed {
  static readonly type = '[Company News] Load More Failed';
  constructor(public error: any) {}
}
