<article class="redirection container">
  <header class="redirection-header">
    <app-svg-icon icon="wizbii" width="5.75em" height="1.25em" aria-label="WIZBII"></app-svg-icon>
    <h1 class="redirection-header__title" i18n>
      Finalisez vite votre candidature sur le site de
      <span class="redirection-header__company-name">{{ (job$ | async)?.company.name }}</span
      >&nbsp;!
    </h1>
  </header>

  <div class="redirection-loading">
    <div class="wizbii-bee">
      <app-svg-icon class="wizbii-bee__icon" icon="bee" width="2.75em"></app-svg-icon>
    </div>
    <app-dots-loader class="redirection-loading__animation"></app-dots-loader>
    <company-logo
      *ngIf="job$ | async"
      class="redirection-loading__company-logo"
      [company]="(job$ | async)?.company"
    ></company-logo>
  </div>

  <p class="redirection__paragraph" i18n>
    Vous êtes redirigé sur le site <span class="redirection__paragraph-url">{{ externalUrl$ | async }}</span>
  </p>

  <job-lightweight
    *ngIf="job$ | async"
    class="redirection__job"
    [model]="job$ | async"
    [interactive]="false"
    [trackingData]="{ eventCategory: 'Redirection Page', eventAction: 'Click See Job' }"
  ></job-lightweight>

  <footer class="redirection-footer">
    <p i18n>Si vous n'êtes pas redirigé après quelques secondes, <a [href]="externalUrl$ | async">cliquez ici</a></p>
  </footer>
</article>
