import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackButtonModule } from '@commons/back-button/back-button.module';
import { CheckmarkLoaderModule } from '@commons/checkmark-loader/checkmark-loader.module';
import { RadioModule } from '@commons/controls/radio/radio.module';
import { HtmlParserModule } from '@commons/html-parser/html-parser.module';
import { MutationObserverModule } from '@commons/mutation-observer/mutation-observer.directive';
import { PipeModule } from '@commons/pipe/pipe.module';
import { PopupModule } from '@commons/popup/popup.module';
import { ScrollSpyModule } from '@commons/scroll-spy/scroll-spy.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { CompanyModule } from '@domains/company/company.module';
import { BookmarkComponent } from '@domains/job/bookmark/bookmark.component';
import { DurationComponent } from '@domains/job/duration/duration.component';
import { FullweightSkeletonComponent } from '@domains/job/fullweight-skeleton/fullweight-skeleton.component';
import { FullweightComponent } from '@domains/job/fullweight/fullweight.component';
import { MoreOptionsPopupComponent } from '@domains/job/fullweight/more-options-popup/more-options-popup.component';
import { SharePopupComponent } from '@domains/job/fullweight/share-popup/share-popup.component';
import { I18nDomainModule } from '@domains/job/i18n-domain/i18n-domain.module';
import { LightweightSkeletonComponent } from '@domains/job/lightweight-skeleton/lightweight-skeleton.component';
import { LightweightComponent } from '@domains/job/lightweight/lightweight.component';
import { MiniSkeletonComponent } from '@domains/job/mini-skeleton/mini-skeleton.component';
import { MiniComponent } from '@domains/job/mini/mini.component';
import { ReportComponent } from '@domains/job/report/report.component';
import { SkillsComponent } from '@domains/job/skills/skills.component';
import { StatusComponent } from '@domains/job/status/status.component';
import { CityComponent } from '@domains/job/tags/city/city.component';
import { TagsComponent } from '@domains/job/tags/tags.component';

@NgModule({
  imports: [
    CommonModule,
    BackButtonModule,
    CheckmarkLoaderModule,
    CompanyModule,
    RadioModule,
    ReactiveFormsModule,
    SvgIconModule,
    PipeModule,
    ScrollSpyModule,
    PopupModule,
    RouterModule,
    I18nDomainModule,
    HtmlParserModule,
    TrackerModule,
    MutationObserverModule,
  ],
  declarations: [
    BookmarkComponent,
    CityComponent,
    DurationComponent,
    LightweightComponent,
    LightweightSkeletonComponent,
    StatusComponent,
    FullweightComponent,
    ReportComponent,
    SkillsComponent,
    TagsComponent,
    SharePopupComponent,
    MoreOptionsPopupComponent,
    MiniComponent,
    MiniSkeletonComponent,
    FullweightSkeletonComponent,
  ],
  exports: [
    LightweightComponent,
    LightweightSkeletonComponent,
    FullweightComponent,
    StatusComponent,
    TagsComponent,
    FullweightSkeletonComponent,
    SharePopupComponent,
    MiniComponent,
    MiniSkeletonComponent,
    ReportComponent,
    RadioModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JobModule {}
