import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeModule } from '@commons/badge/badge.module';
import { BannerComponent } from '@commons/header/banner/banner.component';
import { HeaderComponent } from '@commons/header/header/header.component';
import { NavLinkDirective } from '@commons/header/nav-link/nav-link.directive';
import { NavbarComponent } from '@commons/header/navbar/navbar.component';
import { NotificationsComponent } from '@commons/header/notifications/notifications.component';
import { ProfilePopupComponent } from '@commons/header/profile-popup/profile-popup.component';
import { SearchHeaderComponent } from '@commons/header/search-header/search-header.component';
import { ModalModule } from '@commons/modal/modal.module';
import { PopupModule } from '@commons/popup/popup.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { AdvertisingModule } from '@domains/advertising/advertising.module';
import { WzbMobileMenuModule } from '@wizbii/angular-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ModalModule,
    PopupModule,
    SvgIconModule,
    BadgeModule,
    AdvertisingModule,
    TrackerModule,
    WzbMobileMenuModule,
  ],
  declarations: [
    ProfilePopupComponent,
    NavbarComponent,
    NotificationsComponent,
    HeaderComponent,
    SearchHeaderComponent,
    BannerComponent,
    NavLinkDirective,
  ],
  exports: [HeaderComponent, SearchHeaderComponent, BannerComponent, NavbarComponent, NavLinkDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
