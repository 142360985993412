import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { City } from '@wizbii/models';

@Component({
  selector: 'job-city',
  templateUrl: './city.component.html',
  styleUrls: ['../tags.component.scss'], // eslint-disable-line  @angular-eslint/relative-url-prefix
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityComponent {
  @Input() location: City;
  @Input() addStructuredData = false;
}
