import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { DataStorageService, WINDOW } from '@wizbii/angular-utilities';

@Component({
  selector: 'app-browser-compatibility',
  templateUrl: './browser-compatibility.component.html',
  styleUrls: ['./browser-compatibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowserCompatibilityComponent {
  static BROWSER_MODAL_HAS_OPENED = 'BROWSER_MODAL_HAS_OPENED';

  onMac: boolean;
  displayBrowserModal = false;

  constructor(
    private readonly dataStorage: DataStorageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    @Inject(WINDOW) private readonly window: any,
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>,
    private readonly errorHandlerService: BugsnagService
  ) {
    if (isPlatformBrowser(platformId)) {
      this.onMac = !!this.window.navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
      const alreadyOpened = this.dataStorage.get(BrowserCompatibilityComponent.BROWSER_MODAL_HAS_OPENED) || false;

      if (!alreadyOpened && this.isOldBrowser()) {
        this.displayBrowserModal = true;
      }
    }
  }

  doCloseModal(): void {
    try {
      this.dataStorage.put(BrowserCompatibilityComponent.BROWSER_MODAL_HAS_OPENED, true);
    } catch (error) {
      this.errorHandlerService.sendError(
        {
          errorClass: error,
          errorMessage: `Unable to save ${BrowserCompatibilityComponent.BROWSER_MODAL_HAS_OPENED} to local storage`,
        },
        'warning'
      );
    }

    this.displayBrowserModal = false;
    this.cdr.markForCheck();
  }

  private isOldBrowser(): boolean {
    return this.window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('display') !== 'none';
  }
}
