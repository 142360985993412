import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AdditionnalParticipation, Participation } from '@models/ops-event/participation';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const EventsNotRelatedToJobSearching = ['ma_coloc_sure'];

@Injectable()
export class ParticipationWebservice {
  private readonly baseUrl = `${environment.api.opsEvent}/candidate/participation/:eventId`;
  private readonly baseTypedUrl = `${environment.api.opsEvent}/candidate/participation/:eventType/:eventId`;
  private readonly afterRegistrationUrl = `${environment.api.opsEvent}/candidate/:candidateId/participation/:eventId/after-registration`;

  constructor(private readonly http: HttpClient) {}

  create(eventId: string, participation: Participation, eventType?: string): Observable<{ id: string }> {
    const url =
      eventType && EventsNotRelatedToJobSearching.indexOf(eventType) !== -1
        ? buildUrl(this.baseTypedUrl, { eventType, eventId })
        : buildUrl(this.baseUrl, { eventId });

    return this.http.post<{ id: string }>(url, participation).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.error.status === 'ERROR' &&
          err.error.message &&
          err.error.message.endsWith('already registered to this event')
        ) {
          // eslint-disable-next-line no-console
          console.warn('User already registered');
          return of(null);
        }

        throw err;
      })
    );
  }

  update(eventId: string, candidateId: string, data: AdditionnalParticipation): Observable<void> {
    return this.http.put<void>(buildUrl(this.afterRegistrationUrl, { eventId, candidateId }), data);
  }
}
