import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '@commons/controls/checkbox/checkbox.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, FormsModule, SvgIconModule],
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
