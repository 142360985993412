import { NewsFeedTypeEnum } from '@models/company/news-feed-type.enum';
import { Publication } from '@models/company/publication';
import { Page } from '@models/search/page';

export class CompanyPublicationPagination extends Page<Publication> {
  newsFeedType: NewsFeedTypeEnum;

  constructor(data: Partial<CompanyPublicationPagination> = {}) {
    super(data);
    this.newsFeedType = data.newsFeedType;
  }
}
