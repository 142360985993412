<div *ngIf="!layered" class="backdrop" (click)="dismiss.emit()"></div>

<div
  #modal
  class="modal"
  [class.modal--centered]="!layered"
  [class.modal--padded]="!flushed"
  [class.modal--ie-anyway]="ieAnyway"
  tabindex="-1"
  role="dialog"
  cdkTrapFocus
>
  <perfect-scrollbar class="modal-ps" [disabled]="lockScroll">
    <div class="modal__inner">
      <ng-content></ng-content>
    </div>
  </perfect-scrollbar>
</div>
