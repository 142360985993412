import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import {
  CityDirectoryLinks,
  DomainDirectoryLinks,
  FooterLinks,
  FooterLinksInterface,
} from '@commons/footer/footer/translated-links';
import { trackByString } from '@core/utils/utils';
import { environment } from '@environment';
import { TranslatedCityUrls, TranslatedDomainUrls } from '@features/directories/translated-urls';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LocaleEnum } from '@models/commons/locales.enum';
import { DirectoriesRoutingEnum } from '@routing/directories/directories-routing.enum';
import { buildPath, WINDOW } from '@wizbii/angular-utilities';
import { trackEvent } from '@wizbii/tracking';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() isLogged: boolean;

  trackEvent = trackEvent;
  appId = environment.applicationId;
  apiDomain = environment.apiDomain;
  contactEmailKey = environment.contactEmailKey;

  subDomainByLocale: Record<LocaleEnum, string> = {
    [LocaleEnum.fr_FR]: 'jobs',
    [LocaleEnum.en_GB]: 'en',
    [LocaleEnum.es_ES]: 'es',
    [LocaleEnum.it_IT]: 'it',
  };

  allInitials: string[] = [
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  allLocales: LocaleEnum[] = Object.values(LocaleEnum);
  searchUrl: string;
  companyUrl: string;
  eventUrl: string;
  usersUrl: string;
  companiesUrl: string;
  jobsUrl: string;
  domainsUrl: string;
  citiesUrl: string;

  cguUrl = `https://legal.${environment.apiDomain}/${environment.legalLang}/cpu/jobs?utm_source=jobs&utm_medium=footer`;
  policyUrl = `https://legal.${environment.apiDomain}/${environment.legalLang}/privacy-policy/jobs?utm_source=jobs&utm_medium=footer`;
  legalUrl = `https://legal.${environment.apiDomain}/${environment.legalLang}/legal-notice?utm_source=jobs&utm_medium=footer`;
  policyCookieUrl = `https://legal.${environment.apiDomain}/${environment.legalLang}/privacy-policy-cookie/jobs?utm_source=jobs&utm_medium=footer`;
  promotionsUrl = `https://legal.${environment.apiDomain}/${environment.legalLang}/promotions/jobs?utm_source=jobs&utm_medium=footer`;

  links: FooterLinksInterface;
  isLocaleFrance = environment.locale === LocaleEnum.fr_FR;

  localeSelected: LocaleEnum;
  trackByString = trackByString;

  trackingData = { eventCategory: 'Footer', eventAction: 'Click Social' };

  cookieName = 'preferred-locale';
  cookieExpiryName = 'preferred-locale_expiry';

  EmploymentRoutingByLocale: Record<LocaleEnum, string> = {
    [LocaleEnum.fr_FR]: 'emploi',
    [LocaleEnum.en_GB]: 'job-offers',
    [LocaleEnum.es_ES]: 'empleo',
    [LocaleEnum.it_IT]: 'impieghi',
  };

  DirectoriesRoutingByLocale: Record<LocaleEnum, DirectoriesRoutingEnum> = {
    [LocaleEnum.fr_FR]: DirectoriesRoutingEnum.DomainesFR,
    [LocaleEnum.en_GB]: DirectoriesRoutingEnum.DomainesEN,
    [LocaleEnum.es_ES]: DirectoriesRoutingEnum.DomainesES,
    [LocaleEnum.it_IT]: DirectoriesRoutingEnum.DomainesIT,
  };

  get isFromBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  constructor(
    private readonly cookieService: CookieService,
    @Inject(WINDOW) private readonly window: any,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {
    this.searchUrl = `${environment.urls.job[environment.locale]}/${FeaturesRoutingEnum.Search}`;
    this.companyUrl = buildPath(FeaturesRoutingEnum.Company);
    this.eventUrl = `${environment.urls.job[environment.locale]}/${FeaturesRoutingEnum.Events}`;
    this.companiesUrl = DomainDirectoryLinks[environment.lang];
    this.jobsUrl = CityDirectoryLinks[environment.lang];

    const { lang } = environment;
    this.domainsUrl = TranslatedDomainUrls[lang];
    this.citiesUrl = TranslatedCityUrls[lang];
    this.links = FooterLinks[lang];

    this.localeSelected = environment.locale;
  }

  redirectLocale(locale: string): void {
    const url = new URL(this.window.location.href);
    const domain = url.pathname.split('/')[1];
    let pathname = url.pathname;

    const directoriesDomains = [
      DirectoriesRoutingEnum.DomainesEN,
      DirectoriesRoutingEnum.DomainesES,
      DirectoriesRoutingEnum.DomainesIT,
      DirectoriesRoutingEnum.DomainesFR,
    ];
    if (directoriesDomains.includes(domain as DirectoriesRoutingEnum)) {
      pathname = url.pathname.replace(domain, this.DirectoriesRoutingByLocale[locale]);
    }

    const jobDomains = ['job-offers', 'impieghi', 'empleo', 'emploi'];
    if (jobDomains.includes(domain)) {
      pathname = url.pathname.replace(domain, this.EmploymentRoutingByLocale[locale]);
    }

    this.window.open(
      locale === LocaleEnum.fr_FR && pathname === '/'
        ? `https://jobs.${environment.apiDomain}`
        : `${environment.urls.job[locale]}${pathname}`,
      '_self'
    );
  }

  updatePreferredLocale(locale: string): void {
    this.redirectLocale(locale);
    if (locale !== null && locale !== this.localeSelected) {
      const cookieDomain = this.getCookieDomain();
      const expiryExists = this.cookieService.check(this.cookieExpiryName);
      const msIn390Days = 1000 * 3600 * 24 * 390;
      const expiry = expiryExists
        ? new Date(this.cookieService.get(this.cookieExpiryName))
        : new Date(Date.now() + msIn390Days);

      if (!expiryExists) {
        this.cookieService.set(
          this.cookieExpiryName,
          expiry.getTime().toString(),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      }

      this.cookieService.set(
        this.cookieName,
        locale,
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );

      if (isPlatformBrowser(this.platformId)) {
        this.redirectLocale(locale);
      }
    }
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }

  trackByInitial(_: any, initial: string): string {
    return initial;
  }

  trackByName(_: any, item: { name: string; link: string }): string {
    return item.name;
  }
}
