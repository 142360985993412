import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EventTrackerDirective } from '@commons/tracker/event-tracker/event-tracker.directive';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { ConsentsStoreModule } from '@store/consents/consents.store.module';

@NgModule({
  imports: [CommonModule, HttpClientModule, ConsentsStoreModule],
  declarations: [EventTrackerDirective],
  exports: [EventTrackerDirective],
})
export class TrackerModule {
  static forRoot(): ModuleWithProviders<TrackerModule> {
    return {
      ngModule: TrackerModule,
      providers: [EventTrackerService],
    };
  }
}
