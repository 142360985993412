import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IterablePipe } from '@commons/pipe/iterable/iterable.pipe';
import { NewlinePipe } from '@commons/pipe/newline/newline.pipe';
import { RawPipe } from '@commons/pipe/raw/raw.pipe';
import { RemovePrePipe } from '@commons/pipe/remove-pre/remove-pre.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [NewlinePipe, RawPipe, IterablePipe, RemovePrePipe],
  declarations: [NewlinePipe, RawPipe, IterablePipe, RemovePrePipe],
  exports: [NewlinePipe, RawPipe, IterablePipe, RemovePrePipe],
})
export class PipeModule {}
