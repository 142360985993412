import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environment';
import { LangEnum, LocaleEnum } from '@models/commons/locales.enum';
import { GeoPoint } from '@wizbii/models';
import { EMPTY, MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Element node type guard.
 */
function isElementNode(node: Node): node is Element {
  return node.nodeType === 1; // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
}

/**
 * Add `rel` attribute to any links present inside dynamic content.
 * Use together with the `appMutationObserver` directive.
 */
export function secureExternalLinks(mutation: MutationRecord): void {
  if (mutation.type !== 'childList' || !isElementNode(mutation.target)) {
    return;
  }

  Array.from(mutation.target.querySelectorAll('a')).forEach((anchor) => {
    anchor.setAttribute('rel', 'nofollow noreferrer noopener');
  });
}

export function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

/*
 * Haversine formula :
 * determines the great-circle distance between two points on a sphere given their longitudes and latitudes
 */
export function computeDistance(location1: GeoPoint, location2: GeoPoint): number {
  const lat1 = location1.lat;
  const lon1 = location1.lon;
  const lat2 = location2.lat;
  const lon2 = location2.lon;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

export function buildPartnerWidgetUrl(packageId: string): string {
  if (!packageId) {
    return undefined;
  }
  const partnerHostname = isOldJobplusPackageId(packageId)
    ? environment.legacyPartnersDomain
    : environment.api.publicPartner;
  return `${partnerHostname}/w/${packageId}.js?z=${Date.now()}`;
}

export function isOldJobplusPackageId(packageId: string): boolean {
  return packageId.indexOf('ca') === 0 && packageId.indexOf('v1') > 0;
}

export function trackById(index: number, value: any): string {
  if ('id' in value) {
    return value.id;
  }
  return index.toString(10);
}

export function trackByIndex(index: number): number {
  return index;
}

export function trackByString(_: number, value: string): string {
  return value;
}

export function langFromLocale(locale: LocaleEnum): LangEnum {
  // can happen because all jobs locales are not in the enum
  const normalizedLocale = locale in LocaleEnum ? locale : LocaleEnum.fr_FR;

  return LangEnum[normalizedLocale.split('_')[0].toLowerCase()];
}

const ERASMUS_COUNTRY_NATIVE = [
  'België', // Belgique
  'Ελλάδα', // Grèce
  'Lietuva', // Lituanie
  'Portugal', // Portugal
  'България', // Bulgarie
  'España', // Espagne
  'Luxembourg', // Luxembourg
  'România', // Roumanie
  'Česká republika', // République tchèque
  'Magyarország', // Hongrie
  'Slovenija', // Slovénie
  'Danmark', // Danemark
  'Hrvatska', // Croatie
  'Malta', // Malte
  'Slovensko', // Slovaquie
  'Deutschland', // Allemagne
  'Italia', // Italie
  'Nederland', // Pays-Bas
  'Suomi', // Finlande
  'Eesti', // Estonie
  'Κύπρος', // Chypre
  'Österreich', // Autriche
  'Sverige', // Suède
  'Éire', // Irlande
  'United Kingdom', // Royaume-Uni
  'Latvija', // Lettonie
  'Polska', // Pologne
  'United Kingdom', // Royaume-Uni
  'Македонија', // Macédoine
  'Ísland', // Islande
  'Norge', // Norvège
  'Liechtenstein', // Liechtenstein
  'Türkiye', // Turquie
  'Србија', // Serbie
  'Shqipëria', // Albanie
  'Bosna i Hercegovina', // Bosnie-Herzégovine
  'Republika e Kosovës', // Kososo
  'Црна Гора', // Monténégro
  'Հայաստան', // Arménie
  'Azərbaycan', // Azerbaïdjan
  'Белару́сь', // Biélorussie
  'საქართველო', // Géorgie
  'South Georgia', // Géorgie du Sud-et-les Îles Sandwich du Sud
  'Moldova', // Moldavie
  'Україна', // Ukraine
  'الجزائر', // Algérie
  'مصر‎', // Égypte
  'יִשְׂרָאֵל', // Israël
  'الأردن', // Jordanie
  'لبنان', // Liban
  '‏ليبيا', // Libye
  'المغرب', // Maroc
  'فلسطين', // Palestine
  'سوريا', // Syrie
  'تونس', // Tunisie
  'Россия', // Russie
  'Schweiz', // Suisse
];

export function isErasmusPartner(country: string): boolean {
  // We need to use includes because of country with multiple languages
  // We only check if the whole "country" field contains at least one of the native name
  // ex: Switzerland is displayed as `Schweiz, Suisse, Svizzera, Svizra`
  return ERASMUS_COUNTRY_NATIVE.some((e) => country.includes(e));
}

/**
 * Create a `catchError` operator based on a type guard:
 * - errors that match the type guard are swallowed;
 * - all remaining errors are re-thrown.
 *
 * The returned RxJS operator accepts a callback that is called whenever
 * an error matching the type guard is caught. Use this callback to store
 * the error and/or notify the error handler service.
 *
 * When an `HttpErrorResponse` is caught, the real error is extracted
 * before being matched against the type guard.
 */
export function makeErrorCatcher<K>(typeGuard: (error: any) => error is K) {
  return function customCatchError<T>(callback: (err: K) => void): MonoTypeOperatorFunction<T> {
    return catchError((err: any) => {
      const realError = err instanceof HttpErrorResponse ? err.error : err;

      if (realError && typeGuard(realError)) {
        callback(realError);
        return EMPTY;
      }

      return throwError(err);
    });
  };
}

export interface BackEndError {
  type: string;
  [key: string]: any;
}

export function isBackEndError(error: any): error is BackEndError {
  return !!error.type;
}

export const catchBackEndError = makeErrorCatcher(isBackEndError);
