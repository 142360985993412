<wiz-header
  class="wiz-header"
  current="emploi"
  [attr.app]="appId"
  cookiekey="wizbii_tokens"
  [attr.apidomain]="apiDomain"
  [attr.accountredirectlogout]="redirectUrl"
  [attr.currentlocale]="locale"
></wiz-header>

<header *ngIf="(isMobileOrTablet$ | async) === true" class="header-mobile">
  <wzb-mobile-menu class="header-mobile__burger" [closeOnItemClick]="true" [animationFromLeft]="true">
    <ng-container mobileMenuOpenIcon>
      <app-svg-icon icon="burger-menu" width="1.25em" height="1.25em"></app-svg-icon>
    </ng-container>
    <ng-container mobileMenuLogo>
      <a
        class="header-mobile__modal-logo"
        [href]="urlHome"
        aria-label="Page d'accueil WIZBII Jobs"
        i18n-aria-label
        appEventTracker
        eventCategory="Main Navigation"
        eventAction="Click Logo"
      >
        <app-svg-icon icon="wizbii-jobs" width="5.4375em" height="1.875em"></app-svg-icon>
      </a>
    </ng-container>
    <ng-container mobileMenuListInside>
      <ng-container *ngTemplateOutlet="navbar"></ng-container>
    </ng-container>
  </wzb-mobile-menu>

  <a
    [href]="urlHome"
    aria-label="Page d'accueil WIZBII Jobs"
    i18n-aria-label
    appEventTracker
    eventCategory="Main Navigation"
    eventAction="Click Logo"
  >
    <app-svg-icon icon="wizbii-jobs" width="5.4375em" height="1.875em"></app-svg-icon>
  </a>

  <div *ngIf="isLogged" class="header-mobile__user-profile header__user-infos user-infos">
    <app-profile-popup class="user-infos__profile" [profile]="profile"></app-profile-popup>
  </div>
</header>

<header
  *ngIf="(isMobileOrTablet$ | async) === false"
  class="header"
  [class.header--opaque]="!isLogged && opaque"
  [class.header--invisible]="!isLogged && !showSticky"
>
  <a
    [href]="urlHome"
    class="header__logo"
    aria-label="Page d'accueil WIZBII"
    i18n-aria-label
    appEventTracker
    eventCategory="Main Navigation"
    eventAction="Click Logo"
  >
    <app-svg-icon icon="wizbii" width="5.75em" height="1.25em"></app-svg-icon>
  </a>

  <ng-container *ngTemplateOutlet="navbar"></ng-container>

  <ng-container *ngTemplateOutlet="userProfile"></ng-container>
</header>

<ng-template #userProfile>
  <div *ngIf="isLogged; else userConnect" class="header__user-infos user-infos">
    <a
      [routerLink]="profile && [profileSegment, profile.id]"
      class="user-infos__link"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click my CV"
    >
      <span
        class="user-infos__label"
        [appNavLink]="navLinkEnum.Profile"
        appNavLinkActive="user-infos__label--active"
        i18n
        >Mon CV</span
      >
    </a>
    <a
      [routerLink]="profile && [profileSegment, profile.id, bookmarksSegment]"
      class="user-infos__link"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Favorite"
    >
      <span
        class="user-infos__label"
        [appNavLink]="navLinkEnum.Bookmarks"
        appNavLinkActive="user-infos__label--active"
        i18n
        >Mes favoris</span
      >
      <app-badge *ngIf="bookmarksTotal > 0" [content]="bookmarksTotal" class="user-infos__badge"></app-badge>
    </a>
    <a
      [routerLink]="profile && [profileSegment, profile.id, applicationsSegment]"
      class="user-infos__link user-infos__link--last"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Applications"
    >
      <span
        class="user-infos__label"
        [appNavLink]="navLinkEnum.Applications"
        appNavLinkActive="user-infos__label--active"
        i18n
        >Mes candidatures</span
      >
      <app-badge *ngIf="applicationsTotal > 0" [content]="applicationsTotal" class="user-infos__badge"></app-badge>
    </a>
  </div>
</ng-template>

<ng-template #userConnect>
  <div class="header__user-sign user-sign" [class.user-sign--opaque]="opaque">
    <a class="user-sign__link btn-clean" [href]="accountUrlSignin" i18n> Me connecter </a>

    <a class="user-sign__btn btn btn--shadow" [class.user-sign__btn--opaque]="opaque" [href]="accountUrlSignup" i18n>
      Créer un compte
    </a>
  </div>
</ng-template>

<ng-template #navbar>
  <div *ngIf="isLogged; else unlogged" class="nav__group">
    <a
      class="nav__item"
      [routerLink]="[jobsPath]"
      [appNavLink]="navLinkEnum.Jobs"
      appNavLinkActive="nav__item--active"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Jobs"
    >
      <span class="nav__item-inner highlight" i18n>Jobs</span>
    </a>

    <a
      *ngIf="isLocaleFrance"
      class="nav__item"
      [routerLink]="[eventsPath]"
      [appNavLink]="navLinkEnum.Events"
      appNavLinkActive="nav__item--active"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Events"
    >
      <span class="nav__item-inner highlight" i18n>Événements</span>
    </a>

    <a
      class="nav__item"
      [routerLink]="[companiesPath]"
      [appNavLink]="navLinkEnum.Companies"
      appNavLinkActive="nav__item--active"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Jobs Companies"
    >
      <span class="nav__item-inner highlight" i18n>Entreprises</span>
    </a>

    <advertising-menu-entry class="nav__advertising"></advertising-menu-entry>
  </div>
</ng-template>

<ng-template #unlogged>
  <div class="nav__group">
    <a class="nav__item btn-clean" [href]="accountUrlSignup">
      <span class="nav__item-inner" i18n>Jobs</span>
    </a>

    <a
      *ngIf="isLocaleFrance"
      class="nav__item"
      [routerLink]="[eventsPath]"
      [appNavLink]="navLinkEnum.Events"
      appNavLinkActive="nav__item--active"
      appEventTracker
      eventCategory="Main Navigation"
      eventAction="Click Events"
    >
      <span class="nav__item-inner highlight" i18n>Événements</span>
    </a>

    <a class="nav__item btn-clean" [href]="accountUrlSignup">
      <span class="nav__item-inner" i18n>Entreprises</span>
    </a>

    <a class="nav__login-link btn-clean" [href]="accountUrlSignin" i18n>Se connecter</a>

    <a class="nav__register-link btn-clean" [href]="accountUrlSignup" i18n>Créer un compte</a>

    <advertising-menu-entry class="nav__advertising"></advertising-menu-entry>
  </div>
</ng-template>
