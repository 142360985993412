import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { AlreadySessionedGuard } from '@core/guards/already-sessioned.guard';
import { CompanyGuard } from '@core/guards/company.guard';
import { HasNpsParamsGuard } from '@core/guards/has-nps-params.guard';
import { InitGuard } from '@core/guards/init.guard';
import { IsAuthenticatedProfileGuard } from '@core/guards/is-authenticated-profile.guard';
import { OnboardingFinishedGuard } from '@core/guards/onboarding-finished.guard';
import { SessionedGuard } from '@core/guards/sessioned.guard';
import { UserIsFrenchGuard } from '@core/guards/user-is-french.guard';
import { environment } from '@environment';
import { LayoutComponent as CompanyLayoutComponent } from '@features/companies/layout/layout.component';
import { ErrorModule } from '@features/error/error.module';
import { FeaturesRoutingModule } from '@features/features-routing.module';
import { ProfileUnloggedGuard } from '@routing/profile-unlogged/profile-unlogged.guard';
import { FileStoreService } from '@store/file/file-store.service';
import { JobsStoreModule } from '@store/job/jobs.store.module';
import { ApplicationStoreService } from '@store/profile/application-store.service';
import { CvStoreService } from '@store/profile/cv-store.service';
import { SeoStoreModule } from '@store/seo/seo.store.module';
import { QueryParamsStoreService } from '@store/session/query-params-store.service';
import { AccountWebservice } from '@webservices/account-api/account.webservice';
import { NotificationWebservice } from '@webservices/account-api/notification.webservice';
import { CompanyWebservice } from '@webservices/company-api/company.webservice';
import { NewsWebservice } from '@webservices/company-api/news.webservice';
import { ContractWebservice } from '@webservices/job-api/contract.webservice';
import { JobWebservice } from '@webservices/job-api/job.webservice';
import { ReportWebservice } from '@webservices/job-api/report.webservice';
import { LoyaltyLegacyWebservice } from '@webservices/loyalty-legacy-api/loyalty-legacy.webservice';
import { MediaWebservice } from '@webservices/media-api/media.webservice';
import { OpsEventWebservice } from '@webservices/ops-event-api/ops-event.webservice';
import { ParticipationWebservice } from '@webservices/ops-event-api/participation.webservice';
import { ApplicationWebservice } from '@webservices/profile-api/application.webservice';
import { BookmarkWebservice } from '@webservices/profile-api/bookmark.webservice';
import { CvWebservice } from '@webservices/profile-api/cv.webservice';
import { JobAlertWebservice } from '@webservices/profile-api/job-alert.webservice';
import { ProfileWebservice } from '@webservices/profile-api/profile.webservice';
import { SatisfactionService } from '@webservices/satisfaction-api/satisfaction.service';
import { DegreeWebservice } from '@webservices/school-api/degree.webservice';
import { SchoolWebservice } from '@webservices/school-api/school.webservice';
import { CompanySearchWebservice } from '@webservices/search-api/company-search.webservice';
import { JobSearchWebservice } from '@webservices/search-api/job-search.webservice';
import { SchoolSearchWebservice } from '@webservices/search-api/school-search.webservice';
import { SearchStatsWebservice } from '@webservices/search-stats-api/search-stats.webservice';
import { SeoWebservice } from '@webservices/seo-api/seo.webservice';
import { ContactModalModule } from '@wizbii/angular-ui';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import {
  CONTACT_API_CONFIG,
  ContactWebservice,
  CONTENT_API_CONFIG as WIZBII_CONTENT_API_CONFIG,
  ContentLegalWebservice,
  FILE_API_CONFIG,
  FileWebservice,
} from '@wizbii/webservices';

@NgModule({
  imports: [
    CommonModule,
    ErrorModule,
    FeaturesRoutingModule,
    SeoStoreModule,
    HeadTitleModule,
    HeadDescriptionModule,
    JobsStoreModule,
    TrackerModule,
    ContactModalModule,
  ],
  declarations: [CompanyLayoutComponent],
  providers: [
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },

    FileWebservice,

    AccountWebservice,
    LoyaltyLegacyWebservice,
    ApplicationWebservice,
    BookmarkWebservice,
    CompanySearchWebservice,
    CompanyWebservice,
    CvWebservice,
    DegreeWebservice,
    JobAlertWebservice,
    JobSearchWebservice,
    JobWebservice,
    MediaWebservice,
    NewsWebservice,
    NotificationWebservice,
    OpsEventWebservice,
    ProfileWebservice,
    ReportWebservice,
    SatisfactionService,
    SchoolSearchWebservice,
    SchoolWebservice,
    SeoWebservice,
    ParticipationWebservice,
    SearchStatsWebservice,
    ContractWebservice,
    JobAlertWebservice,
    {
      provide: WIZBII_CONTENT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.content,
        locale: environment.locale,
      },
    },
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
      },
    },
    ContactWebservice,

    ContentLegalWebservice,

    ApplicationStoreService,
    CvStoreService,
    FileStoreService,
    QueryParamsStoreService,

    SessionedGuard,
    AlreadySessionedGuard,
    IsAuthenticatedProfileGuard,
    OnboardingFinishedGuard,
    InitGuard,
    CompanyGuard,
    ProfileUnloggedGuard,
    HasNpsParamsGuard,
    UserIsFrenchGuard,
  ],
})
export class FeaturesModule {}
