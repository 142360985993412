import { ExtractParams } from '@wizbii/angular-utilities';

const ORIGINAL_VIEWPORT = {
  WIDTH: 900,
  HEIGHT: 250,
};

export class BannerInfo implements ExtractParams {
  top: number;
  left: number;
  areawidth: number;
  areaheight: number;

  constructor(data = {}) {
    this.top = Math.abs(data['offsetY'] / data['zoom']);
    this.left = Math.abs(data['offsetX'] / data['zoom']);
    this.areawidth = ORIGINAL_VIEWPORT.WIDTH / data['zoom'];
    this.areaheight = ORIGINAL_VIEWPORT.HEIGHT / data['zoom'];
  }
}
