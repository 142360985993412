<ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
  <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="breadcrumb__list">
    <a
      itemprop="item"
      class="breadcrumb__segment"
      [class.breadcrumb__segment--unlogged]="!isLogged && isMobile"
      [href]="urlHome"
    >
      <span itemprop="name" i18n>Accueil</span>
    </a>
    <meta itemprop="position" [content]="1" />
  </li>

  <app-svg-icon
    *ngIf="segments && segments.length > 0"
    class="breadcrumb__separator"
    [class.breadcrumb__separator--unlogged]="!isLogged && isMobile"
    icon="chevron-down"
    width=".5em"
  ></app-svg-icon>

  <ng-container *ngFor="let segment of segments; trackBy: trackByTitle; let last = last; let index = index">
    <li
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
      class="breadcrumb__list"
      [class.breadcrumb__list--last]="last"
    >
      <a
        itemprop="item"
        class="breadcrumb__segment"
        [class.breadcrumb__segment--active]="last"
        [class.breadcrumb__segment--unlogged]="!isLogged && isMobile"
        [routerLink]="segment.link"
      >
        <span itemprop="name"><app-i18n-domain [model]="segment.title"></app-i18n-domain></span>
      </a>
      <meta itemprop="position" [content]="index + 1" />
    </li>

    <app-svg-icon
      *ngIf="!last"
      class="breadcrumb__separator"
      [class.breadcrumb__separator--unlogged]="!isLogged && isMobile"
      icon="chevron-down"
      width=".5em"
    ></app-svg-icon>
  </ng-container>
</ol>
