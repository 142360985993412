export class Publication {
  id: string;
  companyId: string;
  companyName: string;
  imageFileUrl: string;
  content: string;
  link: string;
  attachmentTitle: string;
  attachmentContent: string;
  attachmentLink: string;
  dateModified: Date;

  constructor(data = {}) {
    this.id = data['id'];
    this.companyId = data['companyId'];
    this.companyName = data['companyName'];
    this.imageFileUrl = data['imageFileUrl'];
    this.content = data['content'];
    this.link = data['link'];
    this.attachmentTitle = data['attachmentTitle'];
    this.attachmentContent = data['attachmentContent'];
    this.attachmentLink = data['attachmentLink'];
    this.dateModified = data['dateModified'] ? new Date(data['dateModified']) : undefined;
  }
}
