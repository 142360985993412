export enum LinkTypeEnum {
  AppleStore = 'APPLE_STORE',
  Other = 'AUTRE',
  Behance = 'BEHANCE',
  Blog = 'BLOG',
  Dribbble = 'DRIBBBLE',
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  GoogleStore = 'GOOGLE_STORE',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Pinterest = 'PINTEREST',
  Skype = 'SKYPE',
  Tumblr = 'TUMBLR',
  Twitter = 'TWITTER',
  Viadeo = 'VIADEO',
  Youtube = 'YOUTUBE',
}
