import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { SearchStat } from '@models/search-stats/search-stat';
import { Facet } from '@models/search/facet';
import { FacetModelInterface } from '@models/search/facet-model.interface';
import { JobFilter } from '@models/search/job-filter';
import { buildUrl } from '@wizbii/angular-utilities';

@Injectable()
export class SearchStatsWebservice {
  private readonly statsUrl = `${environment.api.searchStats}/search/:type`;

  constructor(private readonly http: HttpClient) {}

  // ADR #007
  addStat(type: string, searchStat: SearchStat<JobFilter>): void {
    const url = buildUrl(this.statsUrl, { type });
    this.http
      .post<void>(url, {
        ...searchStat,
        filters: {
          ...searchStat.filters,
          contracts: this.flattenFacet(searchStat.filters.contracts),
          domains: this.flattenFacet(searchStat.filters.domains),
          locale: environment.locale,
        },
      })
      .subscribe();
  }

  private flattenFacet(models?: Facet<FacetModelInterface>[]): FacetModelInterface[] {
    return models && models.map((item) => item.model);
  }
}
