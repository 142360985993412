import { Directive, HostBinding, HostListener } from '@angular/core';
import { PopupComponent } from '@commons/popup/popup/popup.component';

@Directive({
  selector: 'button[type="button"][appPopupTrigger]',
})
export class PopupTriggerDirective {
  constructor(private readonly parent: PopupComponent) {}

  @HostBinding('id')
  get id(): string {
    return `${this.parent.id}__trigger`;
  }

  @HostBinding('attr.aria-controls')
  get menuId(): string {
    return `${this.parent.id}__menu`;
  }

  @HostBinding('attr.aria-expanded')
  get ariaExpanded(): 'true' | 'false' {
    return this.parent.opened ? 'true' : 'false';
  }

  @HostBinding('aria-haspopup') hasPopup = true;

  @HostListener('click')
  onClick(): void {
    this.parent.toggle();
  }
}
