import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable()
export class NotificationWebservice {
  private readonly baseUrl = `${environment.api.account}/v1/notification`;

  constructor(private readonly http: HttpClient) {}

  post(notification: any): any {
    return this.http.post(this.baseUrl, notification).subscribe();
  }
}
