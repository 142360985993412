import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@commons/footer/footer/footer.component';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { SvgIconModule } from '@wizbii/angular-ui';

@NgModule({
  imports: [CommonModule, RouterModule, SvgIconModule, TrackerModule],
  declarations: [FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [FooterComponent],
})
export class FooterModule {}
