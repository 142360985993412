import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

type ScriptCrossOrigin = 'anonymous' | 'use-credentials';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {}

  load(src: string, crossOrigin?: ScriptCrossOrigin): Observable<void> {
    if (isPlatformServer(this.platformId)) {
      return EMPTY; // don't attempt to load script during SSR
    }

    return new Observable<void>((obs) => {
      const scriptElem = this.document.createElement('script');
      scriptElem.src = src;
      scriptElem.async = true;

      if (crossOrigin) {
        scriptElem.crossOrigin = crossOrigin;
      }

      scriptElem.onerror = obs.error.bind(obs);
      scriptElem.onload = () => {
        obs.next();
        obs.complete();
      };

      this.document.head.appendChild(scriptElem);
    });
  }
}
