import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DotsLoaderModule } from '@commons/dots-loader/dots-loader.module';
import { FileModule } from '@commons/file/file.module';
import { WizFileComponent } from '@domains/wiz-file/wiz-file.component';

@NgModule({
  imports: [CommonModule, FormsModule, FileModule, DotsLoaderModule],
  declarations: [WizFileComponent],
  exports: [WizFileComponent],
})
export class WizFileModule {}
