import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Select } from '@ngxs/store';
import { SearchRoutingEnum } from '@routing/search/search-routing.enum';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  homeUrl: string;

  @Select(SessionState.isLogged) isLogged$: Observable<boolean>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly routerExtasService: RouterExtensionService,
    @Optional() @Inject(RESPONSE) private readonly response: any
  ) {}

  ngOnInit(): void {
    this.isLogged$.pipe(take(1)).subscribe((isLogged) => {
      this.homeUrl = isLogged ? buildPath(FeaturesRoutingEnum.Search, SearchRoutingEnum.Jobs) : '/';
    });

    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = 'Not Found';
    } else if (this.route.snapshot.data.unhandled404 && this.routerExtasService.previousUrl) {
      throw new Error('Unhandled 404');
    }
  }
}
