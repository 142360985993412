import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JobPagination } from '@models/company/job-pagination';
import { SearchStat } from '@models/search-stats/search-stat';
import { JobFilter } from '@models/search/job-filter';
import { JobSearchContextEnum } from '@models/search/job-search-context.enum';
import { Action, Actions, createSelector, ofActionDispatched, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { JobSearchLoad, JobSearchLoadMore } from '@store/search/job-search/job-search.actions';
import { JobSearchWebservice } from '@webservices/search-api/job-search.webservice';
import { SearchStatsWebservice } from '@webservices/search-stats-api/search-stats.webservice';
import { WINDOW } from '@wizbii/angular-utilities';
import { of } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export type JobSearchStateModel = Record<
  JobSearchContextEnum,
  { filter?: JobFilter; loading?: boolean; result?: JobPagination }
>;

@State<JobSearchStateModel>({
  name: 'jobSearch',
  defaults: {
    [JobSearchContextEnum.Default]: {},
    [JobSearchContextEnum.Application]: {},
    [JobSearchContextEnum.Bookmark]: {},
    [JobSearchContextEnum.Company]: {},
    [JobSearchContextEnum.Partner]: {},
  },
})
@Injectable()
export class JobSearchState {
  static filter<Context extends JobSearchContextEnum>(context: Context) {
    return createSelector([JobSearchState], ({ [context]: state }: JobSearchStateModel) => state.filter);
  }

  static loading<Context extends JobSearchContextEnum>(context: Context) {
    return createSelector([JobSearchState], ({ [context]: state }: JobSearchStateModel) => state.loading);
  }

  static result<Context extends JobSearchContextEnum, Property extends keyof JobPagination>(
    context: Context,
    property: Property
  ) {
    return createSelector([JobSearchState], ({ [context]: state }: JobSearchStateModel) =>
      state.result ? state.result[property] : undefined
    );
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
    private readonly actions$: Actions,
    private readonly jobSearchWebservice: JobSearchWebservice,
    private readonly searchStatsWebservice: SearchStatsWebservice,
    @Inject(WINDOW) private readonly window: any
  ) {}

  @Action(JobSearchLoad)
  doLoad(context: StateContext<JobSearchStateModel>, action: JobSearchLoad): any {
    const state = context.getState()[action.context];
    const cancelled$ = this.actions$.pipe(
      ofActionDispatched(JobSearchLoad),
      filter((listenedAction: JobSearchLoad) => listenedAction.context === action.context)
    );
    let requestTime: number;

    context.setState(
      patch<JobSearchStateModel>({
        [action.context]: patch({
          filter: action.filter,
          loading: true,
          result: state ? new JobPagination({ ...state.result, hits: [] }) : undefined,
        }),
      })
    );

    return of(undefined).pipe(
      delay(isPlatformBrowser(this.platformId) ? 400 : 0),
      tap(() => {
        if (isPlatformBrowser(this.platformId)) {
          requestTime = this.window.performance.now();
        }
      }),
      switchMap(() => this.jobSearchWebservice.getBy(action.filter)),
      tap((response) => {
        if (isPlatformBrowser(this.platformId)) {
          this.searchStatsWebservice.addStat(
            'jobs',
            new SearchStat<JobFilter>({
              nbResults: response.nbHits,
              httpResponseTime: Math.round(this.window.performance.now() - requestTime),
              searchEngineResponseTime: response.processingTimeMS,
              query: action.filter.q,
              filters: {
                cities: action.filter.cities.length > 0 ? action.filter.cities : undefined,
                contracts: action.filter.contracts.length > 0 ? action.filter.contracts : undefined,
                domains: action.filter.domains.length > 0 ? action.filter.domains : undefined,
                distance: action.filter.distance,
              },
            })
          );
        }
      }),
      map((response) => ({ [action.context]: { filter: action.filter, loading: false, result: response } })),
      map((subState) => context.patchState(subState)),
      takeUntil(cancelled$)
    );
  }

  @Action(JobSearchLoadMore)
  doLoadMore(context: StateContext<JobSearchStateModel>, action: JobSearchLoadMore): any {
    const state = context.getState()[action.context];
    const cancelled$ = this.actions$.pipe(
      ofActionDispatched(JobSearchLoad, JobSearchLoadMore),
      filter((listenedAction: JobSearchLoad | JobSearchLoadMore) => listenedAction.context === action.context)
    );

    context.setState(
      patch<JobSearchStateModel>({
        [action.context]: patch({ loading: true }),
      })
    );

    return this.jobSearchWebservice.getBy(new JobFilter({ ...state.filter, page: state.result.page + 1 })).pipe(
      map((response) => new JobPagination({ ...response, hits: [...state.result.hits, ...response.hits] })),
      map((response) => ({ [action.context]: { filter: state.filter, loading: false, result: response } })),
      map((subState) => context.patchState(subState)),
      takeUntil(cancelled$)
    );
  }
}
