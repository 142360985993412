<wiz-footer
  [attr.appid]="appId"
  [attr.contactemailkey]="contactEmailKey"
  [attr.apidomain]="apiDomain"
  [attr.localeshandled]="allLocales"
  [attr.currentlocale]="localeSelected"
  [attr.hascgv]="true"
  [attr.haspromotions]="false"
  [attr.isbrowser]="isFromBrowser"
  (updateLocale)="updatePreferredLocale($event['detail'])"
>
  <a [href]="searchUrl">
    <svg-icon
      wzbFooterLogo
      class="list__title--logo"
      icon="wizbii"
      label="Page d’accueil WIZBII"
      i18n-label
      width="8.25rem"
      height="1.625em"
    ></svg-icon>
  </a>
  <span wzbFooterSlogan i18n class="list__items brand-tagline"> Faire de chaque avenir une réussite.</span>

  <span slot="wzbFooterListInside" class="usefull-links">
    <li>
      <a
        [href]="jobsUrl"
        target="_blank"
        rel="noopener"
        i18n
        appEventTracker
        [eventCategory]="trackingData.eventCategory"
        eventAction="Click Annuaire Emploi"
        [eventLabel]="'Jobs LP'"
        >Annuaire emplois</a
      >
    </li>

    <li>
      <a
        [href]="companiesUrl"
        target="_blank"
        rel="noopener"
        i18n
        appEventTracker
        [eventCategory]="trackingData.eventCategory"
        eventAction="Click  Annuaire Entreprises"
        [eventLabel]="'Company LP'"
        >Annuaire entreprises</a
      >
    </li>

    <li>
      <a
        *ngIf="isLocaleFrance"
        [href]="eventUrl"
        target="_blank"
        rel="noopener"
        i18n
        appEventTracker
        [eventCategory]="trackingData.eventCategory"
        eventAction="Click Events"
        [eventLabel]="'Events'"
        >Événements</a
      >
    </li>
  </span>
</wiz-footer>
