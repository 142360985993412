import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Job } from '@models/job/job';

@Component({
  selector: 'job-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent {
  @Input() model: Job;

  @Input() leftAlign = false;

  @Input() addStructuredData = false;
}
