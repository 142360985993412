import { Degree } from '@models/profile/degree';
import { School } from '@models/profile/school';
import { DomainEnum } from '@models/search/domain.enum';

export class Education {
  id: string;
  name: string;
  startYear: string;
  endYear: string;
  ongoing: boolean;
  domains: DomainEnum[];
  description: string;
  furtherStudy: boolean;
  degree: Degree;
  school: School;

  constructor(data = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.startYear = data['startYear'];
    this.endYear = data['endYear'];
    this.ongoing = data['ongoing'];
    this.domains = data['domains'] || [];
    this.description = data['description'];
    this.furtherStudy = data['furtherStudy'] || false;
    this.degree = data['degree'] ? new Degree(data['degree']) : undefined;
    this.school = data['school'] ? new School(data['school']) : undefined;
  }
}
