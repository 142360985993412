<div class="error">
  <a class="error__logo" [routerLink]="[homeUrl]">
    <app-svg-icon
      class="logo"
      icon="wizbii"
      label="Page d’accueil WIZBII"
      i18n-label
      width="5.75em"
      height="1.25em"
    ></app-svg-icon>
  </a>

  <div class="error__message">
    <span class="error__message__code"> 404 </span>

    <span class="error__message__description" i18n> page non trouvée </span>

    <a class="error__message__btn btn" [routerLink]="[homeUrl]">
      <app-svg-icon class="icon" icon="exp-left" width="1em" height="0.7em"></app-svg-icon>

      <ng-container i18n>Accueil</ng-container>
    </a>
  </div>
</div>
