import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { QueryParamsStoreService } from '@store/session/query-params-store.service';
import { SessionState } from '@store/session/session.state';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class SessionedGuard implements CanActivate {
  constructor(
    private readonly queryParamsStoreService: QueryParamsStoreService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly errorHandlerService: BugsnagService
  ) {}

  canActivate(_: any, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => this.store.selectOnce(SessionState.tokens)),
      map((tokens) => !!tokens),
      map((isLogged) => {
        if (!isLogged) {
          this.errorHandlerService.sendError('Unauthorized route', 'warning');

          this.queryParamsStoreService.hidedQueryParams = {
            ...this.queryParamsStoreService.hidedQueryParams,
            from: this.queryParamsStoreService.hidedQueryParams['from'] || state.url,
          };

          this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
        }

        return isLogged;
      })
    );
  }
}
