export enum NewDomain {
  // Market / Comm
  MARKETING = 'marketing',
  COMMUNICATION = 'communication',
  EVENEMENTIEL = 'evenementiel',
  JOURNALISME = 'journalisme-rp',
  COMMUNITY_MANAGEMENT = 'community-management',

  // Business / Management
  VENTES = 'ventes',
  RELATION_CLIENT_SUPPORT = 'relation-client-support',
  ACHATS = 'achats',
  CONSEIL = 'conseil',
  DIRECTION_ENTREPRISE = 'direction-d-entreprise',

  // Créa / Design
  DESIGN_UX_UI = 'design-ux-ui',
  GRAPHISME_ILLUSTRATION = 'graphisme-illustration',
  PRODUCTION_AUDIOVISUELLE = 'production-audiovisuelle',

  // RH / Admin / Juridique
  ADMINISTRATION = 'administration',
  RH_FORMATION = 'rh-formation',
  FISCALITE = 'fiscalite',
  JURIDIQUE = 'juridique',

  // Gestion / Finance
  COMPTABILITE_CONTROLE_DE_GESTION = 'comptabilite-controle-de-gestion',
  AUDIT = 'audit',

  // Logistique / Production
  LOGISTIQUE = 'logistique',
  PRODUCTION_FABRICATION_EXPLOITATION = 'production-fabrication-exploitation',
  TRAVAUX_CHANTIER = 'travaux-chantiers',
  QUALITE_MAINTENANCE = 'qualite-maintenance',

  // Tech
  DEVELOPPEMENT_INFORMATIQUE = 'developpement-informatique',
  GESTION_PROJET = 'gestion-de-projet-produit',
  INFRA_RESEAUX_TELECOM = 'infra-reseaux-telecoms',

  // Science / R&D / Ingénierie
  ETUDES_STATISTIQUES_DATA = 'etudes-statistiques-data',
  ENERGIE_MATERIAUX_MECANIQUE = 'energie-materiaux-mecanique',
  ARCHITECTURE_URBANISME = 'architecture-urbanisme',
  CONCEPTION_GENIE_CIVIL_GENIE_INDUSTRIEL = 'conception-genie-civil-genie-industriel',
  CHIMIE_BIOLOGIE_AGRONOMIE = 'chimie-biologie-agronomie',
  ENVIRONNEMENT = 'environnement',

  // Santé / Social / Culture
  ACTIVITES_SOCIALES_ET_CULTURELLES = 'activites-sociales-et-culturelles',
  SERVICES_A_LA_PERSONNE = 'services-a-la-personne',
  SANTE = 'sante',
  ENSEIGNEMENT = 'enseignement',

  // Hôtellerie - Restauration
  HOTELLERIE_RESTAURATION = 'hotellerie-restauration',

  SOURCE_PREDICTED = 'predicted',
  SOURCE_LEGACY = 'legacy',
}

export enum DomainMappingEnum {
  'achat' = NewDomain.ACHATS,
  'aeronautique-mecanique' = NewDomain.ENERGIE_MATERIAUX_MECANIQUE,
  'agroalimentaire' = NewDomain.CHIMIE_BIOLOGIE_AGRONOMIE,
  'architecture' = NewDomain.ARCHITECTURE_URBANISME,
  'art-culture-mode' = NewDomain.ACTIVITES_SOCIALES_ET_CULTURELLES,
  'audit-comptabilite' = NewDomain.COMPTABILITE_CONTROLE_DE_GESTION,
  'automatisme' = NewDomain.ENERGIE_MATERIAUX_MECANIQUE,
  'automobile' = NewDomain.ENERGIE_MATERIAUX_MECANIQUE,
  'banque-assurance' = NewDomain.VENTES,
  'batiment-travaux-publics' = NewDomain.TRAVAUX_CHANTIER,
  'chimie-biologie' = NewDomain.CHIMIE_BIOLOGIE_AGRONOMIE,
  'commerce-vente' = NewDomain.VENTES,
  'communication-community-management-publicite' = NewDomain.COMMUNITY_MANAGEMENT,
  'defense-fonction-publique' = NewDomain.SERVICES_A_LA_PERSONNE,
  'design' = NewDomain.DESIGN_UX_UI,
  'developpement-informatique' = NewDomain.DEVELOPPEMENT_INFORMATIQUE,
  'droit-notariat-fiscalite' = NewDomain.JURIDIQUE,
  'economie' = NewDomain.CONSEIL,
  'electronique-electrotechnique' = NewDomain.ENERGIE_MATERIAUX_MECANIQUE,
  'entrepreneuriat' = NewDomain.DIRECTION_ENTREPRISE,
  'environnement-ecologie-agriculture' = NewDomain.ENVIRONNEMENT,
  'evenementiel' = NewDomain.EVENEMENTIEL,
  'finance-controle-de-gestion' = NewDomain.COMPTABILITE_CONTROLE_DE_GESTION,
  'genie-civil' = NewDomain.CONCEPTION_GENIE_CIVIL_GENIE_INDUSTRIEL,
  'geologie-geochimie-geophysique' = NewDomain.CHIMIE_BIOLOGIE_AGRONOMIE,
  'graphisme-multimedia' = NewDomain.PRODUCTION_AUDIOVISUELLE,
  'hotellerie-restauration-tourisme' = NewDomain.HOTELLERIE_RESTAURATION,
  'immobilier' = NewDomain.VENTES,
  'industrie' = NewDomain.CONCEPTION_GENIE_CIVIL_GENIE_INDUSTRIEL,
  'ingenierie-gestion-de-production' = NewDomain.CONCEPTION_GENIE_CIVIL_GENIE_INDUSTRIEL,
  'journalisme-rp-edition' = NewDomain.JOURNALISME,
  'langues' = NewDomain.ENSEIGNEMENT,
  'logistique' = NewDomain.LOGISTIQUE,
  'management-gestion-de-projet' = NewDomain.GESTION_PROJET,
  'marketing' = NewDomain.MARKETING,
  'mathematiques-statistiques' = NewDomain.ETUDES_STATISTIQUES_DATA,
  'medical-paramedical' = NewDomain.SERVICES_A_LA_PERSONNE,
  'physique' = NewDomain.CHIMIE_BIOLOGIE_AGRONOMIE,
  'qualite-securite-hygiene' = NewDomain.CONCEPTION_GENIE_CIVIL_GENIE_INDUSTRIEL,
  'ressources-humaines' = NewDomain.RH_FORMATION,
  'sciences-humaines' = NewDomain.RH_FORMATION,
  'sciences-politiques' = NewDomain.JOURNALISME,
  'secretariat-administration' = NewDomain.ADMINISTRATION,
  'sport-bien-etre-soin' = NewDomain.SERVICES_A_LA_PERSONNE,
  'systeme-information-urbanisation-des-si' = NewDomain.DEVELOPPEMENT_INFORMATIQUE,
  'systeme-reseaux-telecommunications' = NewDomain.INFRA_RESEAUX_TELECOM,
  'travail-social-animation-enfance' = NewDomain.SERVICES_A_LA_PERSONNE,
}
