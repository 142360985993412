import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Submission } from '@models/nps/submission';
import { Profile } from '@models/profile/profile';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { SatisfactionService } from '@webservices/satisfaction-api/satisfaction.service';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsComponent implements OnInit {
  searchUrl = buildPath(FeaturesRoutingEnum.Search);
  note: number;
  comment: string;
  updateSent: boolean;
  submission: Submission;

  @Select(ProfileState.authenticatedProfile) authenticatedProfile$: Observable<Profile>;

  constructor(private readonly satisfactionService: SatisfactionService, private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.pipe(take(1), untilDestroyed(this)).subscribe((params) => {
      this.note = params['note'];

      this.authenticatedProfile$
        .pipe(
          filter((profile) => !!profile),
          take(1)
        )
        .subscribe((profile) => {
          const content = new Submission({
            user_type: 'profile',
            user_id: profile.id,
            locale: profile.locale,
            product: 'wizbii',
            source: 'web',
            note: this.note,
            comment: '',
          });
          this.satisfactionService
            .create(content)
            .pipe(take(1))
            .subscribe((response: Submission) => {
              this.submission = response;
            });
        });
    });
  }

  sendUpdate(): void {
    this.updateSent = true;
    this.satisfactionService.update(this.submission.id, this.comment).pipe(take(1)).subscribe();
  }
}
