import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';

@Injectable()
export class LoyaltyLegacyWebservice {
  private readonly baseUrl = `${environment.api.loyaltyLegacy}/api`;

  constructor(private readonly http: HttpClient) {}

  getPartnerServicesLoginUrls(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/login/autologin`, { withCredentials: true });
  }

  requestPersonalData(): Observable<void> {
    const url = buildUrl(`${this.baseUrl}/personal-data/request-export/wizbii`);
    return this.http.post<void>(url, {});
  }

  getPartnersServicesLogoutUrls(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/logout/urls`);
  }

  /*
    Requête à la main, car HttpClient.jsonp ne permet pas de passer de
    paramètre pour faire une rêquete avec la méthode POST (uniquement GET).
    Sera amené à changer avec une nouvelle version d'account en V4
  */
  loginToPartner(url: string): Observable<any> {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.withCredentials = true;
      xhr.responseType = 'json';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status <= 300) {
            observer.next(xhr.response);
            observer.complete();
          } else {
            observer.error({
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        }
      };
      xhr.onerror = () => {
        observer.error({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send();
    });
  }

  // This functions is used in order to make HTTP requests to call URLs returned by `getPartnersServicesLogoutUrls`
  // and prevent from injecting `HttpClient` in SessionState class
  logoutPartner(url: string): Observable<void> {
    return this.http.get<void>(url);
  }
}
