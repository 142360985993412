import { City } from '@wizbii/models';

export class School {
  id: string;
  name: string;
  location: City;

  constructor(data = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.location = data['location'] ? new City(data['location']) : undefined;
  }
}
