import { LanguageEnum } from '@models/profile/language.enum';
import { ProficiencyEnum } from '@models/profile/proficiency.enum';

export class Language {
  name: LanguageEnum;
  proficiency: ProficiencyEnum;

  constructor(data = {}) {
    this.name = data['name'];
    this.proficiency = data['proficiency'];
  }
}
