import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileInputComponent } from '@commons/file/file-input/file-input.component';
import { MaxFileSizeValidator } from '@commons/file/validators/max-file-size.validator';
import { MimeTypesValidator } from '@commons/file/validators/mime-types.validator';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';

@NgModule({
  imports: [CommonModule, SvgIconModule, TrackerModule],
  declarations: [FileInputComponent, MaxFileSizeValidator, MimeTypesValidator],
  exports: [FileInputComponent, MaxFileSizeValidator, MimeTypesValidator],
})
export class FileModule {}
