import { HttpErrorResponse } from '@angular/common/http';
import { Contract } from '@models/job/contract';
import { Job } from '@models/job/job';
import { Degree } from '@models/profile/degree';

export class GetJob {
  static readonly type = '[Job] Get';
  constructor(public jobId: string, public companyId?: string) {}
}

export class GetJobSuccess {
  static readonly type = '[Job] Get Success';
  constructor(public job: Job, public altId?: string) {}
}

export class GetDegree {
  static readonly type = '[Degree] Get';
  constructor(public degreeId: string) {}
}

export class GetDegreeSuccess {
  static readonly type = '[Degree] Get Success';
  constructor(public degree: Degree) {}
}

export class GetJobs {
  static readonly type = '[Job] Get Multiple';
  constructor(public jobIds: string[]) {}
}

export class GetJobsSuccess {
  static readonly type = '[Job] Get Multiple Success';
  constructor(public jobs: Job[]) {}
}

export class GetContracts {
  static readonly type = '[Contract] Get All';
  constructor(public force = false) {}
}

export class GetContractsSuccess {
  static readonly type = '[Contract] Get All Success';
  constructor(public contracts: Contract[]) {}
}

export class HandleErrors {
  static readonly type = '[Job] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
