import { Directive, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavLinkEnum, NavLinkRegexpRecord } from '@routing/actives-url';

@Directive({
  selector: '[appNavLink]',
})
export class NavLinkDirective {
  @Input() appNavLink: NavLinkEnum;

  // Active class to apply
  @Input() appNavLinkActive: string;

  @Input() class: string;

  @HostBinding('class')
  get activeClass(): string {
    return this.isActive() ? `${this.class} ${this.appNavLinkActive}` : this.class;
  }

  constructor(private readonly router: Router) {}

  private isActive(): boolean {
    return NavLinkRegexpRecord[this.appNavLink].some((regexp) => regexp.test(this.router.url));
  }
}
