export enum JobRoutingEnum {
  Root = '',
  Apply = 'apply',

  Company = 'company',
  Job = 'job',
}

export enum ApplyRoutingEnum {
  Coordinates = 'coordinates',
  CurriculumVitae = 'curriculum-vitae',
  Message = 'message',
  Confirmation = 'confirmation',
}
