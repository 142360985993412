import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CheckmarkLoaderModule } from '@commons/checkmark-loader/checkmark-loader.module';
import { CheckboxModule } from '@commons/controls/checkbox/checkbox.module';
import { SelectModule } from '@commons/controls/select/select.module';
import { FileModule } from '@commons/file/file.module';
import { ModalModule } from '@commons/modal/modal.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { CardLightweightComponent } from '@domains/event/card-lightweight/card-lightweight.component';
import { CardComponent } from '@domains/event/card/card.component';
import { I18nEventTypeComponent } from '@domains/event/i18n-event-type/i18n-event-type.component';
import { ParticipationComponent } from '@domains/event/participation/participation.component';
import { I18nDomainModule } from '@domains/job/i18n-domain/i18n-domain.module';
import { WizFileModule } from '@domains/wiz-file/wiz-file.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LazyImgModule } from '@wizbii/angular-utilities';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';

@NgModule({
  imports: [
    CheckboxModule,
    CheckmarkLoaderModule,
    CommonModule,
    FileModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    ScrollToModule,
    SelectModule,
    SvgIconModule,
    TrackerModule,
    WizFileModule,
    HeadTitleModule,
    HeadDescriptionModule,
    LazyImgModule,
    I18nDomainModule,
    LazyImgModule,
    ModalModule,
  ],
  declarations: [CardComponent, CardLightweightComponent, I18nEventTypeComponent, ParticipationComponent],
  exports: [CardComponent, CardLightweightComponent, I18nEventTypeComponent, ParticipationComponent],
})
export class EventModule {}
