export class Tab {
  id: string;
  slug: string;
  title: string;
  innerTitle: string;
  description: string;

  constructor(data = {}) {
    this.id = data['id'];
    this.slug = data['slug'];
    this.title = data['title'];
    this.innerTitle = data['innerTitle'];
    this.description = data['description'];
  }
}
