import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class HasNpsParamsGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const hasNote = !!next.queryParams['note'];
    const hasLoginToken = !!next.queryParams['login-token'];

    if (!hasNote || !hasLoginToken) {
      this.router.navigate(['/']);
    }

    return hasNote && hasLoginToken;
  }
}
