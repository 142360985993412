import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { SessionState } from '@store/session/session.state';
import { WINDOW } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class OnboardingFinishedGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly location: Location,
    @Inject(WINDOW) private readonly window: any
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isFinished(route);
  }

  private isFinished(route: ActivatedRouteSnapshot): Observable<boolean> {
    const postOnboardingRedirectionUrl = this.location.path(true);

    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => this.store.select(ProfileState.authenticatedProfile)),
      map((profile) => {
        if (!profile) {
          return true;
        }

        if (!profile.onboardingFinished) {
          const _accountAction = route.queryParamMap.get('_accountAction');
          const accountActionQP = _accountAction ? `_accountAction=${_accountAction}` : '';
          const urlSplitted = postOnboardingRedirectionUrl.split('?');
          const url = urlSplitted[0];
          const actualQueryParams = urlSplitted[1];
          let queryParam = postOnboardingRedirectionUrl
            ? `?redirection=${url}&${accountActionQP}`
            : `?${accountActionQP}`;
          queryParam = actualQueryParams ? `${queryParam}&${actualQueryParams}` : queryParam;
          this.window.open(
            `${environment.urls.job[environment.locale]}/${FeaturesRoutingEnum.Onboarding}${queryParam}`,
            '_self'
          );
        }

        return profile.onboardingFinished;
      })
    );
  }
}
