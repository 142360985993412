import { Company } from '@models/company/company';
import { DomainEnum } from '@models/search/domain.enum';

export class ProExperience {
  id: string;
  startDate: Date;
  endDate: Date;
  inProgress: boolean;
  name: string;
  domainId: DomainEnum;
  companyId: string;
  company: Company;
  description: string;

  constructor(data = {}) {
    this.id = data['id'];
    this.startDate = data['startDate'];
    this.endDate = data['endDate'];
    this.inProgress = data['inProgress'];
    this.name = data['name'];
    this.domainId = data['domainId'];
    this.companyId = data['companyId'];
    this.company = new Company(data['company']);
    this.description = data['description'];
  }
}
