import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { LangEnum, LocaleEnum } from '@models/commons/locales.enum';
import { DomainMappingEnum, NewDomain } from '@models/domains/domain-mapping.enum';
import { SeoLink } from '@models/seo/seo-link';
import { CanonicalUrls, LinkDefinition } from '@routing/canonical-urls';
import { LinkRel, TypesEnum } from '@routing/directories/directories-routing.enum';
import { buildUrl } from '@wizbii/angular-utilities';
import { DEFAULT_LOCALE_BY_LANGUAGE } from '@wizbii/models';
import { Observable } from 'rxjs';

/* eslint-disable */
const headNotFoundError = '<head> not found within DOCUMENT.';

@Injectable()
export class SeoService {
  private readonly renderer: Renderer2;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document,
    private readonly http: HttpClient
  ) {
    this.renderer = this.rendererFactory.createRenderer(this.document, {
      id: Date.now().toString(),
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  addLinkTags(tags: LinkDefinition[]): void {
    tags.forEach((tag) => {
      this.addLinkTag(tag);
    });
  }

  addLinkTag(tag: LinkDefinition): void {
    try {
      const link = this.renderer.createElement('link');
      const head = this.document.head;

      if (head === null) {
        throw new Error(headNotFoundError);
      }

      Object.keys(tag).forEach((prop: string) => {
        return this.renderer.setAttribute(link, prop, tag[prop]);
      });

      this.renderer.appendChild(head, link);
    } catch (e) {
      console.error(`Error while adding tag ${e.message}`);
    }
  }

  removeLinkTag(tag: LinkDefinition): void {
    try {
      const head = this.document.head;

      if (head === null) {
        throw new Error(headNotFoundError);
      }

      const linkTag = this.document.querySelector(
        `link[rel='${tag.rel}'][href='${tag.href}'][hreflang='${tag.hreflang}']`
      );

      if (linkTag) {
        this.renderer.removeChild(head, linkTag);
      }
    } catch (e) {
      console.error(`Error while removing tag ${e.message}`);
    }
  }

  removeLinkWithRel(rel: LinkRel): void {
    try {
      const head = this.document.head;

      if (head === null) {
        throw new Error(headNotFoundError);
      }

      const linkTags = this.document.querySelectorAll(`link[rel='${rel}']`);

      linkTags.forEach((link) => {
        this.renderer.removeChild(head, link);
      });
    } catch (e) {
      console.error(`Error while removing tag ${e.message}`);
    }
  }

  createLinkForCanonicalURL(lang: LangEnum, type: TypesEnum, params?: Params): void {
    let href = `${environment.urls.job[DEFAULT_LOCALE_BY_LANGUAGE[lang]]}${CanonicalUrls[lang][type]}`;

    if (params) {
      href = buildUrl(href, params);
    }

    const tag = {
      rel: LinkRel.Canonical,
      href,
    };

    this.addLinkTag(tag);
  }

  createLinkForPrevURL(lang: LangEnum, type: TypesEnum, params?: Params): void {
    let href = `${environment.urls.job[DEFAULT_LOCALE_BY_LANGUAGE[lang]]}${CanonicalUrls[lang][type]}`;

    if (params) {
      href = buildUrl(href, params);
    }

    const tag = {
      rel: LinkRel.Prev,
      href,
    };

    this.addLinkTag(tag);
  }

  createLinkForNextURL(lang: LangEnum, type: TypesEnum, params?: Params): void {
    let href = `${environment.siteUrl}${CanonicalUrls[lang][type]}`;

    if (params) {
      href = buildUrl(href, params);
    }

    const tag = {
      rel: LinkRel.Next,
      href,
    };

    this.addLinkTag(tag);
  }

  getDirectoryId(type: string, locale: LocaleEnum, letter: string, page?: string): string {
    const pageId = page ? `-${page.toLowerCase()}` : '';
    return `${type}_${locale.toLowerCase()}_${letter.toLowerCase()}${pageId}`;
  }

  getJobLandingId(id: string, locale: LocaleEnum): string {
    return `${id}-${locale}`;
  }

  getBreadcrumbSegmentsWithJob(jobId: string): Observable<SeoLink[]> {
    return this.http.get<SeoLink[]>(`${environment.api.seo}/job/${jobId}/breadcrumb/${environment.locale}`);
  }

  getBreadcrumbSegmentsWithJobLandingPAge(jobLandingPageId: string): Observable<SeoLink[]> {
    return this.http.get<SeoLink[]>(
      `${environment.api.seo}/landing-page/${jobLandingPageId}-${environment.locale}/breadcrumb/${environment.locale}`
    );
  }

  getNewDomain(currentDomain: string): string {
    let city = '';
    const key = Object.keys(DomainMappingEnum).find((k) => {
      if (currentDomain.includes(k)) {
        const extractedCity = currentDomain.split(k);
        city = !!extractedCity[1] ? extractedCity[1] : '';
        return true;
      }

      return false;
    });

    return key ? `${DomainMappingEnum[key]}${city}` : null;
  }

  isNewDomain(currentDomain: string): boolean {
    return Object.values(NewDomain).some((domain) => currentDomain.includes(domain));
  }
}
