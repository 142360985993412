import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nDomainComponent } from '@domains/job/i18n-domain/i18n-domain/i18n-domain.component';

@NgModule({
  imports: [CommonModule],
  declarations: [I18nDomainComponent],
  exports: [I18nDomainComponent],
})
export class I18nDomainModule {}
