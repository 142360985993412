import { HttpErrorResponse } from '@angular/common/http';
import { Account } from '@models/account/account';
import { UserOverview } from '@store/session/session.state';
import { UserStatus } from '@webservices/account-api/account.webservice';
import { JwtTokens } from '@wizbii/jwt';

export abstract class SessionFailed {
  static readonly type;
  constructor(public error: HttpErrorResponse | Error) {}
}

export class LoginToPartners {
  static readonly type = '[LoginToPartners] Login To Partners';
}

export class Init {
  static readonly type = '[Session] Init';
  constructor(public tokens?: JwtTokens) {}
}

export class InitUser {
  static readonly type = '[Session] Init User';
  constructor(public id: string) {}
}

export class UpdateUser {
  static readonly type = '[Session] Update User';
  constructor(public user: UserOverview) {}
}

export class Signin {
  static readonly type = '[Session] Signin';
  constructor(public username: string, public password: string) {}
}

export class SigninSuccess {
  static readonly type = '[Session] Signin Success';
  constructor(public tokens: JwtTokens, public userStatus?: UserStatus) {}
}

export class SigninFailed extends SessionFailed {
  static readonly type = '[Session] Signin Failed';
}

export class Signup {
  static readonly type = '[Session] Signup';
  constructor(public account: Account) {}
}

export class SignupFailed extends SessionFailed {
  static readonly type = '[Session] Signup Failed';
}

export class RefreshTokensStore {
  static readonly type = '[Session] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Session] Logout';
}

export class LogoutPartners {
  static readonly type = '[Session] LogoutPartners';
}

export class LogoutPartnersSuccess {
  static readonly type = '[Session] LogoutPartnersSuccess';
  constructor(public logoutUrls: string[]) {}
}

export class LogoutPartnersFailed extends SessionFailed {
  static readonly type = '[Session] LogoutPartnersFailed';
}

export class RecoverPassword {
  static readonly type = '[Session] Recover Password';
  constructor(public email: string) {}
}

export class RecoverPasswordSuccess {
  static readonly type = '[Session] Recover Password Success';
}

export class RecoverPasswordFailed extends SessionFailed {
  static readonly type = '[Session] Recover Password Failed';
}

export class SetTokens {
  static readonly type = '[Session] Set Tokens';
  constructor(public tokens: JwtTokens) {}
}

export class RemoveTokens {
  static readonly type = '[Session] Remove Tokens';
}

export class SetProvider {
  static readonly type = '[Session] SetProvider';
  constructor(public provider: string) {}
}
