import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Bookmark } from '@models/profile/bookmark';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BookmarkWebservice {
  private readonly baseUrl = `${environment.api.profile}/profiles/:profileId/job-bookmarks/?:jobId`;

  constructor(private readonly http: HttpClient) {}

  getBy(profileId: string): Observable<Bookmark[]> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.get<object[]>(url).pipe(map((response) => response.map((item) => new Bookmark(item))));
  }

  create(profileId: string, bookmark: Bookmark): Observable<Bookmark> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.post<object>(url, bookmark).pipe(map((response) => new Bookmark(response)));
  }

  remove(profileId: string, bookmark: Bookmark): Observable<void> {
    const url = buildUrl(this.baseUrl, { profileId, jobId: bookmark.jobId });

    return this.http.delete<void>(url);
  }
}
