import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { CityGet, CityGetBatch } from '@store/place/city/city.actions';
import { City } from '@wizbii/models';
import { CityWebservice } from '@wizbii/webservices';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CityStateModel {
  cityMap: Map<string, City>;
}

@State<CityStateModel>({
  name: 'city',
  defaults: { cityMap: new Map() },
})
@Injectable()
export class CityState {
  static city(cityPlaceId: string): (_: CityStateModel) => City {
    return createSelector([CityState], (state: CityStateModel) => state.cityMap.get(cityPlaceId));
  }

  static cities(cityPlaceIds: string[]): (_: CityStateModel) => City[] {
    return createSelector([CityState], (state: CityStateModel) =>
      cityPlaceIds.map((cityPlaceId) => state.cityMap.get(cityPlaceId)).filter((city) => !!city)
    );
  }

  constructor(private readonly cityWebservice: CityWebservice) {}

  @Action(CityGet)
  doGet(context: StateContext<CityStateModel>, { cityPlaceId }: CityGet): any {
    if (context.getState().cityMap.has(cityPlaceId)) {
      return of(undefined);
    }

    return this.cityWebservice.get(cityPlaceId).pipe(
      map((city) => new Map(context.getState().cityMap).set(city.cityPlaceId, city)),
      map((cityMap) => context.patchState({ cityMap }))
    );
  }

  @Action(CityGetBatch)
  doGetBatch(context: StateContext<CityStateModel>, { cityPlaceIds }: CityGetBatch): any {
    return context.dispatch(cityPlaceIds.map((cityPlaceId) => new CityGet(cityPlaceId)));
  }
}
