export enum DirectoriesRoutingEnum {
  Entreprises = 'entreprise',
  Jobs = 'jobs',
  Lp = 'lp',

  Lettre = ':letter',
  LettrePage = ':letter/:page',

  DomainesFR = 'domaine',
  VillesFR = 'ville',
  ContractsFR = 'contrat',

  DomainesEN = 'field',
  VillesEN = 'city',
  ContractsEN = 'contract',

  DomainesES = 'especializacion',
  VillesES = 'localidad',
  ContractsES = 'contrato',

  DomainesIT = 'professione',
  VillesIT = 'citta',
  ContractsIT = 'contrarre',
}

export enum LinkRel {
  Canonical = 'canonical',
  Alternate = 'alternate',
  Author = 'author',
  Publisher = 'publisher',
  Next = 'next',
  Prev = 'prev',
}

export enum TypesEnum {
  domain = 'domain',
  city = 'city',
  contract = 'contract',
  company = 'company',
  job = 'job',
  jobList = 'jobList',
  profile = 'profile',
}
