import { Injectable } from '@angular/core';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Contract } from '@models/job/contract';
import { Job } from '@models/job/job';
import { Degree } from '@models/profile/degree';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  GetContracts,
  GetContractsSuccess,
  GetDegree,
  GetDegreeSuccess,
  GetJob,
  GetJobs,
  GetJobsSuccess,
  GetJobSuccess,
  HandleErrors,
} from '@store/job/jobs.actions';
import { ContractWebservice } from '@webservices/job-api/contract.webservice';
import { JobWebservice } from '@webservices/job-api/job.webservice';
import { DegreeWebservice } from '@webservices/school-api/degree.webservice';
import { buildUrl } from '@wizbii/angular-utilities';
import { catchError, switchMap } from 'rxjs/operators';

export interface JobStateModel {
  jobs: { [jobId: string]: Job };
  degrees: { [degreeId: string]: Degree };
  contracts: Record<string, Contract>;

  loading: boolean;
  error: any;
}

const defaultState: JobStateModel = {
  jobs: {},
  degrees: {},
  contracts: {},

  loading: false,
  error: null,
};

@State<JobStateModel>({
  name: 'jobs',
  defaults: defaultState,
})
@Injectable()
export class JobsState {
  static job(jobId: string) {
    return createSelector([JobsState], (state: JobStateModel) => {
      return state.jobs[jobId];
    });
  }

  static degree(degreeId: string): any {
    return createSelector([JobsState], (state: JobStateModel) => {
      return state.degrees[degreeId];
    });
  }

  static jobs(jobIds: string[]): any {
    return createSelector([JobsState], (state: JobStateModel) => {
      return (jobIds || []).map((jobId) => state.jobs[jobId]).filter((job) => !!job);
    });
  }

  static contract(contractId: string): any {
    return createSelector([JobsState], (state: JobStateModel) => {
      return state.contracts[contractId];
    });
  }

  @Selector()
  static degrees(state: JobStateModel): any {
    return state.degrees;
  }

  @Selector()
  static contracts(state: JobStateModel): any {
    return state.contracts;
  }

  @Selector()
  static loading(state: JobStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: JobStateModel): any {
    return state.error;
  }

  constructor(
    private readonly jobWebservice: JobWebservice,
    private readonly contractWebservice: ContractWebservice,
    private readonly degreeWebservice: DegreeWebservice
  ) {}

  @Action(GetJob)
  getJob(ctx: StateContext<JobStateModel>, { jobId }: GetJob): any {
    const { jobs } = ctx.getState();

    if (!jobs[jobId]) {
      ctx.patchState({ error: null, loading: true });

      return this.jobWebservice.get(jobId).pipe(
        switchMap((job) => {
          // We don't have the aliases for now so disable this rule
          // if (!!companyId && (!job || job.company.id !== companyId)) {
          //   return ctx.dispatch(
          //     new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true })
          //   );
          // }

          const altId = jobId !== job.id ? jobId : undefined;

          return ctx.dispatch(new GetJobSuccess(job, altId));
        }),
        catchError((error) => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetJobSuccess)
  getJobSuccess(ctx: StateContext<JobStateModel>, { job, altId }: GetJobSuccess): any {
    return ctx.setState(
      patch<JobStateModel>({
        jobs: patch({
          [job.id]: job,
          ...(altId ? { [altId]: job } : {}), // add a match for possible alternative jobId
        }),
        error: null,
        loading: false,
      })
    );
  }

  @Action(GetDegree)
  getDegree(ctx: StateContext<JobStateModel>, { degreeId }: GetDegree): any {
    const { degrees } = ctx.getState();

    if (!degrees[degreeId]) {
      ctx.patchState({ error: null, loading: true });

      return this.degreeWebservice.get(degreeId).pipe(
        switchMap((degree) => ctx.dispatch(new GetDegreeSuccess(degree))),
        catchError((error) => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetDegreeSuccess)
  getDegreeSuccess(ctx: StateContext<JobStateModel>, { degree }: GetDegreeSuccess): any {
    return ctx.setState(
      patch<JobStateModel>({
        degrees: patch({
          [degree.id]: degree,
        }),
        error: null,
        loading: false,
      })
    );
  }

  @Action(GetJobs)
  getJobs(ctx: StateContext<JobStateModel>, { jobIds }: GetJobs): any {
    const { jobs } = ctx.getState();
    const missingJobs = jobIds.filter((jobId) => !jobs[jobId]);

    if (missingJobs && missingJobs.length > 0) {
      ctx.patchState({ error: null, loading: true });

      return this.jobWebservice.getBatch(missingJobs).pipe(
        switchMap((newJobs) => ctx.dispatch(new GetJobsSuccess(newJobs))),
        catchError((error) => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetJobsSuccess)
  getJobsSuccess(ctx: StateContext<JobStateModel>, { jobs }: GetJobsSuccess): any {
    const newJobs: { [jobId: string]: Job } = jobs.reduce<{ [jobId: string]: Job }>((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});

    return ctx.setState(
      patch<JobStateModel>({
        jobs: patch(newJobs),
        error: null,
        loading: false,
      })
    );
  }

  @Action(GetContracts)
  getContracts(ctx: StateContext<JobStateModel>, { force }: GetContracts): any {
    const { contracts } = ctx.getState();

    if (Object.keys(contracts).length === 0 || force) {
      return this.contractWebservice
        .get()
        .pipe(switchMap((newContracts) => ctx.dispatch(new GetContractsSuccess(newContracts))));
    }
  }

  @Action(GetContractsSuccess)
  getContractsSuccess(ctx: StateContext<JobStateModel>, { contracts }: GetContractsSuccess): any {
    let contractsToSet: Record<string, Contract> = {};
    contracts.forEach((contract) => {
      contractsToSet = {
        ...contractsToSet,
        [contract.id]: contract,
      };
    });

    return ctx.setState(
      patch<JobStateModel>({
        contracts: contractsToSet,
      })
    );
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<JobStateModel>, { error }: HandleErrors): any {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (error.status) {
      // The Job doesn't exist so => 404
      case 404: {
        const segments = error.message.split('?')[0].split('#')[0].split('/');
        const jobId = segments[segments.length - 1];
        // eslint-disable-next-line no-console
        console.error(`Job '${jobId}' not found`);

        ctx.patchState({ error, loading: false });
        return ctx.dispatch(
          new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true })
        );
      }

      default: {
        // eslint-disable-next-line no-console
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error, loading: false });
      }
    }
    // eslint-enable
  }
}
