import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { School } from '@models/profile/school';
import { AlgoliaClientService } from '@wizbii/algolia';
import { SearchIndex } from 'algoliasearch';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Filter {
  keywords: string;
  page?: number;
}

@Injectable()
export class SchoolSearchWebservice {
  index: SearchIndex;

  constructor(private readonly algoliaClientService: AlgoliaClientService) {
    this.index = this.algoliaClientService.client.initIndex(environment.algolia.index.schools);
  }

  getBy(filter: Filter): Observable<School[]> {
    const { keywords, page } = filter;
    const params: { [key: string]: any } = {
      hitsPerPage: 20,
      page: page || 0,
      queryLanguages: environment.lang,
      facets: '*',
      attributesToRetrieve: '*',
    };

    return defer(async () => this.index.search(keywords, params)).pipe(
      map((response) => response.hits || []),
      map((hits: any) => {
        for (const hit of hits) {
          if (hit.city || hit.department || hit.country) {
            hit.location = {
              country: hit.country,
              city: hit.city,
              department: hit.department,
              geo: hit._geoloc ? { lon: hit._geoloc.lng, lat: hit._geoloc.lat } : undefined,
            };
          }
        }

        return hits;
      }),
      map((hits) =>
        hits.map(
          (hit: any) =>
            new School({
              id: hit.objectID,
              name: hit.name,
              location: hit.location,
            })
        )
      )
    );
  }
}
