<ul class="taglist" [class.taglist--left-align]="leftAlign">
  <li *ngIf="model.contract?.titleShort">
    <app-svg-icon
      class="taglist__icon"
      icon="exp-briefcase"
      i18n-label="presentation offre emploi|type de contrat (cdd, cdi,...)"
      label="Type de contrat et durée"
      width="1em"
      height="1em"
    ></app-svg-icon>

    <span [attr.itemprop]="addStructuredData ? 'employmentType' : null">
      {{ model.contract.titleShort }}
    </span>
  </li>

  <li *ngIf="model.duration">
    <app-svg-icon
      class="taglist__icon"
      icon="clock"
      i18n-label="presentation offre emploi|durée du contrat"
      label="Durée du contrat"
      width="1em"
      height="1em"
    ></app-svg-icon>

    <job-duration [model]="model.duration"></job-duration>
  </li>

  <li *ngIf="model.location && model.location.short">
    <job-city [addStructuredData]="addStructuredData" [location]="model.location"></job-city>
  </li>

  <!-- <li *ngIf="model.startDate">
    <app-svg-icon
      class="taglist__icon"
      icon="exp-calendar"
      i18n-label="presentation offre emploi|date de prise du poste"
      label="Date de début"
      width="1em"
      height="1em"
    ></app-svg-icon>

    <span>{{ model.startDate | date: 'longDate' }}</span>
  </li> -->

  <li *ngIf="model.degree">
    <app-svg-icon
      class="taglist__icon"
      icon="diplome"
      i18n-label="presentation offre emploi|niveau/diplôme souhaité/requis"
      label="Diplôme requis"
      width="1em"
      height="1em"
    ></app-svg-icon>

    <span [attr.itemprop]="addStructuredData ? 'experienceRequirements' : null">
      {{ model.degree }}
    </span>
  </li>

  <li *ngIf="model.domain">
    <app-svg-icon
      class="taglist__icon"
      icon="exp-price-tag"
      i18n-label="presentation offre emploi"
      label="Catégorie"
      width="1em"
      height="1em"
    ></app-svg-icon>

    <span><app-i18n-domain [model]="model.domain"></app-i18n-domain></span>
  </li>
</ul>
