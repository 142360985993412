import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioComponent } from '@commons/controls/radio/radio.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [RadioComponent],
  exports: [RadioComponent],
})
export class RadioModule {}
