import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Submission } from '@models/nps/submission';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SatisfactionService {
  private readonly baseUrl = `${environment.api.satisfaction}/nps`;

  constructor(private readonly http: HttpClient) {}

  get(id: string): Observable<Submission> {
    const url = buildUrl(this.baseUrl, { id });

    return this.http.get<object>(url).pipe(map((item) => new Submission(item)));
  }

  create(submission: Submission): Observable<Submission> {
    const url = buildUrl(this.baseUrl);

    return this.http.post<object>(url, submission).pipe(map((item) => new Submission(item)));
  }

  update(id: string, comment?: string): Observable<Submission> {
    const url = buildUrl(`${this.baseUrl}/:id`, { id });

    const content = {
      comment,
    };

    return this.http.put<object>(url, content).pipe(map((item) => new Submission(item)));
  }
}
