export enum FeaturesRoutingEnum {
  Home = '',
  Profile = 'profile',
  Events = 'events',
  Messages = 'messages/:adId',
  Wizmail = 'wizmail',
  Redirection = 'redirection/:jobId',
  Company = 'company',
  Search = 'search',
  Job = 'company/:companyId/job/:jobId',
  JobWithReferral = 'referral/:referalId/company/:companyId/job/:jobId',
  Directories = 'directory',
  Onboarding = 'welcome',
  PasswordRecovery = 'password',
  AccountRecovery = 'accountrecovery',
  Nps = 'nps',

  NotFound = '404',

  EmploymentFr = 'emploi/:id',
  EmploymentEn = 'job-offers/:id',
  EmploymentEs = 'empleo/:id',
  EmploymentIt = 'impieghi/:id',
}
