import { Injectable } from '@angular/core';
import { Publication } from '@models/company/publication';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import {
  GetNews,
  GetNewsFailed,
  GetNewsSuccess,
  LoadMore,
  LoadMoreFailed,
  LoadMoreSuccess,
} from '@store/company/news/news.actions';
import { NewsWebservice } from '@webservices/company-api/news.webservice';
import { catchError, switchMap } from 'rxjs/operators';

export interface NewsStateModel {
  publications: {
    [companyId: string]: {
      list: Publication[];
      page: number;
      hitsPerPage: number;
      nbHits: number;
      nbPages: number;
    };
  };
  loading: boolean;
  error: any;
}

const defaultState: NewsStateModel = {
  publications: {},
  loading: false,
  error: null,
};

@State<NewsStateModel>({
  name: 'companyNews',
  defaults: defaultState,
})
@Injectable()
export class NewsState {
  static publications(companyId: string): any {
    return createSelector([NewsState], (state: NewsStateModel) => {
      return state.publications && state.publications[companyId] && state.publications[companyId].list
        ? state.publications[companyId].list
        : [];
    });
  }

  static page(companyId: string): any {
    return createSelector([NewsState], (state: NewsStateModel) => {
      return state.publications && state.publications[companyId] && state.publications[companyId].page
        ? state.publications[companyId].page
        : null;
    });
  }

  static nbPages(companyId: string): any {
    return createSelector([NewsState], (state: NewsStateModel) => {
      return state.publications && state.publications[companyId] && state.publications[companyId].nbPages
        ? state.publications[companyId].nbPages
        : null;
    });
  }

  @Selector()
  static loading(state: NewsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: NewsStateModel): any {
    return state.error;
  }

  constructor(private readonly newsWebservice: NewsWebservice) {}

  @Action(GetNews)
  getNews(ctx: StateContext<NewsStateModel>, action: GetNews): any {
    const { companyId } = action;
    const state = ctx.getState();

    if (!state.publications[companyId] && !state.loading) {
      ctx.patchState({ error: null, loading: true });

      return this.newsWebservice.getByCompany(companyId).pipe(
        switchMap((companyPublications) => ctx.dispatch(new GetNewsSuccess(companyId, companyPublications))),
        catchError((error) => ctx.dispatch(new GetNewsFailed(error)))
      );
    }
    return undefined;
  }

  @Action(GetNewsSuccess)
  getNewsSuccess(ctx: StateContext<NewsStateModel>, { companyPublications, companyId }: GetNewsSuccess): any {
    return ctx.setState(
      patch<NewsStateModel>({
        publications: patch({
          [companyId]: {
            list: companyPublications.hits,
            page: companyPublications.page,
            hitsPerPage: companyPublications.hitsPerPage,
            nbHits: companyPublications.nbHits,
            nbPages: companyPublications.nbPages,
          },
        }),
        error: null,
        loading: false,
      })
    );
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<NewsStateModel>, { companyId }: LoadMore): any {
    ctx.patchState({ error: null, loading: true });

    return this.newsWebservice.getByCompany(companyId, ctx.getState().publications[companyId].page + 1).pipe(
      switchMap((publications) => ctx.dispatch(new LoadMoreSuccess(companyId, publications))),
      catchError((error) => ctx.dispatch(new LoadMoreFailed(error)))
    );
  }

  @Action(LoadMoreSuccess)
  loadMoreSuccess(ctx: StateContext<NewsStateModel>, { companyPublications, companyId }: LoadMoreSuccess): any {
    return ctx.setState(
      patch<NewsStateModel>({
        publications: patch({
          [companyId]: patch({
            list: append(companyPublications.hits),
            page: companyPublications.page,
            hitsPerPage: companyPublications.hitsPerPage,
            nbHits: companyPublications.nbHits,
            nbPages: companyPublications.nbPages,
          }),
        }),
        error: null,
        loading: false,
      })
    );
  }

  @Action([GetNewsFailed, LoadMoreFailed])
  getNewsFailed(ctx: StateContext<NewsStateModel>, action: GetNewsFailed): any {
    return ctx.patchState({ error: action.error, loading: false });
  }
}
