import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DotsLoaderComponent } from '@commons/dots-loader/dots-loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DotsLoaderComponent],
  exports: [DotsLoaderComponent],
})
export class DotsLoaderModule {}
