import { LocaleEnum } from '@models/commons/locales.enum';
import { BannerInfo } from '@models/company/banner-info';
import { CustomTab } from '@models/company/custom-tab';
import { PresentationTab } from '@models/company/presentation-tab';
import { Link } from '@models/link/link';
import { FieldEnum } from '@models/search/field.enum';
import { City } from '@wizbii/models';

export class Company {
  id: string;
  name: string;
  status: string;
  isClient: boolean;
  tagLine: string;
  logoUrl: string;
  bannerUrl: string;
  bannerInfo: BannerInfo;
  locale: LocaleEnum;
  location: City;
  employees: number;
  industry: FieldEnum;
  websiteUrl: string;
  numberOfFollowers: number;
  numberOfJobsOffline: number;
  numberOfJobsOnline: number;
  isCurrentUserFollowingIt: boolean;
  customTabs: CustomTab[];
  presentationTab: PresentationTab;
  links: Link[];
  aliases: string[];
  aliasOf: string;
  urlCustom: string;
  partnersActivated: boolean;

  constructor(data = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.status = data['status'];
    this.isClient = data['isClient'];
    this.tagLine = data['tagLine'];
    this.logoUrl = data['logoUrl'] || data['logo'];
    this.bannerUrl = data['bannerUrl'] || data['banner'];
    this.bannerInfo = data['bannerInfo'] ? new BannerInfo(data['bannerInfo']) : undefined;
    this.location = data['location'] ? new City(data['location']) : undefined;
    this.employees = data['employees'];
    this.industry = data['industry'];
    this.websiteUrl = data['websiteUrl'];
    this.numberOfFollowers = data['numberOfFollowers'];
    this.numberOfJobsOffline = data['numberOfJobsOffline'];
    this.numberOfJobsOnline = data['numberOfJobsOnline'];
    this.isCurrentUserFollowingIt = data['isCurrentUserFollowingIt'];
    this.customTabs = (data['customTabs'] || []).map((tab) => new CustomTab(tab));
    this.presentationTab = data['presentationTab'] ? new PresentationTab(data['presentationTab']) : undefined;
    this.links = (data['links'] || []).map((tab) => new Link(tab));
    this.aliases = data['aliases'] || [];
    this.locale = data['locale'];
    this.aliasOf = data['aliasOf'];
    this.urlCustom = data['urlCustom'];
    this.partnersActivated = data['partnersActivated'];
  }
}
