import { City } from '@wizbii/models';

export class JobLanding {
  id: string;
  slug: string;
  title: string;
  description: string | null;
  domain: string | null;
  city: string | null;
  contract: string | null;

  location: City;
  relatedCity: JobLanding[];
  relatedDomain: JobLanding[];
  relatedCityDomain: JobLanding[];

  cityDomain?: JobLanding[];
  cityContract?: JobLanding[];

  constructor(data = {}) {
    this.id = data['id'];
    this.slug = data['slug'];
    this.title = data['title'];
    this.description = data['description'];
    this.domain = data['domain'];
    this.city = data['city'];
    this.contract = data['contract']?.id;

    this.location = data['location'] || null;
    this.relatedCity = (data['relatedCity'] || []).map((relatedCity) => new JobLanding(relatedCity));
    this.relatedDomain = (data['relatedDomain'] || []).map((relatedDomain) => new JobLanding(relatedDomain));
    this.relatedCityDomain = (data['relatedCityDomain'] || []).map(
      (relatedCityDomain) => new JobLanding(relatedCityDomain)
    );

    this.cityDomain = (data['cityDomain'] || []).map((cityDomain) => new JobLanding(cityDomain));
    this.cityContract = (data['cityContract'] || []).map((cityContract) => new JobLanding(cityContract));
  }
}
