export class ExtraExperience {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  inProgress: boolean;
  organization: string;
  description: string;

  constructor(data = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.startDate = data['startDate'] ? new Date(data['startDate']) : undefined;
    this.endDate = data['endDate'] ? new Date(data['endDate']) : undefined;
    this.inProgress = data['inProgress'];
    this.organization = data['organization'];
    this.description = data['description'];
  }
}
