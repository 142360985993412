import { Inject, Injectable } from '@angular/core';
import { EventTrackerInterface, FacebookPixelData } from '@commons/tracker/event-tracker/event-tracker.interface';
import { environment } from '@environment';
import { WINDOW } from '@wizbii/angular-utilities';
import { trackEvent } from '@wizbii/tracking';

@Injectable()
export class EventTrackerService {
  constructor(@Inject(WINDOW) private readonly window: any) {}

  send(event: EventTrackerInterface): void {
    trackEvent(event.eventCategory, event.eventAction, event.eventLabel, event.eventValue as any);
    this.sendToAlgolia(event);
  }

  private sendToAlgolia(event: EventTrackerInterface) {
    if (!this.window.aa || !event?.algolia || !event?.algolia.queryId) {
      return;
    }

    if (event.algolia.type === 'click') {
      this.window.aa('clickedObjectIDsAfterSearch', {
        index: environment.algolia.index.jobs,
        eventName: `${event.eventCategory} - ${event.eventAction}${event.eventLabel ? ' - ' : ''}${event.eventLabel}`,
        queryID: event.algolia.queryId,
        objectIDs: [event.algolia.objectId],
        positions: [event.algolia.position],
      });
    } else if (event.algolia.type === 'conversion') {
      this.window.aa('convertedObjectIDsAfterSearch', {
        index: environment.algolia.index.jobs,
        eventName: `${event.eventCategory} - ${event.eventAction}${event.eventLabel ? ' - ' : ''}${event.eventLabel}`,
        queryID: event.algolia.queryId,
        objectIDs: [event.algolia.objectId],
      });
    }
  }

  sendCustomTrackingEventToFacebookPixel(
    facebookPixelData: Partial<FacebookPixelData>,
    applicationType = 'ApplyToJob'
  ): void {
    if (!this.window.fbq) {
      return;
    }

    this.window.fbq('trackCustom', applicationType, {
      type: 'product',
      hitType: 'event',
      ...facebookPixelData,
    });
  }
}
