import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GlobalService } from '@core/services/global.service';
import { ScriptService } from '@core/services/script.service';
import { buildPartnerWidgetUrl } from '@core/utils/utils';
import { environment } from '@environment';
import { Profile } from '@models/profile/profile';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { SetAuthenticatedProfile } from '@store/ngxs-profile/profile.actions';
import {
  Init,
  InitUser,
  LoginToPartners,
  Logout,
  LogoutPartners,
  LogoutPartnersFailed,
  LogoutPartnersSuccess,
  RecoverPassword,
  RecoverPasswordFailed,
  RecoverPasswordSuccess,
  RefreshTokensStore,
  RemoveTokens,
  SessionFailed,
  SetProvider,
  SetTokens,
  Signin,
  SigninFailed,
  SigninSuccess,
  Signup,
  SignupFailed,
  UpdateUser,
} from '@store/session/session.actions';
import { AccountWebservice, UserStatus } from '@webservices/account-api/account.webservice';
import { ConsentApiService } from '@webservices/consent-api/consent-api.service';
import { LoyaltyLegacyWebservice } from '@webservices/loyalty-legacy-api/loyalty-legacy.webservice';
import { ProfileWebservice } from '@webservices/profile-api/profile.webservice';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { WINDOW } from '@wizbii/angular-utilities';
import { deserializeJwt, JwtTokens } from '@wizbii/jwt';
import { ArticleTypes, ConnectionInfo, hideEmail, hideLastNameCharacters } from '@wizbii/models';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

export enum SessionStateEnum {
  Init = 'INIT',
  Logged = 'LOGGED',
  LoggingIn = 'LOGGING_IN',
  SigningUpIn = 'SIGNING_UP_IN',
  Recovering = 'RECOVERING',
  Recovered = 'RECOVERED',
  NotLogged = 'NOT_LOGGED',
}

export interface UserOverview {
  username: string;
  locale: string;
  enabled: boolean;
  sourceInscription: string;
  uniqUserId: string;
  externalServicesPackageId: string;
  externalAccountsTypes: string[];
}

export interface SessionStateModel {
  tokens: JwtTokens;
  loading: boolean;
  state: SessionStateEnum;
  userStatus?: UserStatus;
  error: HttpErrorResponse | Error;
  errorMessage: string;
  user: UserOverview;
  provider: string;
  profile: Profile;
}

export enum SessionErrorMessage {
  badCredential = 'BadCredentialsException',
  deletedAccount = 'DeletedUserException',
  deletedAccountSocial = 'DeletedUserException-social',
  emailAlreadyUsed = 'EmailAlreadyUsedException',
}

const defaultState: SessionStateModel = {
  tokens: null,
  loading: false,
  state: SessionStateEnum.Init,
  userStatus: null,
  error: null,
  errorMessage: null,
  user: null,
  provider: null,
  profile: null,
};

@UntilDestroy()
@State<SessionStateModel>({
  name: 'session',
  defaults: defaultState,
})
@Injectable()
export class SessionState implements NgxsOnInit {
  @Selector()
  static tokens(state: SessionStateModel): JwtTokens {
    return state.tokens;
  }

  @Selector()
  static user(state: SessionStateModel): UserOverview {
    return state.user;
  }

  @Selector()
  static state(state: SessionStateModel): SessionStateEnum {
    return state.state;
  }

  @Selector()
  static isInitialized(state: SessionStateModel): boolean {
    return state.state !== SessionStateEnum.Init;
  }

  @Selector()
  static isLogged(state: SessionStateModel): boolean {
    return state.state === SessionStateEnum.Logged;
  }

  @Selector()
  static failed(state: SessionStateModel): boolean {
    return state.state === SessionStateEnum.NotLogged;
  }

  @Selector()
  static recovered(state: SessionStateModel): boolean {
    return state.state === SessionStateEnum.Recovered;
  }

  @Selector()
  static loading(state: SessionStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: SessionStateModel): HttpErrorResponse | Error {
    return state.error;
  }

  @Selector()
  static errorMessage(state: SessionStateModel): string {
    return state.errorMessage;
  }

  @Selector()
  static provider(state: SessionStateModel): string {
    return state.provider;
  }

  @Selector()
  static profile(state: SessionStateModel): Profile {
    return state.profile;
  }
  /* eslint-disable */

  accountLogout: string;

  constructor(
    private readonly cookieService: CookieService,
    private readonly authService: AuthenticationWebservice,
    private readonly accountWebservice: AccountWebservice,
    private readonly globalService: GlobalService,
    private readonly loyaltyLegacyWebservice: LoyaltyLegacyWebservice,
    private readonly scriptService: ScriptService,
    private readonly consentApiService: ConsentApiService,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly errorHandlerService: BugsnagService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(WINDOW) private readonly window: any,
    private readonly store: Store,
    private readonly profileWebservice: ProfileWebservice
  ) {}

  ngxsOnInit() {
    this.accountLogout = `${environment.urls.accountLogout}${this.window.location.origin}`;
  }

  @Action(Init)
  init(ctx: StateContext<SessionStateModel>, { tokens }: Init) {
    const realTokens = tokens ? tokens : this.readTokens();

    if (!!realTokens) {
      this.globalService.init(realTokens.token, realTokens.refreshToken);
      ctx.patchState({ tokens: realTokens });

      const userId = deserializeJwt(realTokens.token)['user-id'];
      this.persistLastConnection(userId);
      ctx.dispatch([new LoginToPartners(), new InitUser(userId)]);

      this.profileWebservice.get(userId).subscribe({ next: (profile) => ctx.patchState({ profile }) });

      return ctx
        .dispatch(new SetAuthenticatedProfile(userId))
        .pipe(switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Logged }))));
    }

    return ctx.patchState({ state: SessionStateEnum.NotLogged });
  }

  @Action(Signin)
  signin(ctx: StateContext<SessionStateModel>, { username, password }: Signin): Observable<void> {
    // Prevent parallel requests
    if (this.isLoading(ctx)) {
      return of(undefined);
    }

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.LoggingIn });

    return this.authService.signIn(username, password, environment.applicationId).pipe(
      switchMap((tokens) => ctx.dispatch(new SigninSuccess(tokens))),
      catchError((error) => ctx.dispatch(new SigninFailed(error)))
    );
  }

  @Action(InitUser)
  initUser(ctx: StateContext<SessionStateModel>, { id }: InitUser) {
    return this.authService.getUserOverview(id).pipe(switchMap((user) => of(ctx.patchState({ user }))));
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<SessionStateModel>, user: UserOverview) {
    return ctx.patchState({ user });
  }

  @Action(SigninSuccess)
  signinSuccess(ctx: StateContext<SessionStateModel>, { tokens, userStatus }: SigninSuccess): Observable<void> {
    this.writeTokens(tokens);

    ctx.patchState({
      loading: false,
      state: SessionStateEnum.Logged,
      tokens,
      userStatus,
    });

    return ctx.dispatch(new Init(tokens));
  }

  @Action(LoginToPartners)
  loginToPartners(ctx: StateContext<SessionStateModel>) {
    // 1. Retrieve partner login URLs
    return this.loyaltyLegacyWebservice.getPartnerServicesLoginUrls().pipe(
      catchError((err) => {
        this.errorHandlerService.sendError(
          { errorClass: err, errorMessage: 'Unable to retrieve partners login URLs' },
          'warning'
        );
        return EMPTY;
      }),

      // 2. Map each partner login URL to a JSONP request observable
      map((urls) => {
        return urls.map((url) => {
          return this.loyaltyLegacyWebservice.loginToPartner(url).pipe(
            catchError((err) => {
              this.errorHandlerService.sendError(
                { errorClass: err, errorMessage: 'Unable to log in to partner' },
                'warning'
              );
              return of(undefined); // don't error so `forkJoin` doesn't error
            })
          );
        });
      }),

      // 3. Subscribe to all login observables in parallel
      switchMap((sources: Observable<any>[]) => forkJoin(sources)), // eslint-disable-line

      // 4. Once all login observables have completed, load partner widget
      switchMap(() => {
        const decodedToken = deserializeJwt(ctx.getState().tokens.token);
        const widgetUrl = buildPartnerWidgetUrl(decodedToken['external-services-package-id']);

        if (!widgetUrl) {
          return EMPTY;
        }

        return this.scriptService.load(widgetUrl).pipe(
          catchError((err) => {
            this.errorHandlerService.sendError(
              { errorClass: err, errorMessage: 'Unable to load partner widget' },
              'warning'
            );
            return EMPTY;
          })
        );
      })
    );
  }

  @Action(Signup)
  signup(ctx: StateContext<SessionStateModel>, { account }: Signup) {
    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.SigningUpIn });
    return this.accountWebservice.create(account).pipe(
      switchMap((tokens) =>
        combineLatest([
          this.consentApiService.sendLastVersionsConsent(ArticleTypes.CGU, true),
          this.consentApiService.sendLastVersionsConsent(ArticleTypes.CPU, true),
          this.consentApiService.sendLastVersionsConsent(ArticleTypes.PRIVACY_POLICY, true),
        ]).pipe(map(() => tokens))
      ),
      switchMap((tokens) => {
        const uuid = this.cookieService.get('uniqUserId');
        return this.consentApiService.transformToMember(uuid, tokens.token).pipe(map(() => tokens));
      }),
      switchMap((tokens) => ctx.dispatch(new SigninSuccess(tokens))),
      catchError((error) => ctx.dispatch(new SignupFailed(error)))
    );
  }

  @Action(RecoverPassword)
  recoverPassword(ctx: StateContext<SessionStateModel>, action: RecoverPassword) {
    const { email } = action;

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.Recovering });

    return this.authService.getRecoveryEmail(email).pipe(
      switchMap(() => ctx.dispatch(new RecoverPasswordSuccess())),
      catchError((error) => ctx.dispatch(new RecoverPasswordFailed(error)))
    );
  }

  @Action(RecoverPasswordSuccess)
  recoverPasswordSuccess(ctx: StateContext<SessionStateModel>) {
    return ctx.patchState({
      loading: false,
      state: SessionStateEnum.Recovered,
    });
  }

  @Action(SigninFailed)
  signinFailed(ctx: StateContext<SessionStateModel>, { error }: SigninFailed): Observable<void> {
    this.forgetTokens();

    // Retrieve best error message (note that `HTTPErrorResponse` inherits from `Error`)
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    ctx.patchState({
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
      error,
      errorMessage,
    });

    if (errorMessage === SessionErrorMessage.badCredential || errorMessage === SessionErrorMessage.deletedAccount) {
      console.warn(errorMessage);
      return of(undefined);
    }

    return throwError(error); // re-throw unhandled errors
  }

  @Action(SignupFailed)
  failed(ctx: StateContext<SessionStateModel>, action: SessionFailed) {
    const { error } = action;
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    this.forgetTokens();

    return ctx.patchState({
      error,
      errorMessage,
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
    });
  }

  @Action(RecoverPasswordFailed)
  recoverPasswordFailed(ctx: StateContext<SessionStateModel>, { error }: SessionFailed) {
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    this.forgetTokens();

    ctx.patchState({
      error,
      errorMessage,
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
    });

    return of(undefined);
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<SessionStateModel>, action: RefreshTokensStore) {
    const { tokens } = action;

    return ctx.patchState({
      tokens,
    });
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<SessionStateModel>, action: SetTokens) {
    const { tokens } = action;

    this.writeTokens(tokens);

    return ctx.patchState({
      tokens,
    });
  }

  @Action(RemoveTokens)
  removeTokens(ctx: StateContext<SessionStateModel>) {
    this.forgetTokens();

    return ctx.patchState({
      tokens: null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<SessionStateModel>) {
    return ctx.dispatch(new LogoutPartners()).pipe(
      tap(() => {
        ctx.setState(defaultState);
        this.window.open(this.accountLogout, '_self');
      })
    );
  }

  @Action(LogoutPartners)
  logoutPartners(ctx: StateContext<SessionStateModel>) {
    return this.loyaltyLegacyWebservice.getPartnersServicesLogoutUrls().pipe(
      switchMap((urls) => ctx.dispatch(new LogoutPartnersSuccess(urls))),
      catchError((error) => ctx.dispatch(new LogoutPartnersFailed(error)))
    );
  }

  @Action(LogoutPartnersSuccess)
  logoutPartnersSuccess(_, action: LogoutPartnersSuccess) {
    return forkJoin(action.logoutUrls.map((url) => this.loyaltyLegacyWebservice.logoutPartner(url)));
  }

  @Action(LogoutPartnersFailed)
  logoutPartnersFailed(_, action: LogoutPartnersFailed) {
    this.errorHandlerService.sendError(
      { errorClass: action.error as any, errorMessage: 'Logout Partners failed' },
      'warning'
    );
  }

  @Action(SetProvider)
  setProvider(ctx: StateContext<SessionStateModel>, { provider }: SetProvider): void {
    ctx.patchState({ provider });
  }

  private isLoading(ctx: StateContext<SessionStateModel>) {
    return ctx.getState().loading;
  }

  private readTokens(): JwtTokens | null {
    this.cleanBuggedCookie();

    return JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
  }

  private writeTokens(tokens: JwtTokens) {
    this.cleanBuggedCookie();

    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;

    function ensureTimestampHasMilliseconds(timestamp: string): number {
      return timestamp.length < 13 ? parseInt(timestamp, 10) * 1000 : parseInt(timestamp, 10);
    }

    const expiry = expiryExists
      ? new Date(ensureTimestampHasMilliseconds(this.cookieService.get(GlobalService.EXPIRY_KEY)))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        GlobalService.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens() {
    const cookieDomain = this.getCookieDomain();
    const forgetDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      GlobalService.EXPIRY_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private cleanBuggedCookie() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const cookieDomain = this.getCookieDomain();

    if (cookieDomain === 'localhost') {
      return;
    }

    const forgetDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    [GlobalService.TOKEN_KEY, GlobalService.EXPIRY_KEY].forEach((key) => {
      this.cookieService.set(key, '', forgetDate, '/', null, false, null);
    });
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }

  persistLastConnection(userId: string): void {
    const lastConnectionCookieName = 'last-connection';
    const lastConnectionCookieExpiryName = 'last-connection-expiry';
    const token = JSON.parse(this.cookieService.get('wizbii_tokens')).token;
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(lastConnectionCookieExpiryName);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(lastConnectionCookieExpiryName))
      : new Date(Date.now() + msIn390Days);

    combineLatest([
      this.store.selectOnce(SessionState.profile).pipe(filter((profile: Profile): profile is Profile => !!profile)),
      this.store.selectOnce(SessionState.user).pipe(filter((user): user is UserOverview => !!user)),
      this.store.selectOnce(SessionState.provider).pipe(filter((provider): provider is string => !!provider)),
    ]).subscribe({
      next: ([profile, user, provider]: [Profile, UserOverview, string]) => {
        const lastConnection: ConnectionInfo = {
          provider,
          identifier: hideEmail(user.username),
          name: hideLastNameCharacters(`${profile.firstName} ${profile.lastName}`),
          uniqUserId: this.cookieService.get('uniqUserId'),
          userId: userId ? userId : deserializeJwt(token)['user-id'],
          date: new Date(),
        };

        if (!expiryExists) {
          this.cookieService.set(
            lastConnectionCookieExpiryName,
            expiry.getTime().toString(),
            expiry,
            '/',
            cookieDomain,
            cookieDomain !== 'localhost',
            cookieDomain === 'localhost' ? 'Lax' : 'None'
          );
        }

        this.cookieService.set(
          lastConnectionCookieName,
          JSON.stringify(lastConnection),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      },
    });
  }
}
