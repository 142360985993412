import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselSlideComponent } from '@commons/controls/select/carousel-slide/carousel-slide.component';
import { CarouselComponent } from '@commons/controls/select/carousel/carousel.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';

@NgModule({
  imports: [CommonModule, SvgIconModule, TrackerModule],
  declarations: [CarouselComponent, CarouselSlideComponent],
  exports: [CarouselComponent, CarouselSlideComponent],
})
export class SelectModule {}
