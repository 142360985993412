<div class="bookmark-outer-wrapper" [class.bookmark-outer-wrapper--ghost-button]="!seamless">
  <!-- https://github.com/angular/angular/issues/21457 -->
  <button
    class="bookmark btn-clean"
    aria-live="polite"
    (click)="$event.stopPropagation(); $event.preventDefault(); toggle.emit(model)"
    [class.bookmark--ghost-button]="!seamless"
    type="button"
  >
    <span class="bookmark__inner-wrapper" [@hop]="model !== null ? !!model : undefined">
      <span class="visually-hidden" #message>
        <ng-container *ngIf="model">
          <ng-container *ngTemplateOutlet="saved; context: model"></ng-container>
        </ng-container>
        <ng-container *ngIf="!model">
          <ng-container *ngTemplateOutlet="save"></ng-container>
        </ng-container>
      </span>

      <app-svg-icon
        class="bookmark__svg"
        [class.bookmark__svg--full]="!!model"
        icon="star"
        width="1.125em"
        height="1.125em"
        [@spin]="model !== null ? !!model : undefined"
      ></app-svg-icon>
    </span>
  </button>
</div>

<ng-template #saved i18n let-createdDate="createdDate">Sauvegardé le {{ createdDate | date: 'longDate' }}</ng-template>
<ng-template #save i18n>Sauvegarder</ng-template>
