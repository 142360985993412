import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Contract } from '@models/job/contract';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContractWebservice {
  private readonly baseUrl = `${environment.api.job}/contracts`;

  constructor(private readonly http: HttpClient) {}

  get(locale?: LocaleEnum): Observable<Contract[]> {
    const url = locale ? this.baseUrl : buildUrl(this.baseUrl, { locale });
    return this.http.get<object[]>(url).pipe(map((items) => items.map((item) => new Contract(item))));
  }

  getByLocale(locale: LocaleEnum): Observable<Contract[]> {
    const url = buildUrl(this.baseUrl, { locale });
    return this.http.get<object[]>(url).pipe(map((items) => items.map((item) => new Contract(item))));
  }
}
