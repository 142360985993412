import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DataStorageService } from '@wizbii/angular-utilities';

@Injectable()
export class QueryParamsStoreService {
  static KEY_HIDED_QUERY_PARAMS = 'hided-query-params';

  constructor(private readonly dataStorageService: DataStorageService) {}

  get hidedQueryParams(): Params {
    return this.dataStorageService.get(QueryParamsStoreService.KEY_HIDED_QUERY_PARAMS) || {};
  }

  set hidedQueryParams(hidedQueryParams: Params) {
    this.dataStorageService.put(QueryParamsStoreService.KEY_HIDED_QUERY_PARAMS, hidedQueryParams);
  }
}
