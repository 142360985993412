import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'job-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent implements OnInit {
  days: number;

  @Input() date: Date;

  @Input()
  @HostBinding('class.status-filled')
  filled: boolean;

  @HostBinding('class.status-newed') newed: boolean;

  @HostBinding('class.status-expiring') expiring: boolean;

  ngOnInit(): void {
    const numberOfMillisecondsInDays = 24 * 60 * 60 * 1000;
    const now = Date.now();

    this.days = Math.floor((now - this.date.getTime()) / numberOfMillisecondsInDays);
    this.newed = !this.filled && this.days < 7;
    this.expiring = !this.filled && this.days >= 45;
  }
}
