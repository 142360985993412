import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { CvWebservice } from '@webservices/profile-api/cv.webservice';
import { WizFile } from '@wizbii/models';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class CvStoreService {
  @Select(ProfileState.authenticatedProfileId) profileId$: Observable<string>;

  private readonly loadingSubject = new ReplaySubject<boolean>(1);
  private subscription: Subscription;
  private readonly subject = new BehaviorSubject<Map<string, WizFile>>(undefined);

  constructor(private readonly cvWebservice: CvWebservice) {}

  get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  get cvs$(): Observable<Map<string, WizFile>> {
    if (!this.subject.getValue() && !this.subscription) {
      this.subscription = this.profileId$
        .pipe(
          filter((profileId) => !!profileId),
          distinctUntilChanged((itemA, itemB) => itemA === itemB),
          switchMap((profileId) => this.cvWebservice.getBy(profileId)),
          map((cvs) =>
            cvs.reduce((bucket, cv) => {
              bucket.set(cv.id, cv);
              return bucket;
            }, new Map())
          )
        )
        .subscribe((cvs) => this.subject.next(cvs));
    }

    return this.subject.asObservable().pipe(filter((response) => !!response));
  }

  get total$(): Observable<number> {
    return this.cvs$.pipe(map((cvs) => cvs.size));
  }

  add(cv: WizFile): void {
    this.profileId$
      .pipe(
        take(1),
        switchMap((profileId) => this.cvWebservice.create(profileId, cv)),
        switchMap(() => this.cvs$.pipe(take(1))),
        take(1)
      )
      .subscribe((cvs) => {
        cvs.set(cv.id, cv);
        this.subject.next(cvs);
      });
  }
}
