import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Degree } from '@models/profile/degree';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DegreeWebservice {
  private readonly baseUrl = `${environment.api.school}/degrees/?:id`;

  constructor(private readonly http: HttpClient) {}

  getBy(locale: LocaleEnum): Observable<Degree[]> {
    const url = buildUrl(this.baseUrl, { locale });
    return this.http.get<object[]>(url).pipe(map((items) => (items || []).map((item) => new Degree(item))));
  }

  get(id: string): Observable<Degree> {
    const url = buildUrl(this.baseUrl, { id });
    return this.http.get<object>(url).pipe(map((item) => new Degree(item)));
  }
}
