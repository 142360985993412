import { Injectable } from '@angular/core';
import { OpsEvent } from '@models/ops-event/ops-event';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  Failed,
  GetCustomForms,
  GetCustomFormsSuccess,
  Participate,
  ParticipateSuccess,
} from '@store/company/custom-forms/custom-forms.actions';
import { MediaWebservice } from '@webservices/media-api/media.webservice';
import { ParticipationWebservice } from '@webservices/ops-event-api/participation.webservice';
import { catchError, switchMap } from 'rxjs/operators';

export interface CustomFormsStateModel {
  forms: {
    [companyId: string]: {
      [tabId: string]: {
        event: OpsEvent;
      };
    };
  };
  loading: boolean;
  error: any;
}

const defaultState: CustomFormsStateModel = {
  forms: {},
  loading: false,
  error: null,
};

@State<CustomFormsStateModel>({
  name: 'companyCustomForms',
  defaults: defaultState,
})
@Injectable()
export class CustomFormsState {
  static event(companyId: string, tabId: string, tabSlug?: string): any {
    return createSelector([CustomFormsState], (state: CustomFormsStateModel) => {
      // Custom form might be registered under tabId or tabSlug.
      // As it is unknown beforehand, we need to check for both in the state.
      const companyForms = state.forms[companyId];
      const customForm = companyForms ? companyForms[tabId] || companyForms[tabSlug] : undefined;
      return customForm ? customForm.event : undefined;
    });
  }

  @Selector()
  static loading(state: CustomFormsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: CustomFormsStateModel): any {
    return state.error;
  }

  constructor(
    private readonly mediaWebService: MediaWebservice,
    private readonly participationWebservice: ParticipationWebservice
  ) {}

  @Action(GetCustomForms)
  getCustomForms(ctx: StateContext<CustomFormsStateModel>, action: GetCustomForms): any {
    const { companyId } = action;
    const state = ctx.getState();

    if (!state.forms[companyId]) {
      ctx.patchState({ error: null, loading: true });

      return this.mediaWebService.getCustomFormsForCompany(companyId).pipe(
        switchMap((customForms) => ctx.dispatch(new GetCustomFormsSuccess(companyId, customForms))),
        catchError((error) => ctx.dispatch(new Failed(error)))
      );
    }
    return undefined;
  }

  @Action(GetCustomFormsSuccess)
  getCustomFormsSuccess(ctx: StateContext<CustomFormsStateModel>, action: GetCustomFormsSuccess): any {
    const { result, companyId } = action;

    return ctx.patchState({
      forms: {
        ...ctx.getState().forms,
        [companyId]: result.customForms,
      },
      error: null,
      loading: false,
    });
  }

  @Action(Participate)
  participate(ctx: StateContext<CustomFormsStateModel>, action: Participate): any {
    const { participation, eventId } = action;

    ctx.patchState({ error: null, loading: true });

    return this.participationWebservice.create(eventId, participation).pipe(
      switchMap(() => ctx.dispatch(new ParticipateSuccess())),
      catchError((error) => ctx.dispatch(new Failed(error)))
    );
  }

  @Action(ParticipateSuccess)
  participateSuccess(ctx: StateContext<CustomFormsStateModel>): any {
    return ctx.patchState({ error: null, loading: false });
  }

  @Action(Failed)
  failed(ctx: StateContext<CustomFormsStateModel>, action: Failed): any {
    return ctx.patchState({ error: action.error, loading: false });
  }
}
