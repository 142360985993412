import { Company } from '@models/company/company';

export class GetCompany {
  static readonly type = '[Company] Get';
  constructor(public companyId: string, public specialProcess = false) {}
}

export class GetCompanySuccess {
  static readonly type = '[Company] Get Success';
  constructor(public company: Company, public companyId: string) {}
}

export class Follow {
  static readonly type = '[Company] Follow';
  constructor(public companyId: string) {}
}

export class FollowSuccess {
  static readonly type = '[Company] Follow Success';
  constructor(public companyId: string) {}
}

export class Unfollow {
  static readonly type = '[Company] Unfollow';
  constructor(public companyId: string) {}
}

export class UnfollowSuccess {
  static readonly type = '[Company] Unfollow Success';
  constructor(public companyId: string) {}
}

export class CreateCompany {
  static readonly type = '[Company] Create';
  constructor(public name: string) {}
}

export class CreateCompanySuccess {
  static readonly type = '[Company] Create Success';
  constructor(public company: Company) {}
}
