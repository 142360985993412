import { Contract } from '@models/job/contract';
import { JobAlertFrequencyEnum } from '@models/profile/job-alert-frequency.enum';
import { City } from '@wizbii/models';

export class JobAlert {
  id: string;
  userId: string;
  query: string;
  contracts: Contract[];
  locations: City[];
  frequency: JobAlertFrequencyEnum;
  enabled: boolean;
  dateCreated: Date;
  dateModified: Date;

  constructor(data: Partial<JobAlert> = {}) {
    this.id = data.id;
    this.userId = data.userId;
    this.query = data.query;
    this.contracts = (data.contracts || []).map((contract) => new Contract(contract));
    this.locations = (data.locations || []).map((location) => new City(location));
    this.frequency = data.frequency;
    this.enabled = data.enabled;
    this.dateCreated = data.dateCreated ? new Date(data.dateCreated) : undefined;
    this.dateModified = data.dateModified ? new Date(data.dateModified) : undefined;
  }
}
