import { LocaleEnum } from '@models/commons/locales.enum';
import { OnboardingStepsEnum } from '@models/profile/onboarding-steps.enum';

export class Account {
  firstName: string;
  lastName: string;
  appId: string;
  username: string;
  password: string;
  locale: LocaleEnum;
  dateBirthday: Date;
  remainingOnboardingSteps: OnboardingStepsEnum[];

  constructor(data = {}) {
    this.firstName = data['firstName'];
    this.lastName = data['lastName'];
    this.appId = data['appId'];
    this.username = data['username'];
    this.password = data['password'];
    this.locale = data['locale'];
    this.dateBirthday = data['dateBirthday'] ? new Date(data['dateBirthday']) : undefined;
    this.remainingOnboardingSteps = data['remainingOnboardingSteps'] || [];
  }
}
