import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { School } from '@models/profile/school';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SchoolWebservice {
  private readonly baseUrl = `${environment.api.school}/schools`;

  constructor(private readonly http: HttpClient) {}

  create(school: School): Observable<School> {
    return this.http.post<object>(this.baseUrl, school).pipe(map((item) => new School(item)));
  }

  get(id: string): Observable<School> {
    const url = buildUrl(`${this.baseUrl}/?:id`, { id });
    return this.http.get<object>(url).pipe(map((item) => new School(item)));
  }

  update(school: School): Observable<School> {
    const url = buildUrl(`${this.baseUrl}/?:id`, { id: school.id });
    return this.http.put<object>(url, school).pipe(map((item) => new School(item)));
  }
}
