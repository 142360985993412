import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserCompatibilityComponent } from '@commons/browser-compatibility/browser-compatibility.component';
import { ModalModule } from '@commons/modal/modal.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule, ModalModule],
  declarations: [BrowserCompatibilityComponent],
  exports: [BrowserCompatibilityComponent],
})
export class BrowserCompatibilityModule {}
