import { Component, Input } from '@angular/core';
import { environment } from '@environment';
import { SeoLink } from '@models/seo/seo-link';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() segments?: SeoLink[];
  @Input() isLogged: boolean;
  @Input() isMobile: boolean;

  urlHome = environment.urls.jobHome[environment.locale];

  trackByTitle(_: any, link: SeoLink): string {
    return link.title;
  }
}
