import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { JobRoutingEnum } from '@routing/job/job-routing.enum';
import { ProfileRoutingEnum } from '@routing/profile/profile-routing.enum';
import { SearchRoutingEnum } from '@routing/search/search-routing.enum';

const JobsActiveUrl: RegExp[] = [
  new RegExp(`^/${FeaturesRoutingEnum.Search}/${SearchRoutingEnum.Jobs}.*$`, 'i'),
  new RegExp(`^/${JobRoutingEnum.Company}/.+/${JobRoutingEnum.Job}/.+$`, 'i'),
];

const CompaniesActiveUrl: RegExp[] = [
  new RegExp(`^/${FeaturesRoutingEnum.Search}/${SearchRoutingEnum.Companies}.*$`, 'i'),
  new RegExp(`^/${FeaturesRoutingEnum.Company}/[^/]+$`, 'i'),
];

const EventsActiveUrl: RegExp[] = [new RegExp(`^/${FeaturesRoutingEnum.Events}.*$`, 'i')];

const ProfileBookmarksActiveUrl: RegExp[] = [
  new RegExp(`^/${FeaturesRoutingEnum.Profile}/.+/${ProfileRoutingEnum.Bookmarks}.*$`, 'i'),
];

const ProfileApplicationsActiveUrl: RegExp[] = [
  new RegExp(`^/${FeaturesRoutingEnum.Profile}/.+/${ProfileRoutingEnum.Applications}.*$`, 'i'),
];

const ProfileActiveUrl: RegExp[] = [new RegExp(`^/${FeaturesRoutingEnum.Profile}/[^/]+$`, 'i')];

export enum NavLinkEnum {
  Jobs = 'jobs',
  Events = 'events',
  Companies = 'companies',
  Bookmarks = 'bookmarks',
  Applications = 'applications',
  Profile = 'profile',
}

export const NavLinkRegexpRecord: Record<NavLinkEnum, RegExp[]> = {
  [NavLinkEnum.Jobs]: JobsActiveUrl,
  [NavLinkEnum.Events]: EventsActiveUrl,
  [NavLinkEnum.Companies]: CompaniesActiveUrl,
  [NavLinkEnum.Bookmarks]: ProfileBookmarksActiveUrl,
  [NavLinkEnum.Applications]: ProfileApplicationsActiveUrl,
  [NavLinkEnum.Profile]: ProfileActiveUrl,
};
