import { Injectable } from '@angular/core';
import { SeoService } from '@core/services/seo.service';
import { environment } from '@environment';
import { JobPagination } from '@models/company/job-pagination';
import { Contract } from '@models/job/contract';
import { Domain } from '@models/job/domain';
import { Facet } from '@models/search/facet';
import { Directory } from '@models/seo/directory';
import { JobLanding } from '@models/seo/job-landing';
import { SeoTypeEnum } from '@models/seo/seo-type.enum';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  GetCities,
  GetCitiesFailed,
  GetCitiesSuccess,
  GetCityContracts,
  GetCityContractsFailed,
  GetCityContratsSuccess,
  GetCityDomains,
  GetCityDomainsFailed,
  GetCityDomainsSucces,
  GetContractFailed,
  GetContractSuccess,
  GetDirectory,
  GetDirectoryFailed,
  GetDirectorySuccess,
  GetDomains,
  GetDomainsFailed,
  GetDomainsSuccess,
  GetInitialDirectories,
  GetInitialDirectoriesFailed,
  GetInitialDirectoriesSuccess,
  GetJobLandingFailed,
  GetJobLandingPage,
  GetJobLandingPageSuccess,
  GetJobs,
  GetJobsFailed,
  GetJobsSuccess,
  GetSeoContracts,
} from '@store/seo/seo.actions';
import { JobSearchWebservice } from '@webservices/search-api/job-search.webservice';
import { SeoWebservice } from '@webservices/seo-api/seo.webservice';
import { catchError, switchMap } from 'rxjs/operators';

interface DirectoryInterface {
  initials?: string[];
  directories?: {
    [directoryId: string]: Directory;
  };
}

export interface SeoStateModel {
  [SeoTypeEnum.job]: DirectoryInterface;
  [SeoTypeEnum.company]: DirectoryInterface;
  [SeoTypeEnum.profile]: DirectoryInterface;
  [SeoTypeEnum.city]: {
    cities?: JobLanding[];
  };
  [SeoTypeEnum.domain]: {
    domains?: JobLanding[];
    cityDomains?: JobLanding[];
  };
  [SeoTypeEnum.contract]: {
    contracts?: JobLanding[];
    cityContracts?: JobLanding[];
  };

  jobLandings: {
    [jobLandingId: string]: {
      landing: JobLanding;
      jobs?: JobPagination;
    };
  };

  loading: boolean;
  error: any;
}

const defaultState: SeoStateModel = {
  [SeoTypeEnum.job]: {},
  [SeoTypeEnum.city]: {},
  [SeoTypeEnum.company]: {},
  [SeoTypeEnum.profile]: {},
  [SeoTypeEnum.domain]: {},
  [SeoTypeEnum.contract]: {},

  jobLandings: {},

  loading: false,
  error: null,
};

@State<SeoStateModel>({
  name: 'seo',
  defaults: defaultState,
})
@Injectable()
export class SeoState {
  @Selector()
  static cities(state: SeoStateModel): any {
    return state[SeoTypeEnum.city].cities;
  }

  @Selector()
  static domainsCities(state: SeoStateModel): any {
    return (state[SeoTypeEnum.domain] && state[SeoTypeEnum.domain].domains ? state[SeoTypeEnum.domain].domains : [])
      .map((domain) => {
        return {
          ...domain,
          cityDomain: (state[SeoTypeEnum.domain].cityDomains || []).filter(
            (cityDomain) => cityDomain.domain === domain.domain
          ),
        };
      })
      .map((job) => new JobLanding(job));
  }

  @Selector()
  static contractsCities(state: SeoStateModel): any {
    return (
      state[SeoTypeEnum.contract] && state[SeoTypeEnum.contract].contracts ? state[SeoTypeEnum.contract].contracts : []
    )
      .map((contract) => {
        return {
          ...contract,
          cityContract: (state[SeoTypeEnum.contract].cityContracts || []).filter(
            (cityContract) => cityContract.contract === contract.contract
          ),
        };
      })
      .map((job) => new JobLanding(job));
  }

  static initials(type: SeoTypeEnum): any {
    return createSelector([SeoState], (state: SeoStateModel) => {
      return state[type] && state[type].initials ? state[type].initials : [];
    });
  }

  static directory(type: SeoTypeEnum, directoryId: string): any {
    return createSelector([SeoState], (state: SeoStateModel) => {
      return state[type] && state[type].directories && state[type].directories[directoryId]
        ? state[type].directories[directoryId]
        : null;
    });
  }

  static links(type: SeoTypeEnum, directoryId: string): any {
    return createSelector([SeoState], (state: SeoStateModel) => {
      return state[type] &&
        state[type].directories &&
        state[type].directories[directoryId] &&
        state[type].directories[directoryId].links
        ? state[type].directories[directoryId].links
        : [];
    });
  }

  static landing(typeId: string): any {
    return createSelector([SeoState], (state: SeoStateModel) => {
      return state.jobLandings && state.jobLandings[typeId] && state.jobLandings[typeId].landing
        ? state.jobLandings[typeId].landing
        : null;
    });
  }

  static jobs(typeId: string): any {
    return createSelector([SeoState], (state: SeoStateModel) => {
      return state.jobLandings && state.jobLandings[typeId] && state.jobLandings[typeId].jobs
        ? state.jobLandings[typeId].jobs
        : null;
    });
  }

  constructor(
    private readonly seoWebService: SeoWebservice,
    private readonly seoService: SeoService,
    private readonly jobSearchWebservice: JobSearchWebservice
  ) {}

  @Action(GetInitialDirectories)
  getInitialDirectories(ctx: StateContext<SeoStateModel>, { type, locale }: GetInitialDirectories): any {
    const state = ctx.getState();

    if (!state[type].initials && !state.loading) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getInitialDirectories(type, locale).pipe(
        switchMap((directories) => ctx.dispatch(new GetInitialDirectoriesSuccess(type, directories))),
        catchError((error) => ctx.dispatch(new GetInitialDirectoriesFailed(error)))
      );
    }
  }

  @Action(GetInitialDirectoriesSuccess)
  getInitialDirectoriesSuccess(
    ctx: StateContext<SeoStateModel>,
    { type, directories }: GetInitialDirectoriesSuccess
  ): any {
    const state = ctx.getState();

    return ctx.patchState({
      [type]: {
        ...state[type],
        initials: directories.map((dir) => dir.letter),
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetDirectory)
  getDirectory(ctx: StateContext<SeoStateModel>, { type, directoryId }: GetDirectory): any {
    const state = ctx.getState();

    if (!(state[type].directories || {})[directoryId]) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getDirectory(directoryId).pipe(
        switchMap((directory) => ctx.dispatch(new GetDirectorySuccess(type, directoryId, directory))),
        catchError((error) => ctx.dispatch(new GetDirectoryFailed(error)))
      );
    }
  }

  @Action(GetDirectorySuccess)
  getDirectorySuccess(ctx: StateContext<SeoStateModel>, { type, directoryId, directory }: GetDirectorySuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      [type]: {
        ...state[type],
        directories: {
          ...state[type].directories,
          [directoryId]: directory,
        },
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetCities)
  getJobLandingPageSummary(ctx: StateContext<SeoStateModel>, { locale }: GetCities): any {
    const state = ctx.getState();

    if (!state[SeoTypeEnum.city].cities) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getJobLandingPageSummary(SeoTypeEnum.city, locale).pipe(
        switchMap((jobLandings) => ctx.dispatch(new GetCitiesSuccess(jobLandings))),
        catchError((error) => ctx.dispatch(new GetCitiesFailed(error)))
      );
    }
  }

  @Action(GetCitiesSuccess)
  getJobLandingPageSummarySuccess(ctx: StateContext<SeoStateModel>, { jobLandings }: GetCitiesSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      [SeoTypeEnum.city]: {
        ...state[SeoTypeEnum.city],
        cities: jobLandings,
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetJobLandingPage)
  getJobLandingPage(ctx: StateContext<SeoStateModel>, { locale, id }: GetJobLandingPage): any {
    const typeId = this.seoService.getJobLandingId(id, locale);

    ctx.patchState({ error: null, loading: true });

    return this.seoWebService.getJobLandingPage(typeId).pipe(
      switchMap((jobLandings) => ctx.dispatch(new GetJobLandingPageSuccess(typeId, jobLandings))),
      catchError((error) => ctx.dispatch(new GetJobLandingFailed(error)))
    );
  }

  @Action(GetJobLandingPageSuccess)
  getJobLandingPageSuccess(ctx: StateContext<SeoStateModel>, { typeId, jobLanding }: GetJobLandingPageSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      jobLandings: {
        ...state.jobLandings,
        [typeId]: {
          ...state.jobLandings[typeId],
          landing: jobLanding,
        },
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetDomains)
  getDomains(ctx: StateContext<SeoStateModel>, { locale }: GetDomains): any {
    const state = ctx.getState();

    if (!state[SeoTypeEnum.domain].domains) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getJobLandingPageSummary(SeoTypeEnum.domain, locale).pipe(
        switchMap((jobLandings) => ctx.dispatch(new GetDomainsSuccess(jobLandings))),
        catchError((error) => ctx.dispatch(new GetDomainsFailed(error)))
      );
    }
  }

  @Action(GetDomainsSuccess)
  getDomainsSuccess(ctx: StateContext<SeoStateModel>, { jobLandings }: GetDomainsSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      [SeoTypeEnum.domain]: {
        ...state[SeoTypeEnum.domain],
        domains: jobLandings,
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetCityDomains)
  getCityDomains(ctx: StateContext<SeoStateModel>, { locale }: GetCityDomains): any {
    const state = ctx.getState();

    if (!state[SeoTypeEnum.domain].cityDomains) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getJobLandingPageSummary(SeoTypeEnum.cityDomain, locale).pipe(
        switchMap((jobLandings) => ctx.dispatch(new GetCityDomainsSucces(jobLandings))),
        catchError((error) => ctx.dispatch(new GetCityDomainsFailed(error)))
      );
    }
  }

  @Action(GetCityDomainsSucces)
  getCityDomainsSuccess(ctx: StateContext<SeoStateModel>, { jobLandings }: GetCityDomainsSucces): any {
    const state = ctx.getState();

    return ctx.patchState({
      [SeoTypeEnum.domain]: {
        ...state[SeoTypeEnum.domain],
        cityDomains: jobLandings,
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetJobs)
  getJobsByPage(ctx: StateContext<SeoStateModel>, { id, filter }: GetJobs): any {
    const state = ctx.getState();
    const typeId = this.seoService.getJobLandingId(id, environment.locale);
    const jobLanding = state.jobLandings[typeId].landing;
    filter.cities = jobLanding.city ? [jobLanding.location] : [];
    filter.domains = jobLanding.domain ? [new Facet({ model: new Domain({ id: jobLanding.domain }) })] : [];
    filter.contracts = jobLanding.contract ? [new Facet({ model: new Contract({ id: jobLanding.contract }) })] : [];

    return this.jobSearchWebservice.getBy(filter).pipe(
      switchMap((jobs) => ctx.dispatch(new GetJobsSuccess(typeId, jobs))),
      catchError((error) => ctx.dispatch(new GetJobsFailed(error)))
    );
  }

  @Action(GetJobsSuccess)
  getJobsSuccess(ctx: StateContext<SeoStateModel>, { typeId, jobs }: GetJobsSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      jobLandings: {
        ...state.jobLandings,
        [typeId]: {
          ...state.jobLandings[typeId],
          jobs,
        },
      },

      loading: false,
      error: null,
    });
  }

  @Action(GetSeoContracts)
  getContracts(ctx: StateContext<any>, { locale }: GetSeoContracts): any {
    const state = ctx.getState();

    if (!state[SeoTypeEnum.contract]?.contracts) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getJobLandingPageSummary(SeoTypeEnum.contract, locale).pipe(
        switchMap((jobLandings) => ctx.dispatch(new GetContractSuccess(jobLandings))),
        catchError((error) => ctx.dispatch(new GetContractFailed(error)))
      );
    }
  }

  @Action(GetContractSuccess)
  getContractsSuccess(ctx: StateContext<SeoStateModel>, { jobLandings }: GetContractSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      [SeoTypeEnum.contract]: {
        ...state[SeoTypeEnum.contract],
        contracts: jobLandings,
      },
      error: null,
      loading: false,
    });
  }

  @Action(GetCityContracts)
  getCityContracts(ctx: StateContext<SeoStateModel>, { locale }: GetCityContracts): any {
    const state = ctx.getState();

    if (!state[SeoTypeEnum.contract].cityContracts) {
      ctx.patchState({ error: null, loading: true });

      return this.seoWebService.getJobLandingPageSummary(SeoTypeEnum.cityContract, locale).pipe(
        switchMap((jobLandings) => ctx.dispatch(new GetCityContratsSuccess(jobLandings))),
        catchError((error) => ctx.dispatch(new GetCityContractsFailed(error)))
      );
    }
  }

  @Action(GetCityContratsSuccess)
  getCityContractsSuccess(ctx: StateContext<SeoStateModel>, { jobLandings }: GetCityContratsSuccess): any {
    const state = ctx.getState();

    return ctx.patchState({
      [SeoTypeEnum.contract]: {
        ...state[SeoTypeEnum.contract],
        cityContracts: jobLandings,
      },
      error: null,
      loading: false,
    });
  }

  /* eslint-disable  */
  @Action([
    GetInitialDirectoriesFailed,
    GetDirectoryFailed,
    GetDomainsFailed,
    GetCityDomainsFailed,
    GetJobLandingFailed,
    GetCitiesFailed,
    GetContractFailed,
    GetCityContractsFailed,
  ])
  getCompanyFailed(
    ctx: StateContext<SeoStateModel>,
    action:
      | GetInitialDirectoriesFailed
      | GetDirectoryFailed
      | GetDomainsFailed
      | GetCityDomainsFailed
      | GetContractFailed
      | GetCityContractsFailed
  ): void {
    ctx.patchState({ error: action.error, loading: false });
  }
  /* eslint-enable */
}
