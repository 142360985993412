import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomainEnum } from '@models/search/domain.enum';

@Component({
  selector: 'app-i18n-domain',
  templateUrl: './i18n-domain.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class I18nDomainComponent {
  DomainEnum = DomainEnum;

  @Input() model: DomainEnum;
}
