import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DurationEnum } from '@models/job/duration.enum';

@Component({
  selector: 'job-duration',
  templateUrl: './duration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationComponent {
  DurationEnum = DurationEnum;

  @Input() model: DurationEnum;
}
