import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Directory } from '@models/seo/directory';
import { JobLanding } from '@models/seo/job-landing';
import { SeoTypeEnum } from '@models/seo/seo-type.enum';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SeoWebservice {
  private readonly directoryUrl = `${environment.api.seo}/directory`;
  private readonly jobLandingPageUrl = `${environment.api.seo}/job-landing-page`;

  constructor(private readonly http: HttpClient) {}

  getInitialDirectories(type: SeoTypeEnum, locale: LocaleEnum): Observable<Directory[]> {
    const url = buildUrl(this.directoryUrl, { type, locale });

    return this.http.get<object[]>(url).pipe(map((data) => data.map((elem) => new Directory(elem))));
  }

  getDirectory(directoryId: string): Observable<Directory> {
    const url = buildUrl(`${this.directoryUrl}/:directoryId`, { directoryId });

    return this.http.get<object>(url).pipe(map((data) => new Directory(data)));
  }

  getJobLandingPageSummary(type: SeoTypeEnum, locale: LocaleEnum): Observable<JobLanding[]> {
    const url = buildUrl(this.jobLandingPageUrl, {
      locale,
      type,
    });

    return this.http.get<object[]>(url).pipe(map((data) => data.map((elem) => new JobLanding(elem))));
  }

  getJobLandingPage(id: string): Observable<JobLanding> {
    const url = buildUrl(`${this.jobLandingPageUrl}/${id}`);

    return this.http.get<object[]>(url).pipe(map((data) => new JobLanding(data)));
  }
}
