import { Pipe, PipeTransform } from '@angular/core';
import { NewlinePipe } from '@commons/pipe/newline/newline.pipe';
import { RemovePrePipe } from '@commons/pipe/remove-pre/remove-pre.pipe';

@Pipe({
  name: 'raw',
})
export class RawPipe implements PipeTransform {
  constructor(private readonly newline: NewlinePipe, private readonly removePre: RemovePrePipe) {}

  transform(value: string): string {
    return this.removePre.transform(this.newline.transform(value));
  }
}
