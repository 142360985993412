import { Bookmark } from '@models/profile/bookmark';

export class BookmarkCreate {
  static readonly type = '[Bookmark] Create';

  constructor(readonly bookmark: Bookmark) {}
}

export class BookmarkDelete {
  static readonly type = '[Bookmark] Delete';

  constructor(readonly bookmark: Bookmark) {}
}

export class BookmarkToggle {
  static readonly type = '[Bookmark] Toggle';

  constructor(readonly job: string) {}
}
