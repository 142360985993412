<ng-container [ngSwitch]="model">
  <!-- Market / Comm -->
  <ng-container *ngSwitchCase="DomainEnum.Marketing" i18n>Marketing</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Communication" i18n>Communication</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Evenementiel" i18n>Evénementiel</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.JournalismeRp" i18n>Journalisme / RP</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.CommunityManagement" i18n>Community Management</ng-container>

  <!-- Business / Management -->
  <ng-container *ngSwitchCase="DomainEnum.Ventes" i18n>Ventes</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.RelationClientSupport" i18n>Relation client / Support</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Achats" i18n>Achats</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Conseil" i18n>Conseil</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.DirectionEntreprise" i18n>Direction d'entreprise</ng-container>

  <!-- Créa / Design -->
  <ng-container *ngSwitchCase="DomainEnum.DesignUxUi" i18n>Design / UX / UI</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.GraphismeIllustration" i18n>Graphisme / Illustration</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.ProductionAudiovisuelle" i18n>Production Audiovisuelle</ng-container>

  <!-- RH / Admin / Juridique -->
  <ng-container *ngSwitchCase="DomainEnum.Administration" i18n>Administration</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.RhFormation" i18n>RH / Formation</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Fiscalite" i18n>Fiscalité</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Juridique" i18n>Juridique</ng-container>

  <!-- Gestion / Finance -->
  <ng-container *ngSwitchCase="DomainEnum.ComptabiliteControleDeGestion" i18n
    >Comptabilité / Contrôle de gestion</ng-container
  >
  <ng-container *ngSwitchCase="DomainEnum.Audit" i18n>Audit</ng-container>

  <!-- Logistique / Production -->
  <ng-container *ngSwitchCase="DomainEnum.Logistique" i18n>Logistique</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.ProductionFabricationExploitation" i18n
    >Production / Fabrication / Exploitation</ng-container
  >
  <ng-container *ngSwitchCase="DomainEnum.TravauxChantiers" i18n>Travaux / Chantiers</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.QualiteMaintenance" i18n>Qualité / Maintenance</ng-container>

  <!-- Tech -->
  <ng-container *ngSwitchCase="DomainEnum.DeveloppementInformatique" i18n>Développement informatique</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.GestionDeProjetProduit" i18n>Gestion de projet / Produit</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.InfraReseauxTelecoms" i18n>Infra / Réseaux / Télécoms</ng-container>

  <!-- Science / R&D / Ingénierie -->
  <ng-container *ngSwitchCase="DomainEnum.EtudesStatistiquesData" i18n>Études / Statistiques / Data</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.EnergieMateriauxMecanique" i18n>Energie / Matériaux / Mécanique</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.ArchitectureUrbanisme" i18n>Architecture / Urbanisme</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.ConceptionGenieCivilGenieIndustriel" i18n
    >Conception / Génie civil / Génie industriel</ng-container
  >
  <ng-container *ngSwitchCase="DomainEnum.ChimieBiologieAgronomie" i18n>Chimie / Biologie / Agronomie</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Environnement" i18n>Environnement</ng-container>

  <!-- Santé / Social / Culture -->
  <ng-container *ngSwitchCase="DomainEnum.ActivitesSocialesEtCulturelles" i18n
    >Activités sociales et culturelles</ng-container
  >
  <ng-container *ngSwitchCase="DomainEnum.ServicesALaPersonne" i18n>Services à la personne</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Sante" i18n>Santé</ng-container>
  <ng-container *ngSwitchCase="DomainEnum.Enseignement" i18n>Enseignement</ng-container>

  <!-- Hôtellerie - Restauration -->
  <ng-container *ngSwitchCase="DomainEnum.HotellerieRestauration" i18n>Hôtellerie - Restauration</ng-container>

  <ng-container *ngSwitchDefault>{{ model }}</ng-container>
</ng-container>
