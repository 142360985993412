import { CustomForms } from '@models/media/custom-forms';
import { Participation } from '@models/ops-event/participation';

export class GetCustomForms {
  static readonly type = '[CustomForms] Get Custom Forms';

  constructor(public companyId: string) {}
}

export class GetCustomFormsSuccess {
  static readonly type = '[CustomForms] Get Custom Forms success';
  constructor(public companyId: string, public result: CustomForms) {}
}

export class Participate {
  static readonly type = '[CustomForms] Participate';

  constructor(public eventId: string, public participation: Participation) {}
}

export class ParticipateSuccess {
  static readonly type = '[CustomForms] Participate success';
}

export class Failed {
  static readonly type = '[CustomForms] FAILED';
  constructor(public error: any) {}
}
