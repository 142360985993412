import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Contract } from '@models/job/contract';
import { Job } from '@models/job/job';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobWebservice {
  private readonly baseUrl = `${environment.api.job}/jobs/?:id`;
  private readonly contractsUrl = `${environment.api.job}/contracts`;

  constructor(private readonly http: HttpClient) {}

  get(id: string): Observable<Job> {
    const url = buildUrl(this.baseUrl, { id, view: 'true' });

    return this.http.get<object>(url).pipe(map((item) => new Job(item)));
  }

  getBatch(ids: string[]): Observable<Job[]> {
    const queryParam = ids.join(',');
    const url = buildUrl(this.baseUrl, { ids: queryParam });

    return this.http.get<object[]>(url).pipe(map((items) => items.map((item) => new Job(item))));
  }

  getContracts(locale: LocaleEnum): Observable<Contract[]> {
    const url = buildUrl(this.contractsUrl, { locale });

    return this.http.get<object[]>(url).pipe(map((items) => items.map((item) => new Contract(item))));
  }

  getSimilarJobs(jobId: string): Observable<Job[]> {
    return this.http
      .get<object[]>(`${environment.api.job}/jobs/${jobId}/similar-jobs`, {
        headers: {
          'Cache-Control': 'no-store,no-cache,must-revalidate',
          Pragma: 'no-cache',
        },
      })
      .pipe(map((items) => items.map((item) => new Job(item))));
  }
}
