import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { GlobalService } from '@core/services/global.service';
import { CustomMetaService } from '@core/services/meta-service/meta.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { environment } from '@environment';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { SessionState } from '@store/session/session.state';
import { WINDOW } from '@wizbii/angular-utilities';
import { UserOverview } from '@wizbii/models';
import { WebVitalsOptions, sendWebVitals, WebVitalsParams } from '@wizbii/utils';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  @Select(SessionState.user) user$: Observable<UserOverview>;

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  get opened(): boolean {
    return this.window.location.pathname !== '/privacy-policy' && this.window.location.pathname !== '/cgu';
  }

  constructor(
    private readonly eventTrackerService: EventTrackerService,
    private readonly store: Store,
    private readonly metaService: CustomMetaService,
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(WINDOW) private readonly window: any,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
    @Inject(ErrorHandler) errorHandler: BugsnagErrorHandler,
    _: RouterExtensionService, // initialise globally to watch all router events,
    __: GlobalService // initialise globally to initialize the tokens,
  ) {
    if (isPlatformServer(platformId)) {
      return;
    }

    // Provide user data to Bugsnag
    this.store.select(SessionState.user).subscribe((user) => {
      errorHandler.bugsnagClient.setUser(undefined, user?.username ? user.username : undefined);
    });
  }

  ngOnInit(): void {
    this.metaService.updateOrAddTag({
      property: 'fb:app_id',
      content: environment.facebookLogin.appId,
    });

    if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
      this.setLanguageInDom(environment.lang);
    }

    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParamMap
        .pipe(
          map((params) => params.get('_accountAction')),
          filter((accountAction) => !!accountAction && accountAction === 'signup'),
          take(1)
        )
        .subscribe(() => {
          if (!this.document.getElementById('neuvoo-pixel')) {
            const img = this.document.createElement('img');
            img.src = `https://www.talent.com/tracker/img-pixel.php?tracker=${environment.neuvoo}`;
            img.id = 'neuvoo-pixel';
            img.style.height = '0px';
            img.style.width = '0px';
            this.document.body.appendChild(img);
          }
        });
    }

    const routerState = this.store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.apiDomain,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.platformId),
      };
      sendWebVitals(params, options);
    }
  }

  private setLanguageInDom(language: string): void {
    this.document.documentElement.lang = language;
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  @HostListener('touchstart')
  onTouchStart(): void {
    this.hoverEnabled = false;
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }

  sendData({ detail }: any): void {
    this.eventTrackerService.send(detail);
  }
}
