import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { Job } from '@models/job/job';
import { Store } from '@ngxs/store';
import { GetJob } from '@store/job/jobs.actions';
import { JobsState } from '@store/job/jobs.state';
import { WINDOW } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnInit, AfterViewInit {
  job$: Observable<Job>;
  externalUrl$: Observable<string>;

  constructor(
    @Inject(WINDOW) private readonly window: any,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly eventTrackerService: EventTrackerService
  ) {}

  ngOnInit(): void {
    this.job$ = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('jobId')),
      filter((jobId) => !!jobId),
      distinctUntilChanged(),
      tap((jobId) => this.store.dispatch(new GetJob(jobId))),
      switchMap((jobId) => this.store.select(JobsState.job(jobId))),
      shareReplay()
    );

    this.externalUrl$ = this.job$.pipe(
      filter((job) => !!job),
      map((job) => job.externalUrl),
      map((externalUrl) => {
        const newUrl = new URL(externalUrl);
        const hasUtmSource = newUrl.searchParams.has('utm_source');

        if (!hasUtmSource) {
          newUrl.searchParams.append('utm_source', 'wizbii');
        }

        return newUrl.href;
      })
    );
  }

  ngAfterViewInit(): void {
    this.externalUrl$.pipe(delay(3000), take(1)).subscribe((externalUrl) => {
      this.eventTrackerService.send({
        eventCategory: 'Job Page',
        eventAction: 'Redirected',
        eventLabel: '',
      });

      this.window.location.assign(externalUrl);
    });
  }
}
