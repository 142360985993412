import { WishEnum } from '@models/profile/wish-enum';

export class Wish {
  description: string;
  when: WishEnum;

  constructor(data = {}) {
    this.description = data['description'];
    this.when = data['when'];
  }
}
