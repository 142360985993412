export class Application {
  jobId: string;
  profileId: string;
  email: string;
  phone: string;
  profileLocale: string;
  createdDate: Date;
  message: string;
  cv: string;

  constructor(data = {}) {
    this.jobId = data['jobId'];
    this.profileId = data['profileId'];
    this.email = data['email'];
    this.phone = data['phone'];
    this.profileLocale = data['profileLocale'];
    this.createdDate = data['createdDate'] ? new Date(data['createdDate']) : undefined;
    this.message = data['message'];
    this.cv = data['cv'];
  }
}
