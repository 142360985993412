import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { NpsComponent } from '@features/nps/nps/nps.component';

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule, FormsModule, MatInputModule, MatFormFieldModule],
  declarations: [NpsComponent],
})
export class NpsModule {}
