import { ContractsEnum } from '@commons/footer/footer/translated-links';

function getMetaDates(): string {
  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const today = new Date();

  return `${monthNames[today.getMonth()]} ${today.getFullYear()}`;
}

export interface JobsContractsMetaModel {
  title: string;
  description?: string;
  metaTitle: string;
  metaDescription: string;
}

export const JobsContractsMeta: Record<ContractsEnum, JobsContractsMetaModel> = {
  [ContractsEnum.stage]: {
    title: `Stages : Trouvez votre futur stage parmi des milliers d'offres`,
    metaTitle: `Offres de Stages - ${getMetaDates()} - WIZBII Jobs`,
    metaDescription: `Trouvez en quelques clics votre futur stage en Marketing, en Informatique, en logistique, en Achat... partout en France. Des milliers d'offres de stage séléctionnées pour les jeunes de 18-30 ans`,
  },
  [ContractsEnum.alternance]: {
    title: `Offre d'Alternance : Trouvez votre alternance parmis une sélection d'offres`,
    description: `Nous avons sélectionnés pour vous des offres d'Alternances autour de chez vous et partout en France. Une alternance est période où un étudiant alterne entre cours théorique au sein d'un établissement scolaire et mise en pratique au sein d'une entreprise. Entamez sereinement votre année d'Alternance. Nous avons trouvé des offres dans des domaines qui recrutent. Trouvez votre Contrat d'Alternance en Management, Logistique, Marketing / communication, Informatique...`,
    metaTitle: `Offres d'Alternance - ${getMetaDates()} - WIZBII Jobs`,
    metaDescription: `Votre futur alternance vous attend sur WIZBII Jobs. Trouvez votre Alternance parmi plus de 50 000 offres partout en France.`,
  },
  [ContractsEnum.jobEtudiant]: {
    title: `Jobs étudiants : des milliers d'emploi étudiants séléctionnés pour vous  `,
    description: `Trouver le juste équilibre entre vie professionnelle et vie étudiante grâce à une sélection d'offres adaptées aux étudiants. Optez pour des Jobs étudiants entre 10h et 25h par semaine. Chez WIZBII Jobs on est spécialiste des jeunes étudiants c'est pour cela que les offres proposées sont adaptées au rythme des étudiants.`,
    metaTitle: `Jobs Etudiants - ${getMetaDates()} - WIZBII Jobs`,
    metaDescription: `Trouver en quelques clics le futur emploi étudiant qui permettra de combiner votre vie étudiante et votre vie professionnelle. WIZBII Jobs est e spéciliste de l'emploi étudiant.`,
  },
  [ContractsEnum.emploiSaisonnier]: {
    metaTitle: `Jobs d'été & Saisonniers - WIZBII Jobs`,
    metaDescription: `Nous avons séléctionnés pour vous des milliers de jobs de saisonnier et des jobs d'été partout en France. Chez WIZBII Jobs nous sommes spécialisés dans l'emploi des jeunes 18-30 ans`,
    title: `Emploi saisonnier : Des offres partout en France et à l'étranger`,
    description: `Vous souhaitez travailler durant les saisons d'été ou d'hiver pour faire votre argent de poche et ainsi payer vos vacances ou vos projets? Nous avons trouvés des Jobs de saisonnier et d'été autour partout en france. Dans le domaine de l'agriculture, de la grande distribution, de la logistique, du commerce... Votre êtes à quelques clics de trouver votre emploi saisonnier. Chez WIZBII Jobs on accompagne les jeunes à trouver l'emploi le plus adapté.`,
  },
  [ContractsEnum.cdi]: {
    title: `Offres d'emploi en CDI`,
    description: `Trouvez rapidement les offres d'emploi en CDI autour de chez vous, partout en France et même à l'étranger. Nous avons séléctionné des offres en CDI pour les jeunes 18-30 ans. Offres de premier emploi ou offres pour les confirmés.`,
    metaTitle: `Offres d'emploi CDI - ${getMetaDates()} - WIZBII Jobs`,
    metaDescription: `Votre emploi en CDI en quelques clics. Découvrez les offres d'emploi en CDI à pourvoir autour de vous et partout en France.`,
  },
  [ContractsEnum.apprentissage]: {
    metaTitle: `Offres d'Apprentissage - ${getMetaDates()} - WIZBII jobs`,
    metaDescription: `Vous cherchez une entreprise pour votre Apprentisage ? Trouvez vite votre futur emploi parmis notre séléction d'offres d'apprentissage sur WIZBII jobs.`,
    title: `Offres d'Apprentissage`,
  },
};
