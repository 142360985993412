import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'job-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillsComponent {
  @Input() model: string[];

  @Input() addStructuredData = false;

  @Input() leftAlign = false;

  trackBySkill(_: any, skill: string): string {
    return skill;
  }
}
