import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Profile } from '@models/profile/profile';
import { Store } from '@ngxs/store';
import { ProfileRoutingEnum } from '@routing/profile/profile-routing.enum';
import { Logout } from '@store/session/session.actions';
import { buildPath } from '@wizbii/angular-utilities';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePopupComponent {
  @Input() profile: Profile;

  profileSegment: string = buildPath(FeaturesRoutingEnum.Profile);
  applicationsSegment: string = ProfileRoutingEnum.Applications;
  bookmarksSegment: string = ProfileRoutingEnum.Bookmarks;

  constructor(private readonly store: Store) {}

  doLogout(_: any): void {
    this.store.dispatch(new Logout());
  }
}
