export class SearchStat<T> {
  filters: Partial<T>;
  query?: string;
  nbResults: number;
  httpResponseTime: number;
  searchEngineResponseTime: number;

  constructor(data: Partial<SearchStat<T>> = {}) {
    this.filters = data.filters;
    this.query = data.query;
    this.nbResults = data.nbResults;
    this.httpResponseTime = data.httpResponseTime;
    this.searchEngineResponseTime = data.searchEngineResponseTime;
  }
}
