import { JobFilter } from '@models/search/job-filter';
import { JobSearchContextEnum } from '@models/search/job-search-context.enum';

export class JobSearchLoad {
  static readonly type = '[Job Search] Load';
  constructor(readonly context: JobSearchContextEnum, readonly filter: JobFilter) {}
}

export class JobSearchLoadMore {
  static readonly type = '[Job Search] Load More';
  constructor(readonly context: JobSearchContextEnum) {}
}
