import { LocaleEnum } from '@models/commons/locales.enum';

export class Submission {
  id: string;
  user_type: string;
  user_id: string;
  locale: LocaleEnum;
  product: string;
  source: string;
  note: number;
  comment: string;
  date_created: string;
  date_modified: string;

  constructor(data = {}) {
    this.id = data['_id'];
    this.user_type = data['user_type'];
    this.user_id = data['user_id'];
    this.locale = data['locale'];
    this.product = data['product'];
    this.source = data['source'];
    this.note = data['note'];
    this.comment = data['comment'];
    this.date_created = data['date_created'];
    this.date_modified = data['date_modified'];
  }
}
