import { Contract } from '@models/job/contract';
import { Domain } from '@models/job/domain';
import { Facet } from '@models/search/facet';
import { City } from '@wizbii/models';

export class JobFilter {
  company: string;
  partner: string;
  q: string;
  cities: City[];
  contracts: Facet<Contract>[];
  domains: Facet<Domain>[];
  ids: string[];
  page: number;
  perPage: number;
  distance: number;
  analytics: boolean;

  constructor(data = {}) {
    this.company = data['company'];
    this.partner = data['partner'];
    this.q = data['q'];
    this.cities = data['cities'] || [];
    this.contracts = data['contracts'] || [];
    this.domains = data['domains'] || [];
    this.ids = data['ids'] || [];
    this.page = data['page'] || 0;
    this.perPage = data['perPage'] || 20;
    this.distance = data['distance'] || 50;
    this.analytics = data['analytics'] || false;
  }
}
