import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { Bookmark } from '@models/profile/bookmark';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { BookmarkCreate, BookmarkDelete, BookmarkToggle } from '@store/profile/bookmark/bookmark.actions';
import { SessionState } from '@store/session/session.state';
import { BookmarkWebservice } from '@webservices/profile-api/bookmark.webservice';
import { WINDOW } from '@wizbii/angular-utilities';
import { Request } from 'express';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@State<Map<string, Bookmark>>({
  name: 'bookmark',
  defaults: undefined,
})
@Injectable()
export class BookmarkState implements NgxsOnInit {
  constructor(
    private readonly bookmarkWebservice: BookmarkWebservice,
    private readonly store: Store,
    @Inject(WINDOW) private readonly window: any,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {}

  ngxsOnInit(context: StateContext<Map<string, Bookmark>>): void {
    this.store
      .select(ProfileState.authenticatedProfileId)
      .pipe(
        switchMap((profileId) => (profileId ? this.bookmarkWebservice.getBy(profileId) : of(undefined))),
        map((bookmarks) => (bookmarks ? bookmarks.map((bookmark) => [bookmark.jobId, bookmark]) : undefined)),
        map((entries: [string, Bookmark][]) => (entries ? new Map(entries) : undefined))
      )
      .subscribe((state) => context.setState(state));
  }

  @Action(BookmarkCreate)
  doCreate(context: StateContext<Map<string, Bookmark>>, { bookmark }: BookmarkCreate): any {
    const nextState = new Map(context.getState());
    nextState.set(bookmark.jobId, bookmark);
    context.setState(nextState);

    return this.store.selectOnce(ProfileState.authenticatedProfileId).pipe(
      switchMap((profileId) => this.bookmarkWebservice.create(profileId, bookmark)),
      map((response) => context.setState(new Map(context.getState()).set(bookmark.jobId, response))),
      catchError((error) => {
        const previousState = new Map(context.getState());
        previousState.delete(bookmark.jobId);
        context.setState(previousState);
        return throwError(error);
      })
    );
  }

  @Action(BookmarkDelete)
  doDelete(context: StateContext<Map<string, Bookmark>>, { bookmark }: BookmarkDelete): Observable<void> {
    const nextState = new Map(context.getState());
    nextState.delete(bookmark.jobId);
    context.setState(nextState);

    return this.store.selectOnce(ProfileState.authenticatedProfileId).pipe(
      switchMap((profileId) => this.bookmarkWebservice.remove(profileId, bookmark)),
      catchError((error) => {
        const previousState = new Map(context.getState());
        previousState.set(bookmark.jobId, bookmark);
        context.setState(previousState);
        return throwError(error);
      })
    );
  }

  @Action(BookmarkToggle)
  doToggle(context: StateContext<Map<string, Bookmark>>, { job }: BookmarkToggle): any {
    return this.store.selectOnce(SessionState.isLogged).pipe(
      map((isLogged) => {
        if (isLogged) {
          const state = context.getState();
          return state.has(job) ? new BookmarkDelete(state.get(job)) : new BookmarkCreate(new Bookmark({ jobId: job }));
        }
        const redirect =
          this.request && isPlatformServer(this.platformId)
            ? `${this.request.protocol}://${this.request.get('host')}${this.request.url}`
            : `${this.window.location.origin}${this.window.location.pathname}`;

        const accountSignupUrlJob = `${environment.urls.accountSignup}${redirect}&context=wizbii-jobs-offer&job-id=${job}`;
        this.window.open(accountSignupUrlJob, '_self');
        return true;
      }),
      switchMap((action) => context.dispatch(action))
    );
  }
}
