import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { GetProfile } from '@store/ngxs-profile/profile.actions';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ProfileUnloggedGuard implements CanActivate {
  constructor(private readonly store: Store, private readonly router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const { profileId } = next.params;

    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1), // session initialised
      switchMap(() => this.store.dispatch(new GetProfile(profileId))), // get profile
      switchMap(() => this.store.select(ProfileState.profile(profileId))), // profile retrieved
      map((profile) => !!profile), // allow activation if profile exists
      catchError(this.handleError.bind(this))
    );
  }

  private handleError(err: HttpErrorResponse | Error) {
    // Forbid activation to unknown profile and redirect to 404
    if (err instanceof HttpErrorResponse && err.status === 404) {
      this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
      return of(false);
    }

    return throwError(err);
  }
}
