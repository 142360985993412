import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { EventTrackerInterface } from '@commons/tracker/event-tracker/event-tracker.interface';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { JobRoutingEnum } from '@features/routing/job/job-routing.enum';
import { Job } from '@models/job/job';
import { Application } from '@models/profile/application';
import { Bookmark } from '@models/profile/bookmark';

@Component({
  selector: 'job-lightweight',
  templateUrl: './lightweight.component.html',
  styleUrls: ['./lightweight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightweightComponent {
  JobRoutingEnum = JobRoutingEnum;

  @HostBinding('attr.data-interactive') interactiveHost = true;
  @Input() model: Job;
  @Input() queryParams: Params;
  @Input() queryParamsHandling: 'merge' | 'preserve' | '';
  @Input() application: Application;
  @Input() bookmark: Bookmark;
  @Input() interactive = true;
  @Input() trackingData: EventTrackerInterface;

  @Output() toggleBookmark = new EventEmitter<Bookmark>();

  constructor(private readonly router: Router, private readonly eventWebservice: EventTrackerService) {}

  @HostListener('click', ['$event.target'])
  doVisitJob(target: any): void {
    // Do not navigate if clicked target is actionable (e.g. bookmark, 'view job' button)
    if (['BUTTON', 'A'].indexOf(target.tagName) !== -1 || !this.interactive) {
      return;
    }

    this.eventWebservice.send({
      eventLabel: 'Card',
      ...this.trackingData,
    });
    this.router.navigate([JobRoutingEnum.Company, this.model.company.id, JobRoutingEnum.Job, this.model.id], {
      queryParams: this.queryParams,
      queryParamsHandling: this.queryParamsHandling,
      fragment: 'layout-main',
    });
  }
}
