import { Profile } from '@models/profile/profile';
import { ProfileSectionEnum } from '@models/profile/profile-section.enum';

export class GetProfile {
  static readonly type = '[Profile] Get';

  constructor(readonly id: string, readonly silence404 = true) {}
}

export class GetProfileSuccess {
  static readonly type = '[Profile] Get Success';

  constructor(readonly profile: Profile) {}
}

export class SetAuthenticatedProfile {
  static readonly type = '[Profile] Set authenticated profileId';

  constructor(readonly id: string) {}
}

export class SaveProfileSection {
  static readonly type = '[Profile] Save profile section';

  constructor(readonly section: ProfileSectionEnum, readonly data: Partial<Profile>) {}
}

export class SaveProfileSectionSuccess {
  static readonly type = '[Profile] Save profile section Success';

  constructor(readonly section: ProfileSectionEnum, readonly profile: Profile) {}
}

export class SaveProfileOnboarding {
  static readonly type = '[Profile] Save profile onboarding';

  constructor(readonly data: Partial<Profile>) {}
}

export class SaveProfileOnboardingSuccess {
  static readonly type = '[Profile] Save profile onboarding Success';

  constructor(readonly profile: Profile) {}
}
