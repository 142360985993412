import { LocaleEnum } from '@models/commons/locales.enum';

export class Contract {
  duration: boolean;
  id: string;
  title: string;
  titleShort: string;
  type: any;
  locale: LocaleEnum;

  constructor(data = {}) {
    this.duration = data['duration'];
    this.id = data['id'];
    this.title = data['title'];
    this.titleShort = data['titleShort'];
    this.type = data['type'];
    this.locale = data['locale'];
  }
}
