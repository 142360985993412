import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Application } from '@models/profile/application';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplicationWebservice {
  private readonly baseUrl = `${environment.api.profile}/profiles/:profileId/job-applications`;

  constructor(private readonly http: HttpClient) {}

  getBy(profileId: string): Observable<Application[]> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.get<object[]>(url).pipe(map((response) => response.map((item) => new Application(item))));
  }

  create(profileId: string, profileLocale: LocaleEnum, application: Application): Observable<Application> {
    const data = {
      ...application,
      profileId,
      profileLocale,
    };
    const url = buildUrl(`${environment.api.job}/jobs/:jobId/job-applications`, { jobId: application.jobId });

    return this.http.post<object>(url, data).pipe(map((response) => new Application(response)));
  }

  createExternal(profileId: string, profileLocale: LocaleEnum, application: Application): Observable<void> {
    const data = {
      ...application,
      profileId,
      profileLocale,
    };
    const url = buildUrl(`${environment.api.job}/jobs/:jobId/job-external-applications`, { jobId: application.jobId });

    return this.http.post<void>(url, data);
  }
}
