import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { SearchRoutingEnum } from '@routing/search/search-routing.enum';
import { SessionState } from '@store/session/session.state';
import { WINDOW } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AlreadySessionedGuard implements CanActivate {
  constructor(private readonly store: Store, @Inject(WINDOW) private readonly window: any) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => this.store.selectOnce(SessionState.tokens)),
      map((tokens) => !!tokens),
      map((isLogged) => {
        const _accountAction = route.queryParamMap.get('_accountAction');
        const accountActionQP = _accountAction ? `?_accountAction=${_accountAction}` : '';
        if (isLogged) {
          this.window.open(
            `${environment.urls.job[environment.locale]}/${FeaturesRoutingEnum.Search}/${
              SearchRoutingEnum.Jobs
            }${accountActionQP}`,
            '_self'
          );
          return false;
        }

        return !isLogged;
      })
    );
  }
}
