import { LocaleEnum } from '@models/commons/locales.enum';
import { Company } from '@models/company/company';
import { Contract } from '@models/job/contract';
import { DurationEnum } from '@models/job/duration.enum';
import { DomainEnum } from '@models/search/domain.enum';
import { City } from '@wizbii/models';

export class Job {
  company: Company;
  contract: Contract;
  location: City;
  createdDate: Date;
  startDate: Date;
  duration: DurationEnum;
  degree: string;
  domain: DomainEnum;
  mission: string;
  locale: LocaleEnum;
  online: boolean;
  profile: string;
  skills: string[];
  title: string;
  id: string;
  externalUrl: string;
  descriptionCompany: string;

  constructor(data = {}) {
    this.company = data['company'] ? new Company(data['company']) : undefined;
    this.contract = data['contract'] ? new Contract(data['contract']) : undefined;
    this.location = data['location'] ? new City(data['location']) : undefined;
    this.createdDate = data['createdDate'] ? new Date(data['createdDate']) : undefined;
    this.startDate = data['startDate'] ? new Date(data['startDate']) : undefined;
    this.duration = data['duration'];
    this.degree = data['degree'];
    this.domain = data['domain'] ? data['domain'].id : undefined;
    this.mission = data['mission'];
    this.locale = data['locale'];
    this.online = data['online'];
    this.profile = data['profile'];
    this.skills = data['skills'] || [];
    this.title = data['title'];
    this.id = data['id'];
    this.externalUrl = data['externalUrl'];
    this.descriptionCompany = data['descriptionCompany'];
  }
}
