export enum SeoTypeEnum {
  job = 'job',
  company = 'company',
  profile = 'profile',
  city = 'city',
  domain = 'domain',
  cityDomain = 'city_domain',
  contract = 'contract',
  cityContract = 'city_contract',
}
