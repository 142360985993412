import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';
import { environment } from '@environment';
import { Company } from '@models/company/company';
import { ImageService, WINDOW } from '@wizbii/angular-utilities';
import { str as stringHash } from 'adler-32';

@Component({
  selector: 'company-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  static COLORS = ['#8782ff', '#45cfff', '#3df9c6', '#e8a3f4', '#ff5c60'];

  text: string;
  imageSize: number;

  @Input() size: 'medium' | 'large' = 'medium';

  @HostBinding('attr.aria-hidden') ariaHidden = true;

  @HostBinding('style.backgroundColor') backgroundColor = '#fff';

  @HostBinding('style.backgroundImage') backgroundImage = '';

  @HostBinding('class.fallback') fallback: boolean;

  @Input()
  set company(company: Company) {
    if (company.logoUrl) {
      this.imageSize = this.computeImageSize();
      const logoUrl = this.getLogoUrl(company);

      const resizedLogoUrl = this.imageService.resize(logoUrl, { width: this.imageSize });
      this.backgroundImage = `url(${resizedLogoUrl})`;
      this.backgroundColor = '#fff';
      this.text = undefined;
    } else {
      this.backgroundImage = undefined;
      const hash = Math.abs(stringHash(company.id || company.name));
      this.backgroundColor = LogoComponent.COLORS[hash % LogoComponent.COLORS.length];
      this.text = company.name[0];
      this.fallback = true;
    }
  }

  constructor(@Inject(WINDOW) private readonly window: any, private readonly imageService: ImageService) {}

  private getLogoUrl(company: Company): string {
    return company.logoUrl.startsWith('http')
      ? company.logoUrl
      : `${environment.api.googleStorage}/${environment.wizbiiFiles}/${company.logoUrl}`;
  }

  private computeImageSize(): number {
    let size = this.size === 'medium' ? 80 : 110;

    if (this.window.devicePixelRatio >= 2) {
      size = size * 2;
    }

    return size;
  }
}
