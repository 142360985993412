import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Report } from '@models/job/report';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportWebservice {
  private readonly baseUrl = `${environment.api.job}/jobs/?:id/notifications`;

  constructor(private readonly http: HttpClient) {}

  create(jobId: string, notification: Report): Observable<Report> {
    const url = buildUrl(this.baseUrl, { id: jobId });

    return this.http.post<object>(url, notification).pipe(map((data) => new Report(data)));
  }
}
