export enum DomainEnum {
  // Market / Comm
  Marketing = 'marketing',
  Communication = 'communication',
  Evenementiel = 'evenementiel',
  JournalismeRp = 'journalisme-rp',
  CommunityManagement = 'community-management',

  // Business / Management
  Ventes = 'ventes',
  RelationClientSupport = 'relation-client-support',
  Achats = 'achats',
  Conseil = 'conseil',
  DirectionEntreprise = 'direction-d-entreprise',

  // Créa / Design
  DesignUxUi = 'design-ux-ui',
  GraphismeIllustration = 'graphisme-illustration',
  ProductionAudiovisuelle = 'production-audiovisuelle',

  // RH / Admin / Juridique
  Administration = 'administration',
  RhFormation = 'rh-formation',
  Fiscalite = 'fiscalite',
  Juridique = 'juridique',

  // Gestion / Finance
  ComptabiliteControleDeGestion = 'comptabilite-controle-de-gestion',
  Audit = 'audit',

  // Logistique / Production
  Logistique = 'logistique',
  ProductionFabricationExploitation = 'production-fabrication-exploitation',
  TravauxChantiers = 'travaux-chantiers',
  QualiteMaintenance = 'qualite-maintenance',

  // Tech
  DeveloppementInformatique = 'developpement-informatique',
  GestionDeProjetProduit = 'gestion-de-projet-produit',
  InfraReseauxTelecoms = 'infra-reseaux-telecoms',

  // Science / R&D / Ingénierie
  EtudesStatistiquesData = 'etudes-statistiques-data',
  EnergieMateriauxMecanique = 'energie-materiaux-mecanique',
  ArchitectureUrbanisme = 'architecture-urbanisme',
  ConceptionGenieCivilGenieIndustriel = 'conception-genie-civil-genie-industriel',
  ChimieBiologieAgronomie = 'chimie-biologie-agronomie',
  Environnement = 'environnement',

  // Santé / Social / Culture
  ActivitesSocialesEtCulturelles = 'activites-sociales-et-culturelles',
  ServicesALaPersonne = 'services-a-la-personne',
  Sante = 'sante',
  Enseignement = 'enseignement',

  // Hôtellerie - Restauration
  HotellerieRestauration = 'hotellerie-restauration',
}
