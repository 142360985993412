import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { buildUrl } from '@wizbii/angular-utilities';
import { WizFile } from '@wizbii/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CvWebservice {
  private readonly baseUrl = `${environment.api.profile}/profiles/:profileId/cvs/?:cvId`;

  constructor(private readonly http: HttpClient) {}

  getBy(profileId: string): Observable<WizFile[]> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.get<object[]>(url).pipe(map((response) => response.map((item) => new WizFile(item))));
  }

  create(profileId: string, cv: WizFile): Observable<WizFile> {
    const url = buildUrl(this.baseUrl, { profileId });

    return this.http.post<object>(url, cv).pipe(map((response) => new WizFile(response)));
  }

  update(profileId: string, cv: WizFile): Observable<WizFile> {
    const url = buildUrl(this.baseUrl, { profileId, cvId: cv.id });

    return this.http.put<object>(url, cv).pipe(map((response) => new WizFile(response)));
  }

  remove(profileId: string, cv: WizFile): Observable<void> {
    const url = buildUrl(this.baseUrl, { profileId, cvId: cv.id });

    return this.http.delete<void>(url);
  }
}
