export enum DurationEnum {
  // Legacy durations, delete after job indexing
  LessThreeMonths = 'LESSTHREEMONTHS',
  ThreeAndSixMonths = 'THREEANDSIXMONTHS',
  SixAndTwelveMonths = 'SIXANDTWELVEMONTHS',
  TwelveMonths = 'TWELVEMONTHS',
  TwelveAndTwentyFourMonths = 'TWELVEANDTWENTYFOURMONTHS',
  MoreTwelveMonths = 'MORETWELVEMONTHS',
  ThirtySixMonths = 'THIRTYSIXMONTHS',
  TwentyFourMonths = 'TWENTYFOURMONTHS',
  // New contract durations
  ThreeMonths = 'THREEMONTHS',
  SixMonths = 'SIXMONTHS',
  LessOneMonth = 'LESSONEMONTH',
  OneAndThreeMonths = 'ONEANDTHREEMONTHS',
  FourAndSixMonths = 'FOURANDSIXMONTHS',
  SevenAndTwelveMonths = 'SEVENANDTWELVEMONTHS',
  ThirteenAndTwentyFourMonths = 'THIRTEENANDTWENTYFOURMONTHS',
  TwentyFiveAndThirtySixMonths = 'TWENTYFIVEANDTHIRTYSIXMONTHS',
}
