import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Application } from '@models/profile/application';
import { Profile } from '@models/profile/profile';
import { Select } from '@ngxs/store';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { ApplicationWebservice } from '@webservices/profile-api/application.webservice';
import { BehaviorSubject, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ApplicationStoreService {
  private readonly loadingSubject = new ReplaySubject<boolean>(1);
  private subscription: Subscription;
  private readonly subject = new BehaviorSubject<Map<string, Application>>(undefined);

  @Select(ProfileState.authenticatedProfile) profile$: Observable<Profile>;

  constructor(
    private readonly applicationWebservice: ApplicationWebservice,
    private readonly notificationService: NotificationService
  ) {}

  get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  get applications$(): Observable<Map<string, Application>> {
    if (!this.subject.getValue() && !this.subscription) {
      this.subscription = this.profile$
        .pipe(
          filter((profile) => profile && !!profile.id),
          distinctUntilChanged((itemA, itemB) => itemA.id === itemB.id),
          switchMap((profile) => this.applicationWebservice.getBy(profile.id)),
          map((applications) =>
            applications.reduce((bucket, application) => {
              bucket.set(application.jobId, application);
              return bucket;
            }, new Map())
          )
        )
        .subscribe((bookmarks) => this.subject.next(bookmarks));
    }

    return this.subject.asObservable().pipe(filter((response) => !!response));
  }

  get total$(): Observable<number> {
    return this.applications$.pipe(map((applications) => applications.size));
  }

  add(application: Application): Observable<boolean> {
    let subscriber: Subscriber<boolean>;
    const status$ = new Observable((s: Subscriber<boolean>) => (subscriber = s));

    this.loadingSubject.next(true);

    this.profile$
      .pipe(
        take(1),
        switchMap((profile) => this.applicationWebservice.create(profile.id, profile.locale, application)),
        switchMap(() => this.applications$.pipe(take(1))),
        take(1)
      )
      .subscribe(
        (applications) => {
          applications.set(application.jobId, application);
          this.subject.next(applications);
          subscriber.next(true);
          subscriber.complete();
          this.loadingSubject.next(false);
        },
        (error) => {
          subscriber.error(error);
          subscriber.complete();
        }
      );

    this.pushNotification();

    return status$;
  }

  addExternal(application: Application): Observable<void> {
    this.pushNotification();

    return this.profile$.pipe(
      take(1),
      switchMap((profile) => this.applicationWebservice.createExternal(profile.id, profile.locale, application))
    );
  }

  private pushNotification(): void {
    const notificationID = 'la-ruche-candidature';

    this.notificationService.push({
      id: notificationID,
      product: 'La Ruche',
      title: 'Améliorer ses chances de décrocher un job :',
      content: 'Quand et comment relancer un recruteur ?',
      callToAction: "Lire l'article",
      // link's utm_medium is inserted directly by interservices domain components.
      link: `https://laruche.wizbii.com/relancer-recruteurs/?utm_source=wizbii&utm_label=${notificationID}`,
    });
  }
}
