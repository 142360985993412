import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Profile } from '@models/profile/profile';
import { ProfileSectionEnum } from '@models/profile/profile-section.enum';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  GetProfile,
  GetProfileSuccess,
  SaveProfileOnboarding,
  SaveProfileOnboardingSuccess,
  SaveProfileSection,
  SaveProfileSectionSuccess,
  SetAuthenticatedProfile,
} from '@store/ngxs-profile/profile.actions';
import { getAccountRequiredParams } from '@store/ngxs-profile/query-params';
import { Logout } from '@store/session/session.actions';
import { AccountWebservice } from '@webservices/account-api/account.webservice';
import { ProfileWebservice } from '@webservices/profile-api/profile.webservice';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { WINDOW } from '@wizbii/angular-utilities';
import { Request } from 'express';
import qs from 'qs';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export interface ProfileStateModel {
  authenticatedProfileId: string;
  profiles: Record<string, Profile>;
  loading: Record<ProfileSectionEnum, boolean>;
}

const defaultState: ProfileStateModel = {
  authenticatedProfileId: null,
  profiles: {},
  loading: {
    [ProfileSectionEnum.Contact]: false,
    [ProfileSectionEnum.Educations]: false,
    [ProfileSectionEnum.ExtraExperiences]: false,
    [ProfileSectionEnum.HardSkills]: false,
    [ProfileSectionEnum.Intro]: false,
    [ProfileSectionEnum.Languages]: false,
    [ProfileSectionEnum.Links]: false,
    [ProfileSectionEnum.PersonalIdentity]: false,
    [ProfileSectionEnum.ProExperiences]: false,
    [ProfileSectionEnum.SoftSkills]: false,
    [ProfileSectionEnum.Title]: false,
    [ProfileSectionEnum.Visibility]: false,
  },
};

@State<ProfileStateModel>({
  name: 'profile',
  defaults: defaultState,
})
@Injectable()
export class ProfileState {
  static onceProfile = false;

  static profile(profileId: string) {
    return createSelector([ProfileState], (state: ProfileStateModel) => {
      return state.profiles[profileId];
    });
  }

  @Selector()
  static authenticatedProfile(state: ProfileStateModel): Profile {
    return state.profiles[state.authenticatedProfileId];
  }

  @Selector()
  static authenticatedProfileId(state: ProfileStateModel): string {
    return state.authenticatedProfileId;
  }

  @Selector()
  static loading(state: ProfileStateModel): Record<ProfileSectionEnum, boolean> {
    return state.loading;
  }

  constructor(
    private readonly profileWebservice: ProfileWebservice,
    private readonly accountWebservice: AccountWebservice,
    private readonly bugsnagService: BugsnagService,
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: any,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {}

  /* eslint-disable */
  @Action(GetProfile)
  getProfile(ctx: StateContext<ProfileStateModel>, { id, silence404 }: GetProfile) {
    const { profiles } = ctx.getState();

    if (!profiles[id]) {
      return this.profileWebservice.get(id).pipe(
        switchMap((profile) => ctx.dispatch(new GetProfileSuccess(profile))),
        silence404 // ADR #006
          ? catchError((err: HttpErrorResponse | Error) => {
              this.bugsnagService.sendError(err, 'info');
              return throwError(err);
            })
          : catchError((error) => {
              if (
                error.status === 404 &&
                error.url.includes('profile-api') &&
                error.url.includes('/profiles') &&
                !ProfileState.onceProfile
              ) {
                ProfileState.onceProfile = true;

                return this.accountWebservice.getIdentityCard(id).pipe(
                  switchMap(() => {
                    const pathname =
                      this.request && isPlatformServer(this.platformId)
                        ? this.request.url
                        : this.window.location.pathname;
                    const urlParsed =
                      this.request && isPlatformServer(this.platformId)
                        ? this.request.originalUrl
                        : `${this.window.location.pathname}${this.window.location.search}`;

                    const redirect = `${environment.urls.job[environment.locale]}${pathname}`;
                    const isJobUrl = pathname.match(/\/company\/.+\/job\/.+/i);
                    const jobId: string = isJobUrl ? pathname.split('/').pop() : null;
                    const queryParamsAccount = {
                      ...getAccountRequiredParams(this.router.parseUrl(urlParsed).queryParams),
                      ...(jobId ? { 'job-id': jobId, context: 'wizbii-jobs-offer' } : {}),
                      'app-id': environment.applicationId,
                      redirect,
                    };
                    const queryParamsAccountStr = qs.stringify(queryParamsAccount);

                    this.window.open(`${environment.urls.accountActivationBase}?${queryParamsAccountStr}`, '_self');

                    return of({});
                  }),
                  catchError(() => {
                    return ctx.dispatch(new Logout());
                  })
                );
              }

              return throwError(error);
            })
      );
    }
  }

  @Action(GetProfileSuccess)
  getProfileSuccess(ctx: StateContext<ProfileStateModel>, { profile }: GetProfileSuccess) {
    return ctx.patchState({ profiles: { ...ctx.getState().profiles, [profile.id]: profile } });
  }

  @Action(SetAuthenticatedProfile)
  setAuthenticatedProfile(ctx: StateContext<ProfileStateModel>, { id }: SetAuthenticatedProfile) {
    ctx.patchState({ authenticatedProfileId: id });
    return ctx.dispatch(new GetProfile(id, false));
  }

  @Action(SaveProfileSection)
  saveProfileSection(ctx: StateContext<ProfileStateModel>, { section, data }: SaveProfileSection) {
    const { loading, authenticatedProfileId } = ctx.getState();

    ctx.patchState({
      loading: {
        ...loading,
        [section]: true,
      },
    });

    return this.profileWebservice.patch(authenticatedProfileId, data).pipe(
      switchMap((profile) => ctx.dispatch(new SaveProfileSectionSuccess(section, profile))),
      catchError((err: HttpErrorResponse | Error) => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          const error = new Error('Internal Server Error while patching profile');
          error['ngDebugContext'] = {
            component: 'ProfileState',
            context: { section, data },
          };
          return throwError(error);
        }

        return throwError(err);
      })
    );
  }

  @Action(SaveProfileSectionSuccess)
  saveProfileSectionSuccess(ctx: StateContext<ProfileStateModel>, { section, profile }: SaveProfileSectionSuccess) {
    const { loading, authenticatedProfileId } = ctx.getState();

    return ctx.patchState({
      profiles: {
        ...ctx.getState().profiles,
        [authenticatedProfileId]: profile,
      },
      loading: {
        ...loading,
        [section]: false,
      },
    });
  }

  @Action(SaveProfileOnboarding)
  saveProfileOnboarding(ctx: StateContext<ProfileStateModel>, { data }: SaveProfileOnboarding) {
    const { authenticatedProfileId } = ctx.getState();

    return this.profileWebservice
      .patch(authenticatedProfileId, data)
      .pipe(switchMap((profile) => ctx.dispatch(new SaveProfileOnboardingSuccess(profile))));
  }

  @Action(SaveProfileOnboardingSuccess)
  saveProfileOnboardingSuccess(ctx: StateContext<ProfileStateModel>, { profile }: SaveProfileOnboardingSuccess) {
    const { profiles } = ctx.getState();

    return ctx.patchState({
      profiles: {
        ...profiles,
        [profile.id]: profile,
      },
    });
  }
}
