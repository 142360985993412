import { buildConfig } from '@config/build-config';
import { LangEnum, LocaleEnum } from '@models/commons/locales.enum';

const DEFAULT_VARS = {
  API_PLATFORM: 'staging',
  API_DOMAIN: 'staging.k8s.wizbii.us',
  WIZBII_DOMAIN: 'staging.k8s.wizbii.us',
  ALGOLIA_APP_ID: 'testing1G17FKGF4S',
  ALGOLIA_API_KEY: '097ca7bbd7c3e8a0a4a3104d90aa0aef',
  FACEBOOK_CONNECT_KEY: '758995414242020',
  FACEBOOK_CONNECT_VERSION: 'v7.0',
  TIKTOK_PIXEL_ID: 'C5P8C9DODNNK397VRGCG',
  GOOGLE_CONNECT_KEY: '906110026825-3mv59bett800p2cg6dtpuqvg2fes6clu.apps.googleusercontent.com',
  WIZBII_ANALYTICS_UAI: 'UA-22851031-32',
  GOOGLE_ANALYTICS_UAI: 'UA-22851031-33',
  WIZBII_FILES_GCS_BUCKET: 'wizbii-files-qa3',
};

// Read static environment variables (provided at build time with custom Webpack config)
const useServerVars = !!process.env.USE_SERVER_VARS;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isProdBuild = buildConfig === 'production';
const version = process.env.VERSION;
const lang: LangEnum = LangEnum[process.env.LANG] || LangEnum.fr;
const locale: LocaleEnum = LocaleEnum[process.env.LOCALE] || LocaleEnum.fr_FR;
const legalLang: LangEnum = lang === LangEnum.fr || lang === LangEnum.en ? lang : LangEnum.en;

// Retrieve environment variables (injected during SSR), if any
let vars: Record<string, string> = {};
if (typeof window === 'object') {
  vars = window['env'] || {};
} else if (typeof global === 'object') {
  vars = global['ANGULAR_ENV'] || {};
}

// Determine deployment platform and domain from vars
const platform = vars.PLATFORM || 'local';
const domain = vars.WIZBII_DOMAIN;
const isDeployed = !!vars.PLATFORM;

// Determine runtime environment variables
// => use en vars if `USE_SERVER_VARS` is set at build time (for prod and automatic QA deploys)
// => default to QA3 vars (for local dev and manual QA deploys)
const runtimeEnv = useServerVars ? vars : DEFAULT_VARS;

// Determine API platform and domain from runtime vars
const apiPlatform = runtimeEnv.API_PLATFORM;
const apiDomain = runtimeEnv.API_DOMAIN;
const applicationId = 'jobs';
const contactEmailKey = 'contact';
const localhost = 'http://localhost:4200';

export const environment = {
  applicationId: 'jobs',
  contactEmailKey,
  platform,
  domain,
  isDeployed,
  siteUrl: isDeployed ? `https://${platform === 'prod' ? 'www' : 'jobs'}.${domain}` : '',
  redirectUrl: `https://jobs.${domain}`,
  apiPlatform,
  apiDomain,
  isProdBuild,
  version,
  lang,
  locale,
  legalLang,
  bugsnagId: 'bae21a2a1acf5670a9539df25a804a88',
  urls: {
    account: `https://account.${apiDomain}/${locale}`,
    accountSignin: `https://account.${apiDomain}/${locale}/sign-in?app-id=${applicationId}&redirect=`,
    accountSignup: `https://account.${apiDomain}/${locale}/sign-up?app-id=${applicationId}&redirect=`,
    accountLogout: `https://account.${apiDomain}/${locale}/logout?app-id=${applicationId}&redirect=`,
    accountActivation: `https://account.${apiDomain}/${locale}/sign-up/activation?app-id=${applicationId}&redirect=`,
    accountActivationBase: `https://account.${apiDomain}/${locale}/sign-up/activation`,
    job: {
      [LocaleEnum.fr_FR]: platform !== 'local' ? `https://www.${apiDomain}` : localhost,
      [LocaleEnum.en_GB]: platform !== 'local' ? `https://en.${apiDomain}` : localhost,
      [LocaleEnum.es_ES]: platform !== 'local' ? `https://es.${apiDomain}` : localhost,
      [LocaleEnum.it_IT]: platform !== 'local' ? `https://it.${apiDomain}` : localhost,
    },
    jobHome: {
      [LocaleEnum.fr_FR]: platform !== 'local' ? `https://jobs.${apiDomain}` : localhost,
      [LocaleEnum.en_GB]: platform !== 'local' ? `https://en.${apiDomain}` : localhost,
      [LocaleEnum.es_ES]: platform !== 'local' ? `https://es.${apiDomain}` : localhost,
      [LocaleEnum.it_IT]: platform !== 'local' ? `https://it.${apiDomain}` : localhost,
    },
  },
  api: {
    account: `https://account.api.${apiDomain}`,
    address: `https://place.${apiDomain}`,
    authentication: `https://auth.${apiDomain}`,
    company: `https://company-api.${apiDomain}/v1`,
    contact: `https://contact.api.${apiDomain}`,
    consent: `https://consent.${apiDomain}`,
    content: `https://content-api.${apiDomain}`,
    file: `https://file.${apiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    images: `https://images.${apiDomain}`,
    job: `https://job-api.${apiDomain}/api`,
    loyaltyLegacy: `https://account-v1.${apiDomain}`,
    media: `https://m-api.${apiDomain}/v1`,
    opsEvent: `https://ops-event.api.${apiDomain}/api`,
    opsEventV2: `https://ops-event.api.${apiDomain}/v2`,
    profile: `https://profile-api.${apiDomain}/api`,
    publicPartner: `https://p.api.${apiDomain}`,
    satisfaction: `https://satisfaction-api.${apiDomain}/v1`,
    school: `https://school-api.${apiDomain}/api`,
    search: `https://search-v2.${apiDomain}`,
    searchStats: `https://search-stats.api.${apiDomain}`,
    seo: `https://seo.${apiDomain}/v1`,
    wizbiiAnalytiks: `https://a.${apiDomain}`,
  },
  algolia: {
    applicationId: runtimeEnv.ALGOLIA_APP_ID,
    apiKey: runtimeEnv.ALGOLIA_API_KEY,
    index: {
      companies: `${apiPlatform}_companies`,
      jobs: `${apiPlatform}_jobs`,
      schools: `${apiPlatform}_schools`,
    },
  },
  legacyPartnersDomain: `https://p.${apiDomain}`,
  facebookLogin: {
    appId: runtimeEnv.FACEBOOK_CONNECT_KEY,
    version: runtimeEnv.FACEBOOK_CONNECT_VERSION,
  },
  facebookPixel: {
    appId: platform === 'prod' ? '727985974263933' : '758995414242020',
    version: '2.0',
  },
  serviceUrls: {
    jobs: `https://www.${apiDomain}`,
    fibii: `https://money.${apiDomain}`,
    'permii-app': `https://drive.${apiDomain}`,
    blog: `https://laruche.${apiDomain}`,
    protect: `https://protect.${apiDomain}/sante`,
    account: `https://account.${apiDomain}`,
  },
  googleLoginProvider: runtimeEnv.GOOGLE_CONNECT_KEY,
  wizbiiAnalytiksUserId: runtimeEnv.WIZBII_ANALYTICS_UAI,
  googleAnalyticsUserId: runtimeEnv.GOOGLE_ANALYTICS_UAI,
  bingPixelId: '56348309',
  wizbiiFiles: runtimeEnv.WIZBII_FILES_GCS_BUCKET,
  gdprWidgetUrl: 'https://storage.googleapis.com/cdn-wizbii/consent-widget/latest/bundle.js',
  tiktokId: runtimeEnv.TIKTOK_PIXEL_ID,
  hotjar: {
    id: '19879',
    version: '6',
  },
  linkedinPartnerId: '1090721',
  neuvoo: 'wizbii',
};
