import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EventType } from '@models/ops-event/event-type';
import { OpsEvent, OpsEventLight } from '@models/ops-event/ops-event';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OpsEventWebservice {
  private readonly baseUrl = environment.api.opsEvent;
  private readonly baseUrlV2 = environment.api.opsEventV2;

  constructor(private readonly http: HttpClient) {}

  getBy(eventId: string): Observable<OpsEvent> {
    // we need to filter jobs with name.length < 3 cause there are some weird names in sourcii
    return this.http.get<object>(`${this.baseUrlV2}/event/${eventId}`).pipe(
      map((response) => new OpsEvent(response)),
      map((opsEvent) => ({
        ...opsEvent,
        jobs: opsEvent.jobs.filter((job) => job.name.length > 3),
      }))
    );
  }

  getAll(): Observable<OpsEventLight[]> {
    return this.http
      .get<object[]>(`${this.baseUrl}/events/list/incoming-light-illustrated`)
      .pipe(map((response) => response.map((item) => new OpsEventLight(item))));
  }

  getEventTypes(): Observable<EventType[]> {
    return this.http.get<EventType[]>(`${this.baseUrlV2}/event-types`);
  }
}
