export enum LangEnum {
  fr = 'fr',
  en = 'en',
  it = 'it',
  es = 'es',
}

export enum LocaleEnum {
  fr_FR = 'fr_FR',
  en_GB = 'en_GB',
  it_IT = 'it_IT',
  es_ES = 'es_ES',
}

export const DEFAULT_LOCALE_BY_LANGUAGE: Record<LangEnum, LocaleEnum> = {
  [LangEnum.fr]: LocaleEnum.fr_FR,
  [LangEnum.en]: LocaleEnum.en_GB,
  [LangEnum.it]: LocaleEnum.it_IT,
  [LangEnum.es]: LocaleEnum.es_ES,
};

export const DEFAULT_LANGUAGE_BY_LOCALE: Record<LocaleEnum, LangEnum> = {
  [LocaleEnum.fr_FR]: LangEnum.fr,
  [LocaleEnum.en_GB]: LangEnum.en,
  [LocaleEnum.it_IT]: LangEnum.it,
  [LocaleEnum.es_ES]: LangEnum.es,
};
