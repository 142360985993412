import { LinkTypeEnum } from '@models/link/link-type.enum';

export class Link {
  media: LinkTypeEnum;
  url: string;
  urlBuildingInfo: string;
  title: string;
  type: LinkTypeEnum;

  constructor(data = {}) {
    /*
     * url, type and title are the name of the model for links returned by company-api
     * media and urlBuildingInfo are the name of the model for links returned by profile-api
     *
     * By merging these two classes here, we need to update the name of the returned fields
     * of one of the api.
     *
     */
    this.title = data['title']; // only on company : often null
    this.type = data['type'];
    this.media = data['media'] || ''; // aka type on company
    this.urlBuildingInfo = data['urlBuildingInfo'] || '';
    this.url = data['url'] || this.buildUrl(data['media'], data['urlBuildingInfo']); // aka url on company
  }

  buildIconName(media: LinkTypeEnum): string {
    if (media === LinkTypeEnum.Other) {
      return 'blog';
    }

    return media.toString().toLowerCase();
  }

  checkIfUsingCompleteUrl(media: LinkTypeEnum): boolean {
    switch (media) {
      case LinkTypeEnum.Behance:
      case LinkTypeEnum.Dribbble:
      case LinkTypeEnum.Facebook:
      case LinkTypeEnum.Github:
      case LinkTypeEnum.Instagram:
      case LinkTypeEnum.Pinterest:
      case LinkTypeEnum.Skype:
      case LinkTypeEnum.Twitter:
        return false;
      default:
        return true;
    }
  }

  private buildUrl(media: LinkTypeEnum, urlBuildingInfo: string): string {
    if (!media || !urlBuildingInfo) {
      return '';
    }
    // Complete from company-api if needed : http:// vs. @user vs user

    const prefixHttpsEverywhere = /(?:https?:\/\/)?(.*?\..*?)(\/.*?(?:\..*?)?)?$/g;
    /*
     * The regex above is able to select a wide range of url, with or without http prefixes
     * We can use it to enforce all displayed url will be navigable
     * This might be a problem if target url does not support https :
     * - browser will warn about incorrect certificate
     * - server might not allow https connection
     *
     * Alternatively we can use : const forceAtLeastHttpPrefix = /^((?!https?:\/\/).*)$/g;
     * to set at least http prefix, relying on the fact that https websites should
     * - set hsts header
     * - redirect all http connection to https
     */

    switch (media) {
      case LinkTypeEnum.Behance:
        return `https://www.behance.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Dribbble:
        return `https://www.dribbble.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Facebook:
        return `https://www.facebook.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Github:
        return `https://www.github.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Instagram:
        return `https://www.instagram.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Pinterest:
        return `https://www.pinterest.com/${urlBuildingInfo}`;
      case LinkTypeEnum.Skype:
        return `skype:${urlBuildingInfo}?call`;
      case LinkTypeEnum.Twitter:
        return `https://www.twitter.com/${urlBuildingInfo}`;
      default:
        return urlBuildingInfo.replace(prefixHttpsEverywhere, 'https://$1$2');
    }
  }
}
