import { PageSeo } from '@features/directories/job-layout/jobs-list/seo/page-seo';
import { RelEnum } from '@features/directories/job-layout/jobs-list/seo/rel.enum';

export class PaginationSeo {
  currentPage: number;
  nbElementsPerPage: number;
  nbElements: number;
  nbPages: number;

  constructor(nbElements: number, nbElementsPerPage: number, currentPage: number, nbPages: number) {
    this.nbElements = nbElements;
    this.nbElementsPerPage = nbElementsPerPage;
    this.currentPage = currentPage;
    this.nbPages = nbPages;
  }

  /* eslint-disable  */
  getComputedPagination(): PageSeo[] {
    const pages = [];

    const lastPageNumber = this.getLastPage();
    const currentPageNumber = this.currentPage + 1;

    const currentPageLowTenBoundary = Math.floor(currentPageNumber / 10) * 10;

    let i = Math.max(1, currentPageLowTenBoundary - 10);

    while (i <= lastPageNumber) {
      const page = new PageSeo(i);

      if (i === currentPageNumber + 1) {
        page.rel = RelEnum.REL_NEXT;
      } else if (i === currentPageNumber - 1) {
        page.rel = RelEnum.REL_PREV;
      } else if (i === currentPageNumber) {
        page.rel = RelEnum.REL_CURRENT;
      }

      const baseIterationStep = this.computeIterationStep(lastPageNumber, currentPageNumber, i);
      const currentIterationLowBoundary = Math.floor(i / baseIterationStep) * baseIterationStep;
      const iterationStep = baseIterationStep - (i - currentIterationLowBoundary);

      pages.push(page);
      i += iterationStep;
    }

    let hasFirstPage = false;
    let hasLastPage = false;

    pages.forEach((alreadyExistingPage) => {
      if (alreadyExistingPage.number === 1) {
        hasFirstPage = true;
      } else if (alreadyExistingPage.number === lastPageNumber) {
        hasLastPage = true;
      }
    });

    if (!hasFirstPage) {
      pages.unshift(new PageSeo(1, currentPageNumber === 1 ? RelEnum.REL_CURRENT : null));
    }

    if (!hasLastPage && lastPageNumber > 0) {
      pages.push(new PageSeo(lastPageNumber));
    }

    return pages;
  }
  /* eslint-enable */

  computeIterationStep(lastPageNumber: number, currentPageNumber: number, currentlyComputedPageNumber: number): number {
    for (let j = 1; j <= lastPageNumber.toString().length; j++) {
      const range = Math.pow(10, j);
      const lowBoundary = Math.floor(currentPageNumber / range) * range;
      const highBoundary = Math.ceil((currentPageNumber + 1) / range) * range;

      if (currentlyComputedPageNumber >= lowBoundary && currentlyComputedPageNumber < highBoundary) {
        return Math.pow(10, j - 1);
      }
    }
    return 1;
  }

  getLastPage(): number {
    const effectiveLastPage = Math.ceil(this.nbElements / this.nbElementsPerPage);
    return effectiveLastPage > this.nbPages ? this.nbPages : effectiveLastPage;
  }
}
