<article class="lightweight">
  <company-logo class="lightweight__logo" [company]="model.company"></company-logo>

  <div class="lightweight__wrap">
    <header class="lightweight-header lightweight__header">
      <span class="lightweight-header__company">{{ model.company.name }}</span>

      <job-status class="lightweight-header__status" [date]="model.createdDate" [filled]="!model.online"></job-status>

      <span class="lightweight-header__divider"></span>

      <job-bookmark
        *ngIf="interactive"
        class="lightweight-header__bookmark"
        [model]="bookmark"
        (toggle)="toggleBookmark.emit($event)"
      ></job-bookmark>

      <h3 [id]="model.id" class="lightweight-header__title">
        <a
          [routerLink]="['/', JobRoutingEnum.Company, model.company.id, JobRoutingEnum.Job, model.id]"
          [queryParamsHandling]="queryParamsHandling"
          [queryParams]="queryParams"
          fragment="layout-main"
          appEventTracker
          [eventCategory]="trackingData.eventCategory"
          [eventAction]="trackingData.eventAction"
          [eventLabel]="trackingData.eventLabel || 'Card'"
          [eventValue]="trackingData.eventValue"
          [algoliaTrackingData]="trackingData.algolia"
          class="lightweight-header__title--link"
          >{{ model?.title }}</a
        >
      </h3>
    </header>

    <div class="lightweight-content lightweight__content">
      <job-tags
        class="lightweight-content__tags"
        [leftAlign]="true"
        [model]="model"
        [addStructuredData]="false"
      ></job-tags>

      <job-skills
        *ngIf="model.skills && model.skills.length"
        class="lightweight-content__skills"
        [model]="model.skills"
        [leftAlign]="true"
      ></job-skills>
    </div>
  </div>

  <footer *ngIf="interactive" class="lightweight-footer lightweight__footer">
    <p class="lightweight-footer__simple-apply" [attr.aria-hidden]="model.externalUrl ? 'true' : undefined" i18n>
      Candidature Simplifiée
    </p>

    <a
      class="lightweight-footer__btn btn"
      [routerLink]="['/', JobRoutingEnum.Company, model.company.id, JobRoutingEnum.Job, model.id]"
      [queryParamsHandling]="queryParamsHandling"
      [queryParams]="queryParams"
      fragment="layout-main"
      [attr.aria-describedby]="model.id"
      appEventTracker
      [eventCategory]="trackingData.eventCategory"
      [eventAction]="trackingData.eventAction"
      [eventLabel]="trackingData.eventLabel || 'Button'"
      [eventValue]="trackingData.eventValue"
      [algoliaTrackingData]="trackingData.algolia"
    >
      <ng-container i18n>Voir le job</ng-container>

      <app-svg-icon class="btn__icon" icon="arrow-right" width="0.85em" height="0.7em"></app-svg-icon>
    </a>

    <p class="lightweight-footer__info" [attr.aria-hidden]="application ? undefined : 'true'">
      <ng-container i18n>Postulé </ng-container>

      <span *ngIf="application" class="lightweight-footer__date" i18n
        >le {{ application.createdDate | date: 'shortDate' }}</span
      >
    </p>
  </footer>
</article>
