<span
  [attr.itemprop]="addStructuredData ? 'jobLocation' : null"
  [attr.itemscope]="addStructuredData ? '' : null"
  [attr.itemtype]="addStructuredData ? 'http://schema.org/Place' : null"
>
  <app-svg-icon
    class="taglist__icon"
    icon="exp-location"
    i18n-label="presentation offre emploi|emplacement"
    label="Lieu de travail"
    width=".7em"
    height="1em"
  ></app-svg-icon>

  <span
    [attr.itemprop]="addStructuredData ? 'address' : null"
    [attr.itemscope]="addStructuredData ? '' : null"
    [attr.itemtype]="addStructuredData ? 'http://schema.org/PostalAddress' : null"
  >
    <ng-container *ngIf="location.city && location.department; else fallback">
      <span [attr.itemprop]="addStructuredData ? 'addressLocality' : null">{{ location.city }}</span>
      (<span [attr.itemprop]="addStructuredData ? 'addressRegion' : null">{{ location.department }}</span
      >)
    </ng-container>

    <ng-template #fallback>
      <span [attr.itemprop]="addStructuredData ? 'addressLocality' : null">{{ location.short }}</span>
    </ng-template>

    <meta
      *ngIf="location.zipCode"
      [attr.itemprop]="addStructuredData ? 'postalCode' : null"
      content="{{ location.zipCode }}"
    />
    <meta
      *ngIf="location.address"
      [attr.itemprop]="addStructuredData ? 'streetAddress' : null"
      content="{{ location.address }}"
    />
  </span>
</span>
