import { LangEnum } from '@models/commons/locales.enum';
import { TypesEnum } from '@routing/directories/directories-routing.enum';

/* eslint-disable  */
export const CanonicalUrls: Record<LangEnum, Record<TypesEnum, string>> = {
  [LangEnum.fr]: {
    [TypesEnum.domain]: '/directory/lp/domaine',
    [TypesEnum.city]: '/directory/lp/ville',
    [TypesEnum.contract]: '/directory/lp/contrat',
    [TypesEnum.company]: '/company/:companyId',
    [TypesEnum.job]: '/company/:companyId/job/:jobId',
    [TypesEnum.jobList]: '/emploi/:id',
    [TypesEnum.profile]: '/profile/:id',
  },
  [LangEnum.en]: {
    [TypesEnum.domain]: '/directory/lp/field',
    [TypesEnum.city]: '/directory/lp/city',
    [TypesEnum.contract]: '/directory/lp/contract',
    [TypesEnum.company]: '/company/:companyId',
    [TypesEnum.job]: '/company/:companyId/job/:jobId',
    [TypesEnum.jobList]: '/job-offers/:id',
    [TypesEnum.profile]: '/profile/:id',
  },
  [LangEnum.it]: {
    [TypesEnum.domain]: '/directory/lp/professione',
    [TypesEnum.city]: '/directory/lp/citta',
    [TypesEnum.contract]: '/directory/lp/contrarre',
    [TypesEnum.company]: '/company/:companyId',
    [TypesEnum.job]: '/company/:companyId/job/:jobId',
    [TypesEnum.jobList]: '/impieghi/:id',
    [TypesEnum.profile]: '/profile/:id',
  },
  [LangEnum.es]: {
    [TypesEnum.domain]: '/directory/lp/especializacion',
    [TypesEnum.city]: '/directory/lp/localidad',
    [TypesEnum.contract]: '/directory/lp/contrato',
    [TypesEnum.company]: '/company/:companyId',
    [TypesEnum.job]: '/company/:companyId/job/:jobId',
    [TypesEnum.jobList]: '/empleo/:id',
    [TypesEnum.profile]: '/profile/:id',
  },
};
/* eslint-enable */

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
