import { Injectable, OnDestroy } from '@angular/core';
import { NotificationWebservice } from '@webservices/account-api/notification.webservice';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private readonly notificationSubject = new Subject<any>();
  private timer: number;

  constructor(private readonly notificationWebservice: NotificationWebservice) {}

  get notification$(): Observable<any> {
    return this.notificationSubject.asObservable();
  }

  push(notification: any, delay = 1500): void {
    this.timer = setTimeout(() => {
      this.notificationSubject.next(notification);
    }, delay) as any;
  }

  notifyNotificationIsAccepted(notification: any): void {
    this.notificationWebservice.post(notification);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
    this.notificationSubject.complete();
  }
}
