import { animate, animateChild, group, keyframes, query, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Bookmark } from '@models/profile/bookmark';

@Component({
  selector: 'job-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
  animations: [
    trigger('hop', [
      transition('false => true', [
        style({ willChange: 'transform' }),
        group([
          query('@spin', animateChild()),
          animate(
            '600ms ease-in-out',
            keyframes([
              style({ transform: 'translate(0, 0)', offset: 0 }),
              style({ transform: 'translate(.125em, -.625em)', offset: 0.17 }),
              style({ transform: 'translate(.25em, 0)', offset: 0.33 }),
              style({ transform: 'translate(-.125em, -.4375em)', offset: 0.5 }),
              style({ transform: 'translate(-.25em, 0)', offset: 0.66 }),
              style({ transform: 'translate(-.125em, -.3125em)', offset: 0.84 }),
              style({ transform: 'translate(0, 0)', offset: 1 }),
            ])
          ),
        ]),
      ]),
    ]),
    trigger('spin', [
      transition('false => true', [
        style({ willChange: 'transform, color, stroke' }),
        group([
          animate(
            '600ms ease-in-out',
            keyframes([
              style({ transform: 'rotate(0)', offset: 0 }),
              style({ transform: 'rotate(175deg)', offset: 0.33 }),
              style({ transform: 'rotate(120deg)', offset: 0.66 }),
              style({ transform: 'rotate(144deg)', offset: 1 }),
            ])
          ),
          animate(
            '300ms ease-out',
            style({
              color: '#f8c60b',
              stroke: '#f8c60b',
            })
          ),
        ]),
      ]),
      transition('true => false', [
        style({
          transform: 'rotate(72deg)',
          willChange: 'transform, color, stroke',
        }),
        animate(
          '300ms ease-out',
          style({
            transform: 'rotate(0)',
            color: 'rgba(255, 255, 255, 0)',
            stroke: '#323030',
          })
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarkComponent {
  @Input() model: Bookmark = null; // initial value to not animate on first display
  @Input() seamless = true;
  @Output() toggle = new EventEmitter<Bookmark>();
}
