export class CityGet {
  static readonly type = '[City] Get';

  constructor(readonly cityPlaceId: string) {}
}

export class CityGetBatch {
  static readonly type = '[City] Get Batch';

  constructor(readonly cityPlaceIds: string[]) {}
}
