import { Injectable } from '@angular/core';
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { Action, State, StateContext } from '@ngxs/store';
import { Lock, Unlock } from '@store/scroll-lock/scroll-lock.actions';

@State<number>({
  name: 'totalScrollLocks',
  defaults: 0,
})
@Injectable()
export class ScrollLockState {
  constructor(private readonly scrollLockService: ScrollLockService) {}

  @Action(Lock)
  lock(ctx: StateContext<number>): void {
    const total = ctx.getState() + 1;
    ctx.setState(total);

    if (total === 1) {
      this.scrollLockService.lock();
    }
  }

  @Action(Unlock)
  unlock(ctx: StateContext<number>): void {
    const total = ctx.getState() - 1;
    ctx.setState(total);

    if (total === 0) {
      this.scrollLockService.unlock();
    }
  }
}
