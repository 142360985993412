import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CompanyPublicationPagination } from '@models/company/company-publication-pagination';
import { Publication } from '@models/company/publication';
import { Page } from '@models/search/page';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsWebservice {
  private readonly baseUrl = `${environment.api.company}/newsfeed`;

  constructor(private readonly http: HttpClient) {}

  getByCompany(companyId: string, page = 0, perPage = 21): Observable<Page<Publication>> {
    const url = buildUrl(`${this.baseUrl}/?:companyId`, { companyId, page: `${page}`, perPage: `${perPage}` });

    return this.http.get<object>(url).pipe(
      map((response) => ({
        ...response,
        hits: (response['hits'] || []).map((hit) => new Publication(hit)),
      })),
      map((response) => new Page<Publication>(response))
    );
  }

  getByProfile(profileId: string, page = 0, perPage = 21): Observable<CompanyPublicationPagination> {
    const url = buildUrl(this.baseUrl, { profileId, page: `${page}`, perPage: `${perPage}` });

    return this.http.get<object>(url).pipe(
      map((response) => ({
        ...response,
        hits: (response['hits'] || []).map((hit) => new Publication(hit)),
      })),
      map((response) => new CompanyPublicationPagination(response))
    );
  }
}
