import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DotsLoaderModule } from '@commons/dots-loader/dots-loader.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { CompanyModule } from '@domains/company/company.module';
import { JobModule } from '@domains/job/job.module';
import { RedirectComponent } from '@features/redirection/redirect/redirect.component';

@NgModule({
  imports: [CommonModule, JobModule, SvgIconModule, CompanyModule, DotsLoaderModule],
  declarations: [RedirectComponent],
})
export class RedirectionModule {}
