import { LocaleEnum } from '@models/commons/locales.enum';
import { Education } from '@models/profile/education';
import { ExtraExperience } from '@models/profile/extra-experience';
import { HardSkill } from '@models/profile/hard-skill';
import { Language } from '@models/profile/language';
import { Link } from '@models/profile/link';
import { OnboardingStepsEnum } from '@models/profile/onboarding-steps.enum';
import { ProExperience } from '@models/profile/pro-experience';
import { SexEnum } from '@models/profile/sex.enum';
import { SoftSkillEnum } from '@models/profile/soft-skill.enum';
import { Wish } from '@models/profile/wish';
import { City } from '@wizbii/models';

export class Profile {
  id: string;
  firstName: string;
  lastName: string;
  dateBirthday: Date;
  drivingLicence: boolean;
  noDegree: boolean;
  location: City;
  email: string;
  extraExperiences: ExtraExperience[];
  mobile: string;
  avatar: string;
  title: string;
  resume: string;
  hardSkills: HardSkill[];
  softSkills: SoftSkillEnum[];
  educations: Education[];
  proExperiences: ProExperience[];
  languages: Language[];
  locale: LocaleEnum;
  sex: SexEnum;
  wishes: Wish[];
  firstConnectionFinishedDate: Date;
  onboardingFinished: boolean;
  remainingOnboardingSteps: OnboardingStepsEnum[];
  allowIndex: boolean;
  links: Link[];

  constructor(data = {}) {
    this.id = data['id'];
    this.firstName = data['firstName'];
    this.lastName = data['lastName'];
    this.dateBirthday = data['dateBirthday'] ? new Date(data['dateBirthday']) : undefined;
    this.noDegree = data['noDegree'] || false;
    this.location = data['location'] ? new City(data['location']) : undefined;
    this.email = data['email'];
    this.extraExperiences = (data['extraExperiences'] || []).map((item) => new ExtraExperience(item));
    this.mobile = data['mobile'];
    this.avatar = data['avatar'];
    this.title = data['title'];
    this.resume = data['resume'];
    this.hardSkills = (data['hardSkills'] || []).map((skill) => new HardSkill(skill));
    this.softSkills = data['softSkills'] || [];
    this.educations = (data['educations'] || []).map((education) => new Education(education));
    this.languages = (data['languages'] || []).map((language) => new Language(language));
    this.proExperiences = (data['proExperiences'] || []).map((proExperience) => new ProExperience(proExperience));
    this.locale = data['locale'];
    this.sex = data['sex'];
    this.wishes = (data['wishes'] || []).map((wish) => new Wish(wish));
    this.firstConnectionFinishedDate = data['firstConnectionFinishedDate']
      ? new Date(data['firstConnectionFinishedDate'])
      : undefined;
    this.onboardingFinished = data['onboardingFinished'];
    this.remainingOnboardingSteps = data['remainingOnboardingSteps'] || [];
    this.allowIndex = data['allowIndex'];
    this.links = (data['links'] || []).map((link) => new Link(link));
  }
}
