import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CompanyFilter } from '@models/commons/company-filter';
import { Company } from '@models/company/company';
import { Page } from '@models/search/page';
import { AlgoliaClientService } from '@wizbii/algolia';
import { SearchIndex } from 'algoliasearch';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CompanySearchWebservice {
  index: SearchIndex;

  constructor(private readonly algoliaClientService: AlgoliaClientService) {
    this.index = this.algoliaClientService.client.initIndex(environment.algolia.index.companies);
  }

  getBy(filter: CompanyFilter): Observable<Page<Company>> {
    // https://rxjs-dev.firebaseapp.com/api/index/function/defer
    const params = this.buildParams(filter);
    return defer(async () => this.index.search(params.query, params)).pipe(
      map((response) => ({
        ...response,
        hits: (response['hits'] || []).map(
          (hit: any) =>
            new Company({
              ...hit,
              id: hit.objectID,
              tagLine: hit.tag_line,
              employees: hit.employees_number,
              numberOfFollowers: hit.nb_followers,
              numberOfJobsOffline: hit.nb_jobs_offline,
              numberOfJobsOnline: hit.nb_jobs_online,
              location: {
                city: hit.location_town,
                country: hit.location_country,
              },
            })
        ),
      })),
      map((response) => new Page<Company>(response))
    );
  }

  private buildParams(filter: CompanyFilter): { [key: string]: any } {
    const { geoPoint, keywords, fields, page } = filter;

    const aroundLatLng = geoPoint.lat ? `${geoPoint.lat}, ${geoPoint.lon}` : null;

    const facetFilters = [['status:PRIVE', 'status:PUBLIC']];

    if (this.shouldQueryCustomer(filter)) {
      facetFilters.push([`is_client:true`]);
    }

    if (fields) {
      facetFilters.push(fields.map((item) => `industry:${item.id}`));
    }

    return {
      query: keywords,
      facetFilters,
      queryLanguages: [environment.lang],
      ...(aroundLatLng ? { aroundLatLng } : {}),
      page,
    };
  }

  private shouldQueryCustomer(filter): boolean {
    return filter.fields.length === 0 && filter.geoPoint.length === 0 && !filter.keywords;
  }
}
