import { ReportReasonEnum } from '@models/job/report-reason.enum';

export class Report {
  profileId: string;
  reason: ReportReasonEnum;
  message: string;

  constructor(data = {}) {
    this.profileId = data['profileId'];
    this.reason = data['reason'];
    this.message = data['message'];
  }
}
