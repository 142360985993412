import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { NotFoundComponent } from '@features/error/not-found/not-found.component';

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule],
  declarations: [NotFoundComponent],
})
export class ErrorModule {}
