import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Account } from '@models/account/account';
import { LocaleEnum } from '@models/commons/locales.enum';
import { buildUrl } from '@wizbii/angular-utilities';
import { JwtTokens } from '@wizbii/jwt';
import { IdentityCard, toIdentityCard } from '@wizbii/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type UserStatus = 'created' | 'existing' | 'associated';

export interface SocialConnectStatus {
  userStatus: UserStatus;
  tokens: JwtTokens;
}

export enum SocialProviderEnum {
  facebook = 'facebook',
  google = 'google',
}

export enum AccountApiError {
  DeletedUserException = 'DeletedUserException',
  MissingEmailException = 'MissingEmailException',
}

interface SocialConnectData {
  [SocialProviderEnum.facebook]: { accessToken: string };
  [SocialProviderEnum.google]: { authCode: string };
}

@Injectable()
export class AccountWebservice {
  private readonly baseUrl = `${environment.api.account}/v1/account`;

  constructor(private readonly http: HttpClient) {}

  create(account: Account): Observable<JwtTokens> {
    const payload = new Account({
      ...account,
      appId: environment.applicationId,
      locale: environment.locale,
    });

    return this.http.post<JwtTokens>(`${this.baseUrl}/jobs`, payload, { withCredentials: true });
  }

  delete(id: string, reasons: string[]): Observable<void> {
    const url = buildUrl(`${this.baseUrl}/:id/delete`, { id });
    return this.http.post<void>(url, reasons);
  }

  recover(recoveryToken: string): Observable<JwtTokens> {
    const payload = { recoveryToken, appId: environment.applicationId };
    return this.http.post<JwtTokens>(`${this.baseUrl}/recover`, payload);
  }

  updateLanguage(id: string, locale: LocaleEnum): Observable<any> {
    const url = buildUrl(`${this.baseUrl}/:id/locale`, { id });
    const payload = {
      locale,
      appId: environment.applicationId,
    };
    return this.http.put<any>(url, payload);
  }

  facebookConnect(accessToken: string): Observable<SocialConnectStatus> {
    return this.socialConnect(SocialProviderEnum.facebook, { accessToken });
  }

  googleConnect(authCode: string): Observable<SocialConnectStatus> {
    return this.socialConnect(SocialProviderEnum.google, { authCode });
  }

  getIdentityCard(identityCardId: string): Observable<IdentityCard> {
    return this.http
      .get<IdentityCard>(`${environment.api.account}/v1/identity-card/${identityCardId}`)
      .pipe(map(toIdentityCard));
  }

  private socialConnect<K extends SocialProviderEnum>(
    providerId: K,
    data: SocialConnectData[K]
  ): Observable<SocialConnectStatus> {
    return this.http.post<SocialConnectStatus>(
      `${this.baseUrl}/jobs/social-connect/${providerId}`,
      {
        ...data,
        appId: environment.applicationId,
        locale: environment.locale,
      },
      { withCredentials: true }
    );
  }
}
