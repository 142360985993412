import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { Company } from '@models/company/company';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CompanyWebservice {
  private readonly baseUrl = `${environment.api.company}/company/?:companyId`;

  constructor(private readonly http: HttpClient) {}

  autoFollow(companyId: string): Observable<unknown> {
    const params: Params = { companyId, specialProcess: true };

    const url = buildUrl(this.baseUrl, params);

    return this.http.head(url);
  }

  get(companyId: string, specialProcess = false): Observable<Company> {
    let params: Params = { companyId };

    if (specialProcess) {
      params = { ...params, specialProcess };
    }

    const url = buildUrl(this.baseUrl, params);

    return this.http.get<object>(url).pipe(map((item) => new Company(item)));
  }

  follow(companyId: string): Observable<object> {
    const url = buildUrl(`${this.baseUrl}/follow`, { companyId });

    return this.http.post<object>(url, null).pipe(map((response) => response));
  }

  unfollow(companyId: string): Observable<object> {
    const url = buildUrl(`${this.baseUrl}/unfollow`, { companyId });

    return this.http.post<object>(url, null).pipe(map((response) => response));
  }

  create(companyName: string): Observable<Company> {
    const url = buildUrl(this.baseUrl, {});

    return this.http.post(url, { name: companyName }).pipe(map((item) => new Company(item)));
  }
}
