import { Injectable } from '@angular/core';

const regexH2Full = /<\s*p class="h3"[^>]*>(.*?)<\s*\/\s*p>/gi;
const regexH2Open = /<\s*p class="h3"[^>]*>/gi;
const regexH2OpenStrong = /<\s*p class="h3"><strong>/gi;
const regexH2Close = /<\s*\/\s*p>/gi;
const regexH2CloseStrong = /<\/strong><\s*\/\s*p>/gi;
const regexH3Full = /<\s*p class="p1"[^>]*><strong>(.*?)<\s*\/\s*p>/gi;
const regexH3Open = /<\s*p class="p1"[^>]*><strong>/gi;
const regexH3Close = /<\/strong><\s*\/\s*p>/gi;
const regexStyleInline = /style="(.*?)"/gi;

@Injectable()
export class HtmlParseService {
  parse(text: string): string {
    if (!text) {
      return '';
    }

    return text
      .replace(regexH2Full, (strFound) => {
        return strFound
          .replace(regexH2OpenStrong, '<h2>')
          .replace(regexH2CloseStrong, '</h2>')
          .replace(regexH2Open, '<h2>')
          .replace(regexH2Close, '</h2>');
      })
      .replace(regexH3Full, (strFound) => {
        return strFound.replace(regexH3Open, '<h3>').replace(regexH3Close, '</h3>');
      })
      .replace(regexStyleInline, '');
  }

  cleanSchema(text: string): string {
    if (!text) {
      return '';
    }

    return text
      .replace(/itemscope=""/g, '')
      .replace(/itemprop="([a-z, A-Z]*)"/g, '')
      .replace(/itemtype="http\/\/schema.org\/JobPosting"/g, '');
  }
}
