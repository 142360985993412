import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalComponent } from '@commons/modal/modal.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  imports: [CommonModule, SvgIconModule, PerfectScrollbarModule, A11yModule],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalModule {}
