import { LocaleEnum } from '@models/commons/locales.enum';
import { SeoLink } from '@models/seo/seo-link';

export class Directory {
  id: string;
  links: SeoLink[];
  isMainDirectory: boolean;
  type: string;
  letter: string;
  locale: LocaleEnum;

  constructor(data = {}) {
    this.id = data['id'];
    this.isMainDirectory = data['isMainDirectory'];
    this.type = data['type'];
    this.letter = data['letter'];
    this.locale = data['locale'];
    this.links = (data['links'] || []).map((link) => new SeoLink(link));
  }
}
