import { FacetModelInterface } from '@models/search/facet-model.interface';

export class Facet<Model extends FacetModelInterface> {
  model: Model;
  total: number;

  constructor(data: Partial<Facet<Model>> = {}) {
    this.model = data.model;
    this.total = data.total;
  }
}
