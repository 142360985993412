import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeThemeEnum } from '@commons/badge/badge-theme.enum';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  BadgeThemeEnum = BadgeThemeEnum;

  text: string;

  @Input()
  set content(content: string | number) {
    this.text = content.toString();
  }

  @Input() theme = BadgeThemeEnum.PrimaryOnWhite;
}
