import { Facet } from '@models/search/facet';
import { FacetModelInterface } from '@models/search/facet-model.interface';

/*
  how to use :

    - Page<Company>
      ==> page of Company items (without any facet)

   - Page<Job, { contracts: Contract, domains: Domain}>
       ==> page of `Job` items with `contracts` and `domains` facets
       ==> you can then write `page.facets.domains` to query `Facet<Domain>` items of the page
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export class Page<Model, FacetMap extends Record<string, FacetModelInterface> = {}> {
  nbHits: number;
  hitsPerPage: number;
  nbPages: number;
  page: number;
  processingTimeMS: number;
  queryId: string;
  hits: Model[];
  facets: { [key in keyof FacetMap]?: Facet<FacetMap[key]>[] };

  constructor(data: Partial<Page<Model, FacetMap>>) {
    this.nbHits = data.nbHits;
    this.hitsPerPage = data.hitsPerPage;
    this.nbPages = data.nbPages;
    this.page = data.page;
    this.processingTimeMS = data.processingTimeMS;
    this.queryId = data.queryId;
    this.hits = data.hits || [];
    this.facets = data.facets || {};
  }
}
