/* eslint-disable */
import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AlreadySessionedGuard } from '@core/guards/already-sessioned.guard';
import { HasNpsParamsGuard } from '@core/guards/has-nps-params.guard';
import { InitGuard } from '@core/guards/init.guard';
import { OnboardingFinishedGuard } from '@core/guards/onboarding-finished.guard';
import { SessionedGuard } from '@core/guards/sessioned.guard';
import { GlobalService } from '@core/services/global.service';
import { JobsListComponent } from '@features/directories/job-layout/jobs-list/jobs-list.component';
import { NotFoundComponent } from '@features/error/not-found/not-found.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NpsModule } from '@features/nps/nps.module';
import { NpsComponent } from '@features/nps/nps/nps.component';
import { RedirectComponent } from '@features/redirection/redirect/redirect.component';
import { RedirectionModule } from '@features/redirection/redirection.module';
import { LangEnum } from '@models/commons/locales.enum';

let _jobUnloggedMatcher;
let _profileUnloggedMatcher;

export function jobUnloggedMatcher(...args) {
  return _jobUnloggedMatcher && _jobUnloggedMatcher(...args);
}

export function profileUnloggedMatcher(...args) {
  return _profileUnloggedMatcher && _profileUnloggedMatcher(...args);
}

const routes: Routes = [
  {
    matcher: jobUnloggedMatcher,
    canActivate: [InitGuard],
    loadChildren: () => import('@features/routing/job-unlogged/job-unlogged.module').then((m) => m.JobUnloggedModule),
  },
  {
    matcher: profileUnloggedMatcher,
    canActivate: [InitGuard],
    loadChildren: () =>
      import('@features/routing/profile-unlogged/profile-unlogged.module').then((m) => m.ProfileUnloggedModule),
  },
  {
    path: FeaturesRoutingEnum.Onboarding,
    canActivate: [InitGuard, SessionedGuard],
    loadChildren: () => import('@features/routing/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: FeaturesRoutingEnum.Job,
    canActivate: [InitGuard, SessionedGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/routing/job/job.module').then((m) => m.JobModule),
  },
  {
    path: FeaturesRoutingEnum.JobWithReferral,
    canActivate: [InitGuard, SessionedGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/routing/job/job.module').then((m) => m.JobModule),
  },
  {
    path: FeaturesRoutingEnum.Search,
    canActivate: [InitGuard, SessionedGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/routing/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: FeaturesRoutingEnum.Company,
    loadChildren: () => import('@features/routing/company/company.module').then((m) => m.CompanyModule),
    canActivate: [InitGuard, OnboardingFinishedGuard],
  },
  {
    path: FeaturesRoutingEnum.Events,
    loadChildren: () => import('@features/routing/events/events.module').then((m) => m.EventsModule),
    canActivate: [InitGuard, OnboardingFinishedGuard],
  },
  {
    path: FeaturesRoutingEnum.Messages,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/messages/messages.module').then((m) => m.MessagesModule),
  },
  {
    path: FeaturesRoutingEnum.Wizmail,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/wizmail/wizmail.module').then((m) => m.WizmailModule),
  },
  {
    path: FeaturesRoutingEnum.Profile,
    canActivate: [InitGuard, SessionedGuard, OnboardingFinishedGuard],
    loadChildren: () => import('@features/routing/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: FeaturesRoutingEnum.Redirection,
    canActivate: [InitGuard, SessionedGuard, OnboardingFinishedGuard],
    component: RedirectComponent,
  },
  {
    path: FeaturesRoutingEnum.Home,
    canActivate: [InitGuard, AlreadySessionedGuard],
    loadChildren: () => import('@features/routing/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
  },
  {
    path: FeaturesRoutingEnum.Directories,
    loadChildren: () => import('@features/routing/directories/directories.module').then((m) => m.DirectoriesModule),
    canActivate: [InitGuard, OnboardingFinishedGuard],
  },
  {
    path: FeaturesRoutingEnum.EmploymentFr,
    component: JobsListComponent,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    runGuardsAndResolvers: 'paramsChange',
    data: {
      locale: LangEnum.fr,
    },
  },
  {
    path: FeaturesRoutingEnum.EmploymentEn,
    component: JobsListComponent,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    data: {
      locale: LangEnum.en,
    },
  },
  {
    path: FeaturesRoutingEnum.EmploymentEs,
    component: JobsListComponent,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    data: {
      locale: LangEnum.es,
    },
  },
  {
    path: FeaturesRoutingEnum.EmploymentIt,
    component: JobsListComponent,
    canActivate: [InitGuard, OnboardingFinishedGuard],
    data: {
      locale: LangEnum.it,
    },
  },
  {
    path: FeaturesRoutingEnum.Nps,
    canActivate: [InitGuard, HasNpsParamsGuard],
    component: NpsComponent,
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    component: NotFoundComponent,
    canActivate: [InitGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [InitGuard],
    data: { unhandled404: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NpsModule, RedirectionModule],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {
  private static injector: Injector;

  static jobUnloggedMatcher(segments: UrlSegment[]) {
    let result = null;
    const jobPath = FeaturesRoutingEnum.Job.split('/');
    const jobMatched = segments.length === 4 && segments[0].path === jobPath[0] && segments[2].path === jobPath[2];
    const jobWithReferralPath = FeaturesRoutingEnum.JobWithReferral.split('/');
    const jobWithReferralMatched =
      segments.length === 6 &&
      segments[0].path === jobWithReferralPath[0] &&
      segments[2].path === jobWithReferralPath[2] &&
      segments[4].path === jobWithReferralPath[4];
    const tokens = FeaturesRoutingModule.getGlobalService().tokens;

    if (jobMatched && !tokens) {
      result = {
        consumed: segments,
        posParams: {
          companyId: segments[1],
          jobId: segments[3],
        },
      };
    } else if (jobWithReferralMatched && !tokens) {
      result = {
        consumed: segments,
        posParams: {
          referralId: segments[1],
          companyId: segments[3],
          jobId: segments[5],
        },
      };
    }

    return result;
  }

  static profileUnloggedMatcher(segments: UrlSegment[]) {
    // Match only URLs of the form `/profile/:profileId`
    if (segments.length !== 2 || !segments[0] || segments[0].path !== FeaturesRoutingEnum.Profile || !segments[1]) {
      return null;
    }

    // Match only if `profileId` is not the current logged-in user's ID
    return segments[1].path !== FeaturesRoutingModule.getGlobalService().userId
      ? {
          consumed: segments,
          posParams: {
            profileId: segments[1],
          },
        }
      : null;
  }

  private static getGlobalService(): GlobalService {
    return FeaturesRoutingModule.injector.get(GlobalService);
  }

  constructor(injector: Injector) {
    FeaturesRoutingModule.injector = injector;
    _jobUnloggedMatcher = FeaturesRoutingModule.jobUnloggedMatcher;
    _profileUnloggedMatcher = FeaturesRoutingModule.profileUnloggedMatcher;
  }
}
