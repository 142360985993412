import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { JobPagination } from '@models/company/job-pagination';
import { Contract } from '@models/job/contract';
import { Domain } from '@models/job/domain';
import { Job } from '@models/job/job';
import { Facet } from '@models/search/facet';
import { JobFilter } from '@models/search/job-filter';
import { AlgoliaClientService } from '@wizbii/algolia';
import { City } from '@wizbii/models';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobSearchWebservice {
  private static readonly KEY_CONTRACT = 'contract.id';
  private static readonly KEY_DOMAIN = 'domain.id';
  private static readonly KEY_CITY = 'location.city';

  constructor(private readonly algoliaClientService: AlgoliaClientService) {}

  getBy(filter: JobFilter): Observable<JobPagination> {
    const queries = this.buildQueries(filter);

    return from(this.algoliaClientService.client.search([queries.contracts, queries.domains, queries.hits])).pipe(
      map((response) => response.results),
      map(([contractFacetsResponse, domainFacetsResponse, hitsResponse]) => ({
        ...hitsResponse,
        facets: {
          [JobSearchWebservice.KEY_CONTRACT]: contractFacetsResponse['facets'][JobSearchWebservice.KEY_CONTRACT],
          [JobSearchWebservice.KEY_DOMAIN]: domainFacetsResponse['facets'][JobSearchWebservice.KEY_DOMAIN],
        },
      })),
      map((response) => ({
        ...response,
        facets: {
          contracts: Object.keys(response['facets'][JobSearchWebservice.KEY_CONTRACT] || {})
            .map((contractId) => new Contract({ id: contractId, titleShort: contractId }))
            .map(
              (contract) =>
                new Facet<Contract>({
                  model: contract,
                  total: response['facets'][JobSearchWebservice.KEY_CONTRACT][contract.id],
                })
            ),

          domains: Object.keys(response['facets'][JobSearchWebservice.KEY_DOMAIN] || {})
            .map((domainId) => new Domain({ id: domainId, titleShort: domainId }))
            .map(
              (domain) =>
                new Facet<Domain>({
                  model: domain,
                  total: response['facets'][JobSearchWebservice.KEY_DOMAIN][domain.id],
                })
            ),
        },
      })),
      map((response) => ({
        ...response,
        hits: (response['hits'] || []).map(
          (hit: any) =>
            new Job({
              ...hit,
              id: hit.objectID,
              createdDate: hit.createdDate * 1000,
              startDate: hit.startDate * 1000,
            })
        ),
      })),
      map((response) => ({ ...response, queryId: response['queryID'] })),
      map((response: object) => new JobPagination(response))
    );
  }

  getResult(filter: JobFilter): Observable<number> {
    const { hits } = this.buildQueries(filter);
    const nbHitsQuery = { ...hits, params: { ...hits.params, responseFields: ['nbHits'] } };
    return from(this.algoliaClientService.client.search([nbHitsQuery])).pipe(map(({ results }) => results[0].nbHits));
  }

  private buildQueries(filter: JobFilter) {
    const bounds = filter.cities.map((city) => this.buildBounds(city, filter.distance));
    const facetFilters = this.buildFacetFilters(filter);
    const sharedParams = {
      queryLanguages: [environment.lang],
      removeStopWords: true,
      ignorePlurals: true,
      ...(bounds.length ? { insideBoundingBox: bounds } : {}),
      ...(filter.ids.length ? { filters: filter.ids.map((id) => `objectID:${id}`).join(' OR ') } : {}),
    };

    const shouldBeTracked = this.shouldQueryBeTracked(filter);

    return {
      // query for contract facets
      contracts: {
        indexName: environment.algolia.index.jobs,
        query: filter.q,
        params: {
          ...sharedParams,
          analytics: false,
          hitsPerPage: 0,
          facets: [JobSearchWebservice.KEY_CONTRACT],
          facetFilters: facetFilters.contracts,
        },
      },

      // query for domain facets
      domains: {
        indexName: environment.algolia.index.jobs,
        query: filter.q,
        params: {
          ...sharedParams,
          analytics: false,
          hitsPerPage: 0,
          facets: [JobSearchWebservice.KEY_DOMAIN],
          facetFilters: facetFilters.domains,
        },
      },

      // query for hits itself
      hits: {
        indexName: environment.algolia.index.jobs,
        query: filter.q,
        params: {
          ...sharedParams,
          analytics: shouldBeTracked,
          clickAnalytics: shouldBeTracked,
          page: filter.page,
          hitsPerPage: filter.perPage,
          attributesToRetrieve: ['*'],
          facetFilters: facetFilters.hits,
        },
      },
    };
  }

  shouldQueryBeTracked(filter: JobFilter): boolean {
    return !!filter.q || filter.contracts.length > 0 || filter.domains.length > 0 || filter.cities.length > 0;
  }

  private buildFacetFilters({ company, partner, contracts, domains, cities }: JobFilter) {
    const companyFacets = company ? [`company.id:${company}`] : [];
    const partnerFacets = partner ? [`sponsoringPartner.id:${partner}`] : [];
    const cityFacets = cities.map((city) => `${JobSearchWebservice.KEY_CITY}:${city.city}`);
    const contractsFacets = contracts.map((contract) => `${JobSearchWebservice.KEY_CONTRACT}:${contract.model.id}`);
    const domainsFacets = domains.map((domain) => `${JobSearchWebservice.KEY_DOMAIN}:${domain.model.id}`);

    return {
      // facets filter for contract facets query
      contracts: [companyFacets, partnerFacets, domainsFacets],

      // facets filter for domain facets query
      domains: [companyFacets, partnerFacets, contractsFacets],

      // facets filter for hits query
      hits: [companyFacets, partnerFacets, contractsFacets, domainsFacets, cityFacets],
    };
  }

  private buildBounds({ geo: { lon, lat } }: City, kmTolerance: number): number[] {
    const kmPerDegree = 111.321;
    const latOffset = kmTolerance / kmPerDegree;
    const lonOffset = kmTolerance / (Math.cos(lat * (Math.PI / 180)) * kmPerDegree);

    return [lat - latOffset, lon - lonOffset, lat + latOffset, lon + lonOffset];
  }
}
