import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Profile } from '@models/profile/profile';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { NavLinkEnum } from '@routing/actives-url';
import { ProfileRoutingEnum } from '@routing/profile/profile-routing.enum';
import { SearchRoutingEnum } from '@routing/search/search-routing.enum';
import { buildPath, WINDOW } from '@wizbii/angular-utilities';
import { Request } from 'express';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { map, startWith, throttleTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() heading: string;
  @Input() applicationsTotal: number;
  @Input() bookmarksTotal: number;
  @Input() isLogged = true;

  @Input('profile')
  get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;

    this.profileIsInFrance$.next(
      profile && profile.location && profile.location.country && profile.location.country.toLowerCase() === 'france'
    );
  }

  jobsPath = buildPath(FeaturesRoutingEnum.Search, SearchRoutingEnum.Jobs);
  companiesPath = buildPath(FeaturesRoutingEnum.Search, SearchRoutingEnum.Companies);
  eventsPath = buildPath(FeaturesRoutingEnum.Events);
  profileSegment = buildPath(FeaturesRoutingEnum.Profile);
  applicationsSegment = ProfileRoutingEnum.Applications;
  bookmarksSegment = ProfileRoutingEnum.Bookmarks;
  urlHome = environment.urls.jobHome[environment.locale];

  navLinkEnum = NavLinkEnum;
  localeEnum = LocaleEnum;
  isLocaleFrance = environment.locale === LocaleEnum.fr_FR;
  apiDomain = environment.apiDomain;
  redirectUrl = environment.redirectUrl;
  appId = environment.applicationId;
  locale = environment.locale;

  scrollObserver$: Subscription;
  opaque: boolean;
  scrollPreviousValue = 0;
  showSticky: boolean;
  scrollingUp: boolean;
  profileIsInFrance$ = new BehaviorSubject<boolean>(false);

  notification$: Observable<any>;

  accountUrlSignin: string;
  accountUrlSignup: string;

  isMobileOrTablet$: Observable<boolean>;

  private _profile: Profile;
  private redirect: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly eventTrackerService: EventTrackerService,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(WINDOW) private readonly window: any,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {
    this.notification$ = this.notificationService.notification$;

    if (isPlatformBrowser(platformId)) {
      this.isMobileOrTablet$ = this.breakpointObserver
        .observe('(max-width: 62em)')
        .pipe(map((breakpoint) => breakpoint.matches));
    }
  }

  ngOnInit(): void {
    this.redirect =
      this.request && isPlatformServer(this.platformId)
        ? `${this.request.protocol}://${this.request.get('host')}${this.request.url}`
        : `${this.window.location.origin}${this.window.location.pathname}`;

    this.accountUrlSignin = `${environment.urls.accountSignin}${this.redirect}`;

    this.store
      .select(RouterState.state)
      .pipe(
        untilDestroyed(this),
        startWith(null),
        map((state: any) => state?.params?.jobId)
      )
      .subscribe((jobId) => {
        const otherParams = jobId ? `&context=wizbii-jobs-offer&job-id=${jobId}` : '';
        this.accountUrlSignup = `${environment.urls.accountSignup}${this.redirect}${otherParams}`;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    if (!this.isLogged && isPlatformBrowser(this.platformId)) {
      this.scrollObserver$ = fromEvent(this.window, 'scroll', { passive: true })
        .pipe(untilDestroyed(this), startWith(null), throttleTime(25, undefined, { leading: true, trailing: true }))
        .subscribe(() => {
          const pageYOffset = this.window.pageYOffset;
          const shouldShowSticky = this.scrollingUp || pageYOffset <= 25;
          const shouldBeOpaque = pageYOffset >= 25;

          // Only update scroll direction when two consecutive scroll offsets differ
          const diff = pageYOffset - this.scrollPreviousValue;
          if (diff !== 0) {
            this.scrollingUp = Math.sign(diff) === -1;
          }

          this.scrollPreviousValue = pageYOffset;

          if (this.showSticky !== shouldShowSticky || this.opaque !== shouldBeOpaque) {
            this.showSticky = shouldShowSticky;
            this.opaque = shouldBeOpaque;
            this.cdr.markForCheck();
          }

          this.cdr.detectChanges();
        });
    }
  }

  sendData({ detail }: any): void {
    this.eventTrackerService.send(detail);
  }

  handleNotificationIsAccepted({ detail }: any): void {
    if (this.isLogged) {
      this.notificationService.notifyNotificationIsAccepted(detail);
    }
  }

  ngOnDestroy(): void {
    this.profileIsInFrance$.complete();
  }
}
