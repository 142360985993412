import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LangEnum } from '@models/commons/locales.enum';
import { DirectoriesRoutingEnum } from '@routing/directories/directories-routing.enum';
import { buildPath } from '@wizbii/angular-utilities';

export const TranslatedJobUrls: Record<LangEnum, string> = {
  [LangEnum.fr]: buildPath(FeaturesRoutingEnum.EmploymentFr),
  [LangEnum.en]: buildPath(FeaturesRoutingEnum.EmploymentEn),
  [LangEnum.es]: buildPath(FeaturesRoutingEnum.EmploymentEs),
  [LangEnum.it]: buildPath(FeaturesRoutingEnum.EmploymentIt),
};

export const TranslatedDomainUrls: Record<LangEnum, string> = {
  [LangEnum.fr]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.DomainesFR
  ),
  [LangEnum.en]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.DomainesEN
  ),
  [LangEnum.es]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.DomainesES
  ),
  [LangEnum.it]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.DomainesIT
  ),
};

export const TranslatedCityUrls: Record<LangEnum, string> = {
  [LangEnum.fr]: buildPath(FeaturesRoutingEnum.Directories, DirectoriesRoutingEnum.Lp, DirectoriesRoutingEnum.VillesFR),
  [LangEnum.en]: buildPath(FeaturesRoutingEnum.Directories, DirectoriesRoutingEnum.Lp, DirectoriesRoutingEnum.VillesEN),
  [LangEnum.es]: buildPath(FeaturesRoutingEnum.Directories, DirectoriesRoutingEnum.Lp, DirectoriesRoutingEnum.VillesES),
  [LangEnum.it]: buildPath(FeaturesRoutingEnum.Directories, DirectoriesRoutingEnum.Lp, DirectoriesRoutingEnum.VillesIT),
};

export const TranslatedContractsUrls: Record<LangEnum, string> = {
  [LangEnum.fr]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.ContractsFR
  ),
  [LangEnum.en]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.ContractsEN
  ),
  [LangEnum.es]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.ContractsES
  ),
  [LangEnum.it]: buildPath(
    FeaturesRoutingEnum.Directories,
    DirectoriesRoutingEnum.Lp,
    DirectoriesRoutingEnum.ContractsIT
  ),
};
