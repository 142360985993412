import { EventStatusEnum } from '@models/ops-event/event-status.enum';
import { DomainEnum } from '@models/search/domain.enum';
import { City } from '@wizbii/models';

export class OpsEvent {
  id: string;
  name: string;
  type: string;
  metaTitle?: string;
  metaDescription?: string;
  smallSocialNetworkFileId?: string;
  bigSocialNetworkFileId?: string;
  status: EventStatusEnum;
  date: Date;
  scheduleStart: Date;
  scheduleEnd: Date;
  jobsTitles: string[];
  jobs: OpsEventJob[];
  location?: City;
  areas?: City[];
  distance: number;
  thumbnailFileId: string;
  salonUrl: string;
  cvBookParameters: { imageFileId: string };
  publicName?: string;

  constructor(data = {}) {
    this.id = data['id'];
    this.name = data['name'];
    this.type = data['type'];
    this.metaTitle = data['metaTitle'];
    this.metaDescription = data['metaDescription'];
    this.smallSocialNetworkFileId = data['smallSocialNetworkFileId'];
    this.bigSocialNetworkFileId = data['bigSocialNetworkFileId'];
    this.status = data['status'];
    this.location = data['location'] ? new City(data['location']) : undefined;
    this.areas = (data['areas'] || []).map((area) => new City(area));

    this.date = data['date'] ? new Date(data['date']) : undefined;
    this.scheduleStart = data['scheduleStart'] ? new Date(data['scheduleStart']) : undefined;
    this.scheduleEnd = data['scheduleEnd'] ? new Date(data['scheduleEnd']) : undefined;

    this.jobsTitles = data['jobsTitles'] ? data['jobsTitles'] : data['jobTitles'];
    this.thumbnailFileId = data['thumbnailFileId'];
    this.cvBookParameters = data['cvBookParameters'];

    this.jobs = data['jobs'] ? data['jobs'] : [];

    this.publicName = data['publicName'];
    this.salonUrl = data['salonUrl'];
  }
}

export class OpsEventLight {
  id: string;
  scheduleStart: Date;
  scheduleEnd: Date;
  type: string;
  status: EventStatusEnum;
  location?: City;
  areas?: City[];
  thumbnailFileId: string;
  publicName?: string;

  constructor(data = {}) {
    this.id = data['id'];
    this.type = data['type'];
    this.status = data['status'];
    this.location = data['location'] ? new City(data['location']) : undefined;
    this.areas = (data['areas'] || []).map((area) => new City(area));

    this.scheduleStart = data['date'] ? new Date(removeTimezone(data['date'])) : undefined;
    this.scheduleEnd = data['scheduleEnd'] ? new Date(removeTimezone(data['scheduleEnd'])) : undefined;
    this.thumbnailFileId = data['thumbnailFileId'];
    this.publicName = data['publicName'];
  }
}

export class OpsEventJob {
  name: string;
  contract: string;
  city: string;
  degreeId: string;
  domain: DomainEnum;
}

function removeTimezone(date: string): string {
  return !!date && !!date.search(/[T]/g) && date.split('T')[1]?.length <= 8
    ? date
    : date?.substring(0, date.toString().length - 6);
}
