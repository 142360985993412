import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from '@core/services/seo.service';
import { trackById, trackByIndex } from '@core/utils/utils';
import { environment } from '@environment';
import { PageSeo } from '@features/directories/job-layout/jobs-list/seo/page-seo';
import { PaginationSeo } from '@features/directories/job-layout/jobs-list/seo/pagination-seo';
import { TranslatedJobUrls } from '@features/directories/translated-urls';
import { LocaleEnum } from '@models/commons/locales.enum';
import { JobPagination } from '@models/company/job-pagination';
import { Job } from '@models/job/job';
import { Application } from '@models/profile/application';
import { Bookmark } from '@models/profile/bookmark';
import { Profile } from '@models/profile/profile';
import { JobFilter } from '@models/search/job-filter';
import { JobLanding } from '@models/seo/job-landing';
import { SeoLink } from '@models/seo/seo-link';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { LinkRel, TypesEnum } from '@routing/directories/directories-routing.enum';
import { ProfileState } from '@store/ngxs-profile/profile.state';
import { ApplicationStoreService } from '@store/profile/application-store.service';
import { BookmarkToggle } from '@store/profile/bookmark/bookmark.actions';
import { BookmarkState } from '@store/profile/bookmark/bookmark.state';
import { GetJobLandingPage, GetJobs } from '@store/seo/seo.actions';
import { SeoState } from '@store/seo/seo.state';
import { SessionState } from '@store/session/session.state';
import { buildPath, buildUrl } from '@wizbii/angular-utilities';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { ContractsEnum } from '@commons/footer/footer/translated-links';
import { JobsContractsMeta } from '@features/directories/job-layout/contents/jobs-contracts/jobs-contracts-meta';

@UntilDestroy()
@Component({
  selector: 'app-jobs-city',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss', '../job-layout.component.scss'], // eslint-disable-line  @angular-eslint/relative-url-prefix
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsListComponent implements OnDestroy, OnInit {
  @Select(SessionState.isLogged) isLogged$: Observable<boolean>;
  @Select(ProfileState.authenticatedProfile) profile$: Observable<Profile>;
  @Select(BookmarkState) bookmarkMap$: Observable<Map<string, Bookmark>>;

  pageId: string;
  applications$: Observable<Map<string, Application>>;
  applicationsTotal$: Observable<number>;
  landing$: Observable<JobLanding>;
  jobs$: Observable<JobPagination>;
  page = 1;
  paginationSeo: PaginationSeo;
  computedPagination: PageSeo[];
  breadcrumbSegments$: Observable<SeoLink[]>;
  contractsEnum = ContractsEnum;
  jobsContractsMeta = JobsContractsMeta;
  trackById = trackById;
  trackByIndex = trackByIndex;

  private metaElements: HTMLMetaElement[] = [];

  constructor(
    @Optional() @Inject(RESPONSE) private readonly response: any,
    private readonly applicationStoreService: ApplicationStoreService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    private readonly seoService: SeoService,
    private readonly metaService: Meta
  ) {
    this.applicationsTotal$ = this.applicationStoreService.total$;
    this.applications$ = this.applicationStoreService.applications$;
  }

  @Dispatch() toggleBookmark = (job: Job): BookmarkToggle => new BookmarkToggle(job.id);

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        untilDestroyed(this),
        filter(([params]) => !!params.id)
      )
      .subscribe(([params, queryParams]) => {
        this.pageId = params.id;

        if (environment.locale === LocaleEnum.fr_FR && !this.seoService.isNewDomain(this.pageId)) {
          const newDomain = this.seoService.getNewDomain(this.pageId);
          if (newDomain) {
            this.redirect301(newDomain);
          }
        }

        this.store.dispatch(new GetJobLandingPage(environment.locale, this.pageId));

        this.landing$ = this.store.select(
          SeoState.landing(this.seoService.getJobLandingId(this.pageId, environment.locale))
        );
        this.jobs$ = this.store.select(SeoState.jobs(this.seoService.getJobLandingId(this.pageId, environment.locale)));
        this.page = parseInt(queryParams['page'], 10) || 1;

        this.handleSeoLinks();

        this.landing$
          .pipe(
            filter((landing) => !!landing),
            take(1)
          )
          .subscribe(() => {
            this.store.dispatch(new GetJobs(this.pageId, new JobFilter({ page: queryParams['page'] - 1 || 0 })));
          });

        this.jobs$
          .pipe(
            untilDestroyed(this),
            filter((jobPagination) => !!jobPagination)
          )
          .subscribe((jobPagination: JobPagination) => {
            const { nbHits, hitsPerPage, page, nbPages } = jobPagination;
            this.paginationSeo = new PaginationSeo(nbHits, hitsPerPage, page, nbPages);
            this.computedPagination = this.paginationSeo.getComputedPagination();
          });
      });

    this.metaElements = [
      this.metaService.addTag({
        name: 'robots',
        content: this.page < 10 ? 'index,follow' : 'noindex,nofollow',
      }),
    ];
    this.breadcrumbSegments$ = this.store.select(RouterState.state).pipe(
      filter((state: any) => state.params['id']),
      map((state: any) => state.params['id']),
      distinctUntilChanged(),
      switchMap((jobLandingPageId) => this.seoService.getBreadcrumbSegmentsWithJobLandingPAge(jobLandingPageId))
    );
  }

  buildTranslatedUrl(slug: string): string {
    return buildUrl(TranslatedJobUrls[environment.lang], {
      id: slug,
    });
  }

  buildUrlForPagination(): string {
    return buildUrl(TranslatedJobUrls[environment.lang], {
      id: this.pageId,
    });
  }

  buildQueryParamsForPagination(direction: number, total: number): { [key: string]: number } {
    let queryParams = {};
    const currentPage = this.page || 1;

    if (direction === -1) {
      queryParams = {
        page:
          currentPage + direction < 1 ? undefined : currentPage + direction === 1 ? undefined : currentPage + direction,
      };
    } else {
      queryParams = {
        page:
          currentPage + direction > total ? total : currentPage + direction === 1 ? undefined : currentPage + direction,
      };
    }

    return queryParams;
  }

  handleSeoLinks(): void {
    this.seoService.removeLinkWithRel(LinkRel.Canonical);
    this.seoService.removeLinkWithRel(LinkRel.Prev);
    this.seoService.removeLinkWithRel(LinkRel.Next);

    this.seoService.createLinkForCanonicalURL(environment.lang, TypesEnum.jobList, {
      id: this.pageId,
      page: this.page > 1 ? this.page : undefined,
    });
    if ((this.page || 1) - 1 > 0) {
      this.seoService.createLinkForPrevURL(environment.lang, TypesEnum.jobList, {
        id: this.pageId,
        page: (this.page ? this.page : 1) - 1 > 1 ? (this.page ? this.page : 1) - 1 : undefined,
      });
    }
    if ((this.page || 1) + 1 < 50) {
      // Algolia limits us to 50 pages when I wrote theses lines
      this.seoService.createLinkForNextURL(environment.lang, TypesEnum.jobList, {
        id: this.pageId,
        page: (this.page ? this.page : 1) + 1,
      });
    }
  }

  ngOnDestroy(): void {
    this.seoService.removeLinkWithRel(LinkRel.Canonical);
    this.seoService.removeLinkWithRel(LinkRel.Prev);
    this.seoService.removeLinkWithRel(LinkRel.Next);

    this.metaElements.forEach((meta) => {
      this.metaService.removeTagElement(meta);
    });
  }

  private redirect301(domainSlug: string) {
    const redirectUrl = buildPath('emploi', domainSlug);

    if (this.response) {
      this.response.statusCode = 301;
      this.response.statusMessage = `Redirecting to ${redirectUrl} with 301`;
      this.response.setHeader('Location', redirectUrl);
    }
    this.router.navigate([redirectUrl], { replaceUrl: true });
  }
}
