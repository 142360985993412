import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LocaleEnum } from '@models/commons/locales.enum';
import { buildPath } from '@wizbii/angular-utilities';

@Injectable()
export class UserIsFrenchGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(): boolean {
    if (environment.locale === LocaleEnum.fr_FR) {
      return true;
    }

    this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
    return false;
  }
}
