import { RelEnum } from '@features/directories/job-layout/jobs-list/seo/rel.enum';

export class PageSeo {
  number: number;
  rel: RelEnum;

  constructor(number: number, rel: RelEnum = null) {
    this.number = number;
    this.rel = rel;
  }
}
