import { LocaleEnum } from '@models/commons/locales.enum';
import { JobPagination } from '@models/company/job-pagination';
import { JobFilter } from '@models/search/job-filter';
import { Directory } from '@models/seo/directory';
import { JobLanding } from '@models/seo/job-landing';
import { SeoTypeEnum } from '@models/seo/seo-type.enum';

export class GetInitialDirectories {
  static readonly type = '[SEO] Get Initial Directories';

  constructor(readonly type: SeoTypeEnum, readonly locale: LocaleEnum) {}
}

export class GetInitialDirectoriesSuccess {
  static readonly type = '[SEO] Get Initial Directories Success';

  constructor(readonly type: SeoTypeEnum, readonly directories: Directory[]) {}
}

export class GetInitialDirectoriesFailed {
  static readonly type = '[SEO] Get Initial Directories Failed';

  constructor(readonly error: any) {}
}

export class GetDirectory {
  static readonly type = '[SEO] Get Directory';

  constructor(readonly type: SeoTypeEnum, readonly directoryId: string) {}
}

export class GetDirectorySuccess {
  static readonly type = '[SEO] Get Directory Success';

  constructor(readonly type: SeoTypeEnum, readonly directoryId: string, readonly directory: Directory) {}
}

export class GetCities {
  static readonly type = '[SEO] Get Cities';

  constructor(readonly locale: LocaleEnum) {}
}

export class GetCitiesSuccess {
  static readonly type = '[SEO] Get Cities Success';

  constructor(readonly jobLandings: JobLanding[]) {}
}

export class GetJobLandingPageSuccess {
  static readonly type = '[SEO] Get Job LandingPageSummary Succes';

  constructor(readonly typeId: string, readonly jobLanding: JobLanding) {}
}

export class GetJobLandingPage {
  static readonly type = '[SEO] Get Job LandingPage';

  constructor(readonly locale: LocaleEnum, readonly id: string) {}
}

export class GetDirectoryFailed {
  static readonly type = '[SEO] Get Directory Failed';

  constructor(readonly error: any) {}
}

export class GetJobLandingFailed {
  static readonly type = '[SEO] Get JobLanding Failed';

  constructor(readonly error: any) {}
}

export class GetCitiesFailed {
  static readonly type = '[SEO] Get Cities Failed';

  constructor(readonly error: any) {}
}

export class GetDomains {
  static readonly type = '[SEO] Get Domains';

  constructor(readonly locale: LocaleEnum) {}
}

export class GetDomainsSuccess {
  static readonly type = '[SEO] Get Domains Success';

  constructor(readonly jobLandings: JobLanding[]) {}
}

export class GetDomainsFailed {
  static readonly type = '[SEO] Get Domains Failed';

  constructor(readonly error: any) {}
}

export class GetCityDomains {
  static readonly type = '[SEO] Get City Domains';

  constructor(readonly locale: LocaleEnum) {}
}

export class GetCityDomainsSucces {
  static readonly type = '[SEO] Get City Domains Success';

  constructor(readonly jobLandings: JobLanding[]) {}
}

export class GetCityDomainsFailed {
  static readonly type = '[SEO] Get City Domains Failed';

  constructor(readonly error: any) {}
}

export class GetJobs {
  static readonly type = '[SEO] Get Jobs';

  constructor(readonly id: string, readonly filter: JobFilter) {}
}

export class GetJobsSuccess {
  static readonly type = '[SEO] Get Jobs Success';

  constructor(readonly typeId: string, readonly jobs: JobPagination) {}
}

export class GetJobsFailed {
  static readonly type = '[SEO] Get Jobs Failed';

  constructor(readonly error: any) {}
}

export class GetSeoContracts {
  static readonly type = '[SEO] Get SEO Contracts';

  constructor(readonly locale: LocaleEnum) {}
}

export class GetContractSuccess {
  static type = '[SEO] Get Contract Success';

  constructor(readonly jobLandings: JobLanding[]) {}
}

export class GetContractFailed {
  static type = '[SEO] Get Contrat Failed';

  constructor(readonly error: any) {}
}

export class GetCityContracts {
  static readonly type = '[SEO] Get City Contracts';

  constructor(readonly locale: LocaleEnum) {}
}

export class GetCityContratsSuccess {
  static type = '[SEO] Get City Contracts Success';

  constructor(readonly jobLandings: JobLanding[]) {}
}

export class GetCityContractsFailed {
  static type = '[SEO] Get City Contracts Failed';
  constructor(readonly error: any) {}
}
