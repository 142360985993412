import { Injectable } from '@angular/core';
import { Company } from '@models/company/company';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import {
  CreateCompany,
  CreateCompanySuccess,
  Follow,
  FollowSuccess,
  GetCompany,
  GetCompanySuccess,
  Unfollow,
  UnfollowSuccess,
} from '@store/company/company.actions';
import { CustomFormsState } from '@store/company/custom-forms/custom-forms.state';
import { NewsState } from '@store/company/news/news.state';
import { CompanyWebservice } from '@webservices/company-api/company.webservice';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface CompanyStateModel {
  companies: { [companyId: string]: Company };
}

const defaultState: CompanyStateModel = {
  companies: {},
};

@State<CompanyStateModel>({
  name: 'company',
  defaults: defaultState,
  children: [NewsState, CustomFormsState],
})
@Injectable()
export class CompanyState {
  static company(companyId: string) {
    return createSelector([CompanyState], (state: CompanyStateModel) => {
      return state.companies[companyId];
    });
  }

  constructor(private readonly companyWebservice: CompanyWebservice) {}

  @Action(GetCompany)
  getCompany(ctx: StateContext<CompanyStateModel>, { companyId, specialProcess }: GetCompany): any {
    const { companies } = ctx.getState();

    if (!companies[companyId]) {
      return this.companyWebservice
        .get(companyId, specialProcess)
        .pipe(switchMap((company) => ctx.dispatch(new GetCompanySuccess(company, companyId))));
    }
  }

  @Action(GetCompanySuccess)
  getCompanySuccess(ctx: StateContext<CompanyStateModel>, { company, companyId }: GetCompanySuccess): any {
    const { companies } = ctx.getState();

    let newCompanies = { ...companies };

    company.aliases.forEach((alias) => {
      newCompanies = {
        ...newCompanies,
        [alias]: company,
      };
    });

    return ctx.patchState({
      companies: {
        ...newCompanies,
        [companyId]: company,
        [company.id]: company,
      },
    });
  }

  @Action(Follow)
  follow(ctx: StateContext<CompanyStateModel>, { companyId }: Follow): any {
    return this.companyWebservice.follow(companyId).pipe(switchMap(() => ctx.dispatch(new FollowSuccess(companyId))));
  }

  @Action(FollowSuccess)
  followSuccess(ctx: StateContext<CompanyStateModel>, { companyId }: FollowSuccess): any {
    const { companies } = ctx.getState();
    const company = companies[companyId];

    return ctx.patchState({
      companies: {
        ...companies,
        [companyId]: {
          ...company,
          numberOfFollowers: company.numberOfFollowers + 1,
          isCurrentUserFollowingIt: true,
        },
      },
    });
  }

  @Action(Unfollow)
  unfollow(ctx: StateContext<CompanyStateModel>, { companyId }: Unfollow): any {
    return this.companyWebservice
      .unfollow(companyId)
      .pipe(switchMap(() => ctx.dispatch(new UnfollowSuccess(companyId))));
  }

  @Action(UnfollowSuccess)
  unfollowSuccess(ctx: StateContext<CompanyStateModel>, { companyId }: UnfollowSuccess): any {
    const { companies } = ctx.getState();
    const company = companies[companyId];

    return ctx.patchState({
      companies: {
        ...companies,
        [companyId]: {
          ...company,
          numberOfFollowers: company.numberOfFollowers - 1,
          isCurrentUserFollowingIt: false,
        },
      },
    });
  }

  @Action(CreateCompany)
  createCompany(ctx: StateContext<CompanyStateModel>, { name }: CreateCompany): Observable<void | Company> {
    return this.companyWebservice
      .create(name)
      .pipe(switchMap((company) => ctx.dispatch(new CreateCompanySuccess(company))));
  }

  @Action(CreateCompanySuccess)
  createCompanySuccess(ctx: StateContext<CompanyStateModel>, { company }: CreateCompanySuccess): any {
    const { companies } = ctx.getState();

    return ctx.patchState({
      companies: {
        ...companies,
        [company.id]: company,
      },
    });
  }
}
