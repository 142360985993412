import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BackButtonComponent } from '@commons/back-button/back-button.component';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule],
  declarations: [BackButtonComponent],
  exports: [BackButtonComponent],
})
export class BackButtonModule {}
