import { animate, keyframes, query, style, transition, trigger } from '@angular/animations';

export const modalOpenTransition = trigger('modalOpenTransition', [
  transition(':enter', [
    query(
      '.modal',
      animate(
        '0.4s ease-out',
        keyframes([style({ transform: 'scale3d(.0, .0, .0)' }), style({ transform: 'scale3d(1, 1, 1)' })])
      )
    ),
  ]),
]);
export const modalCloseTransition = trigger('modalCloseTransition', [
  transition(':leave', [
    query(
      '.modal',
      animate(
        '0.2s ease-in',
        keyframes([style({ transform: 'scale3d(1, 1, 1)' }), style({ transform: 'scale3d(.0, .0, .0)' })])
      )
    ),
  ]),
]);
